<template>
  <ValidationObserver ref="observer">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <strong>Inleverans</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-6 col-12">
                <label class="form-label">Produkter</label>
                <v-select
                  ref="v-select-product-list"
                  class="product-select"
                  :clearable="false"
                  placeholder="sök..."
                  :options="GetProductList"
                  :reset-on-options-change="true"
                  :filter-by="$beplugins.OrderProductFilter"
                  @input="(product) => ProductChangeEvent(product)"
                  label="name"
                >
                  <div slot="no-options">hittar inga produkter!</div>
                  <template v-slot:option="option">
                    {{ option.productNumber }} - {{ option.name }}
                    <span class="float-right"
                      >Saldo: {{ option.inStock }} st</span
                    >
                  </template>
                </v-select>
              </div>
              <div v-if="isProductSelected" class="form-group col-sm-2 col-12">
                <label class="form-label">I lager</label>
                <input
                  :value="`${selectedProduct.inStock} st`"
                  class="form-control form-control-sm"
                  style="background:#fff"
                  disabled
                  type="text"
                />
              </div>
              <div v-if="isProductSelected" class="form-group col-sm-2 col-12">
                <label class="form-label">Inleverans</label>
                <input
                  v-model="vModel"
                  class="form-control form-control-sm"
                  type="number"
                />
              </div>
              <div v-if="isProductSelected" class="form-group col-sm-2 col-12">
                <button @click="Update()" style="margin-top:35px;" class="btn btn-success btn-sm pull-right">
                  Uppdatera
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { apiGeneral } from "@/services/api/ApiServices";
import vSelect from "vue-select";
import { ProductEntity } from "@/services/api/ApiGeneral";
@Component({
  components: {
    vSelect,
  },
})
export default class Incoming extends Vue {
  @Prop({type: Array, required: true }) private productList!: ProductEntity[];
  private ProductList: ProductEntity[] = this.productList;
  private selectedProduct: ProductEntity = new ProductEntity();
  private isProductSelected: boolean = false;
  private vModel: number = 0;
  get GetProductList() {
    return this.ProductList;
  }
  @Watch('productList') private ProductListUpdated(data: ProductEntity[]) {
    this.ProductList = data;
  }
  private ProductChangeEvent(payload: ProductEntity) {
    this.selectedProduct = payload === null ? new ProductEntity() : payload;
    this.isProductSelected = payload === null ? false : true;
  }
  private async Update() {
    await apiGeneral.product_UpdateProductStock(this.selectedProduct.id, this.vModel).then(async (response) => {
      if (response.isSuccess && response.messages != null) {
          this.$beplugins.ApiSuccess(response);
          this.vModel = 0;
          this.$emit('reload');
      } else {
          this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.popover-preview {
  position: fixed !important;
  width: 100% !important;
  max-height: 50% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  overflow-y: scroll !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
