<template>
  <div class="animated fadeIn">
    <ProviderNew />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ProviderNew from '@/components/provider/ProviderNew.vue';
@Component({
  components: {
    ProviderNew
  }
})
export default class ProviderCreate extends Vue {
}
</script>
