<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://easyasia.se" target="_blank">Easy Asia AB</a>
      <span class="ml-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div>
    <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="https://benader.com" target="_blank">BeNader AB</a>
    </div>
  </CFooter>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class Footer extends Vue {}
</script>
