<template>
  <div>
    <div class="card-header">Inköp</div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="DataList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
        <template #show_details="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="toggleDetails(item, index)"
            >
              {{ Boolean(item._toggled) ? "Dölj" : "Visa" }}
            </CButton>
          </td>
        </template>
        <template #details="{item}">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
            style="border-top:1px solid #d8dbe0;"
          >
            <div class="col-12 py-2">
              <h4>
                {{ item.CompanyName }}
              </h4>
              <div class="row"></div>
              <p class="text-muted">
                Telefon: {{ item.companyPhone }}<br />
                Epost: {{ item.companyEmail }}
              </p>
              <CButton @click="editItem(item)" size="sm" color="info" class="">
                Redigera
              </CButton>
              <CButton
                @click="removeItem(item)"
                size="sm"
                color="danger"
                class="ml-1"
              >
                Radera
              </CButton>
            </div>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PurchaseEntity } from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
import Moment from "moment";
const moment = Moment;
@Component({})
export default class PurchaseList extends Vue {
  @Prop() private dataList!: PurchaseEntity[];
  private DataList: PurchaseEntity[] = [];
  private headers = [
    { key: "purchaseId", label: "Inköpsnr", _style: "width:20%" },
    { key: "CompanyName", label: "Leverantör", _style: "width:20%;" },
    { key: "Status", label: "Status", _style: "width:20%" },
    { key: "Created", label: "Skapad", _style: "width:10%;" },
    { key: "ArrivalAt", label: "Leverans", _style: "width:10%;" },
    { key: "CheckedInAt", label: "Incheckad", _style: "width:10%;" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private details = [];
  private collapseDuration = 0;
  @Watch("dataList") private ProviderListChanged() {
    this.$nextTick(() => {
      this.DataList = this.dataList.map((item: any, ToogleId: any) => ({
        ...item,
        CompanyName: item.provider.companyName,
        Created: moment(item.created).format("yyyy-MM-DD HH:mm"),
        Status: this.$t(
          `enums.${this.$beplugins
            .GetEnumsByValue("PurchaseStatusEnums", item.status)
            .toString()}`
        ),
        ArrivalAt:
          item.arrivalAt !== null
            ? moment(item.arrivalAt).format("yyyy-MM-DD HH:mm")
            : "-",
        CheckedInAt:
          item.checkedInAt !== null
            ? moment(item.checkedInAt).format("yyyy-MM-DD HH:mm")
            : "-",
        ToogleId,
      }));
    });
  }
  private toggleDetails(item: any) {
    this.$set(this.DataList[item.ToogleId], "_toggled", !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  private created() {
    this.DataList = this.dataList.map((item: any, ToogleId: any) => ({
      ...item,
      ToogleId,
    }));
  }
  private editItem(selected: PurchaseEntity) {
    window.open(`/purchase/edit/${selected.id}`, "_blank");
    // this.$router.push(`/purchase/edit/${selected.id}`);
  }
  private async removeItem(selected: PurchaseEntity) {
    const employeeName = selected.purchaseId;
    this.$beplugins.DeleteDialog(employeeName!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.purchase_Delete(selected.id!).then((response) => {
          if (response.isSuccess && response.messages != null) {
            this.$beplugins.ApiSuccess(response);
            this.$emit("reload");
          } else {
            this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
