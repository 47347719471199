<template>
    <ValidationObserver ref="observer">
    <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <strong>Biluppgifter</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-3">
                <label class="form-label" style="width:100%;" for="name">Namn
                  <CSwitch
                    color="primary"
                    variant="3d"
                    shape="pill"
                    size="sm"
                    class="float-right"
                    :checked.sync="vModel.isEnabled"
                  />
                  <span class="float-right mr-3">{{ vModel.isEnabled ? 'Påställd' : 'Avställd' }}</span>
                </label>
                <ValidationProvider rules="required|name" v-slot="v">
                <input
                  v-model="vModel.name"
                  name="name"
                  class="form-control"
                  id="name"
                  type="text"
                  placeholder="Vänligen ange bilnamn..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
            </div>
              <div class="form-group col-3">
                <label class="form-label" for="brand">Märke</label>
                <ValidationProvider rules="required|name" v-slot="v">
                <input
                  v-model="vModel.brand"
                  name="brand"
                  class="form-control"
                  id="brand"
                  type="text"
                  placeholder="Vänligen ange märke..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label" for="model">Modell</label>
                <ValidationProvider rules="required|name" v-slot="v">
                <input
                  v-model="vModel.model"
                  name="model"
                  class="form-control"
                  id="model"
                  type="text"
                  placeholder="Vänligen ange modell..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label" for="numberPlate">Registreringsnummer</label>
                <ValidationProvider rules="required|name" v-slot="v">
                <input
                  v-model="vModel.numberPlate"
                  name="numberPlate"
                  class="form-control"
                  id="numberPlate"
                  type="text"
                  placeholder="Vänligen ange registreringsnummer..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-3">
                <label class="form-label" for="width">Bredden</label>
                <ValidationProvider rules="required|numeric" v-slot="v">
                <input
                  v-model.number="vModel.width"
                  name="width"
                  class="form-control"
                  id="width"
                  type="text"
                  placeholder="Vänligen ange bredden..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label" for="length">Längden</label>
                <ValidationProvider rules="required|numeric" v-slot="v">
                <input
                  v-model.number="vModel.length"
                  name="length"
                  class="form-control"
                  id="length"
                  type="text"
                  placeholder="Vänligen ange längden..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label" for="height">Höjden</label>
                <ValidationProvider rules="required|numeric" v-slot="v">
                <input
                  v-model.number="vModel.height"
                  name="height"
                  class="form-control"
                  id="height"
                  type="text"
                  placeholder="Vänligen ange höjden..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label" for="maxLoad">Max vikt</label>
                <ValidationProvider rules="required|numeric" v-slot="v">
                <input
                  v-model.number="vModel.maxLoad"
                  name="maxLoad"
                  class="form-control"
                  id="maxLoad"
                  type="text"
                  placeholder="Vänligen ange max vikten..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-success pull-right"
            >
              Spara
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { CarEntity } from '@/services/api/ApiGeneral';
@Component({})
export default class CarUpdate extends Vue {
  @Prop() private carId!: number;
  private vModel: CarEntity = new CarEntity({
    id: 0,
    name: '',
    brand: '',
    model: '',
    numberPlate: '',
    width: 0,
    length: 0,
    height: 0,
    maxLoad: 0,
    isEnabled: true,
  });
  private async created() {
    await this.GetCar();
  }
  private async GetCar() {
    await apiGeneral.car_GetById(this.carId).then((response) => {
      this.vModel = response;
    });
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    await apiGeneral.car_Update(this.vModel).then((response) => {
      if (response.isSuccess && response.messages != null) {
          this.$beplugins.ApiSuccess(response);
          (this.$refs.observer as any).reset();
          this.$router.push('/car/list')
      } else {
          this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
