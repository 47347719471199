<template>
  <div>
    <div class="card-header">Lagerstatus</div>
    <div class="card-body">
      <button @click="exportXLS" class="btn btn-primary float-right">
        Exportera
      </button>
      <CDataTable
        :pagination="false"
        :items="GetStockValueList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        outlined
        sorter
        responsive
      >
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { StockValueViewModel } from "@/services/api/ApiGeneral";
import XLSX from "xlsx";
import moment from "moment";
@Component({})
export default class StockValueList extends Vue {
  @Prop() public stockValueList!: StockValueViewModel[];
  public headers = [
    { key: "artNumber", label: "ArtNr", _style: "" },
    { key: "artikelName", label: "Namn", _style: "" },
    { key: "stockQuantity", label: "Lagerstatus", _style: "" },
    { key: "unit", label: "Enhet", _style: "" },
    { key: "priceAverage", label: "Snittpris", _style: "" },
    { key: "stockValue", label: "Lagervärde", _style: "" },
  ];
  get GetStockValueList() {
    return this.stockValueList;
  }
  private calculateTotalStockValue(worksheet: XLSX.WorkSheet) {
    let totalValue = 0;
    const colCalculate = 5;
    const range = XLSX.utils.decode_range(worksheet["!ref"]!);
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: colCalculate });
      totalValue += worksheet[ref].v;

      if (worksheet[ref].v === 0) {
        // worksheet[ref].f = '{=SUM(F2:F3)}';
      }
    }
  }
  private exportXLS() {
    const result = this.GetStockValueList.filter(
      (row) => row.stockValue > 0
    ).reduce(
      (results: any[], row) => {
        const tempData: any[] = [
          row.artNumber,
          row.artikelName,
          row.stockQuantity,
          row.unit,
          row.priceAverage,
          row.stockValue,
        ];
        results.push(tempData);
        return results;
      },
      [
        [
          "Artnr",
          "Artikelbenämning",
          "I lager",
          "Enh",
          "Snittpris",
          "Lagervärde",
        ],
      ] as any[]
    );
    result.push(["", "", "", "", "", ""]);
    result.push([
      "",
      "",
      "",
      "",
      "Totalt lagervärde:",
      { f: `=SUM(F2:F${result.length - 1})` },
    ]);
    const data = XLSX.utils.aoa_to_sheet(result);
    const wb = XLSX.utils.book_new();
    // this.calculateTotalStockValue(data);
    XLSX.utils.book_append_sheet(wb, data, "Värde");
    XLSX.writeFile(
      wb,
      `Lagervärdeslista-${moment().format("YYYY-MM-DD-HH-mm")}.xlsx`
    );
  }
}
</script>
<style lang="scss" scoped>
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.popover-preview {
  position: fixed !important;
  width: 100% !important;
  max-height: 50% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  overflow-y: scroll !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
