<template>
  <div class="animated fadeIn">
    <ProductUpdate :productId="productId" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ProductUpdate from '@/components/product/ProductUpdate.vue';
@Component({
  components: {
    ProductUpdate
  }
})
export default class ProductEdit extends Vue {
  private productId!: string;
  private created() {
    this.productId = this.$route.params.id;
  }
}
</script>
