<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding: 0.3rem .3rem !important;">
          <label>Fortnox kundregister</label> 
          <CButton @click.stop.prevent="UpdateCustomers()" size="sm" color="primary" class="pull-right mr-2">
            Uppdatera kundregister
          </CButton>
        </div>
        <FortnoxCustomerList
          :customerList="GetCustomerList"
          @reload="loadCustomers"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { CustomerSearch, CustomerSubset } from '@/services/api/ApiGeneral';
import FortnoxCustomerList from '@/components/fortnox/customers/FortnoxCustomerList.vue';
@Component({
  components: {
    FortnoxCustomerList,
  },
})
export default class FortnoxCustomers extends Vue {
  private customerList: CustomerSubset[] = [];
  private searchFilter: CustomerSearch = new CustomerSearch({
    page: 1,
    limit: 1000000,
  });
  private async created() {
    await this.loadCustomers();
  }
  private async loadCustomers() {
    await apiGeneral.fortnox_GetAllCustomers(this.searchFilter).then((data) => {
      this.customerList = data;
    }).catch((err) => {
      console.log(err);
    });
  }
  private async UpdateCustomers() {
    await apiGeneral.fortnox_CloneAllCustomersToFortnox(this.searchFilter).then(async () => {
      await this.loadCustomers();
    });
  }
  get GetCustomerList() {
    return this.customerList;
  }
}
</script>
