<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <PurchaseList :dataList="GetDataList" @reload="loadData" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { PurchaseEntity } from '@/services/api/ApiGeneral';
import PurchaseList from '@/components/purchase/PurchaseList.vue';
@Component({
  components: {
      PurchaseList
  },
})
export default class Purchases extends Vue {
    private dataList: PurchaseEntity[] = [];
    private async created() {
        await this.loadData();
    }
    private async loadData() {
        await apiGeneral.purchase_GetAll().then((data) => {
            this.dataList = data;
        });
    }
    get GetDataList() {
        return this.dataList;
    }
}
</script>
