<template>
  <div class="card" style="height: 500px; overflow: auto">
    <div class="card-header" style="padding: 0.3rem 0.3rem !important">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label
          :class="
            `btn ${customerMode === 0 ? 'btn-info active' : 'btn-secondary'}`
          "
        >
          <input
            v-model="customerMode"
            :value="0"
            type="radio"
            name="userMode"
            id="option1"
            autocomplete="off"
          />
          Företag
        </label>
        <label
          :class="
            `btn ${customerMode === 1 ? 'btn-info active' : 'btn-secondary'}`
          "
        >
          <input
            v-model="customerMode"
            :value="1"
            type="radio"
            name="userMode"
            id="option2"
            autocomplete="off"
          />
          Privat
        </label>
      </div>
    </div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="GetCustomerList"
        :fields="GetTableHeaders"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
        <template #show_details="{ item }">
          <td class="py-2">
            <CIcon
              @click.native="createOrder(item)"
              class="mt-1"
              style="cursor: pointer"
              name="cil-color-border"
            />
            <CIcon
              @click.native="listOrders(item)"
              class="ml-2 mt-1"
              style="cursor: pointer"
              name="cil-list-numbered"
            />
          </td>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { CustomerEntity, CustomerModeEnums } from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
@Component({})
export default class CustomerList extends Vue {
  private customerMode: CustomerModeEnums = 0;
  private customerList: CustomerEntity[] = [];
  private headersCompany = [
    { key: "customerNo", label: "KundNr", _style: "width:20%" },
    { key: "companyName", label: "Företag", _style: "width:20%" },
    // { key: "country", label: "Land", _style: "width:20%;" },
    // { key: "email", label: "E-post", _style: "width:20%;" },
    // { key: "phone", label: "Telefon", _style: "width:20%" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private headersPrivate = [
    { key: "customerNo", label: "KundNr", _style: "width:20%" },
    { key: "firstName", label: "Förnamn", _style: "width:20%" },
    { key: "lastName", label: "Efternamn", _style: "width:20%" },
    // { key: "country", label: "Land", _style: "width:20%;" },
    // { key: "email", label: "E-post", _style: "width:20%;" },
    // { key: "phone", label: "Telefon", _style: "width:20%" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  get GetTableHeaders() {
    return this.customerMode === 0 ? this.headersCompany : this.headersPrivate;
  }
  private collapseDuration = 0;
  private selectedUser!: CustomerEntity;
  private createOrder(user: CustomerEntity) {
    const h = 800;
    const w = 1200;
    const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
    const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
    const new_window = window.open(
      "/OrderCreate/" + user.id,
      user.companyName!,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        y +
        ", left=" +
        x
    );
    new_window!.onbeforeunload = () => {
      // this.isLoading = true;
      // Promise.all([this.LoadOrders(), this.LoadCustomer()]).then(() => {
      //   this.isLoading = false;
      // });
    };
  }
  private async listOrders(selected: CustomerEntity) {
    this.$router.push(`/order/list/${selected.id}`);
  }
  private async created() {
    await this.loadCustomers();
  }
  private async loadCustomers() {
    await apiGeneral
      .customer_GetAll(true, false, false, false, false)
      .then((data) => {
        this.customerList = data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  get GetCustomerList() {
    return this.customerList.filter(
      (x) => x.customerMode === this.customerMode
    );
  }
}
</script>
