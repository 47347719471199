<template>
  <div class="animated fadeIn">
    <ProductNew v-if="$beplugins.HasRoles(['Ansvarig', 'Administrator', 'Admin'])" />
    <NotAuthorized v-else />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ProductNew from '@/components/product/ProductNew.vue';
import NotAuthorized from '@/components/access/NotAuthorized.vue'
@Component({
  components: {
    ProductNew,
    NotAuthorized
  }
})
export default class ProductCreate extends Vue {
}
</script>
