<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <EmployeeList :employeeList="GetCustomerList" @reload="loadData" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { EmployeeEntity } from '@/services/api/ApiGeneral';
import EmployeeList from '@/components/employee/EmployeeList.vue';
@Component({
  components: {
    EmployeeList,
  },
})
export default class Customers extends Vue {
  private employeeList: EmployeeEntity[] = [];
  private async created() {
    await this.loadData();
  }
  private async loadData() {
    await apiGeneral.employee_GetAll().then((data) => {
      this.employeeList = data;
    });
  }
  get GetCustomerList() {
    return this.employeeList;
  }
}
</script>
