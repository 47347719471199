<template>
    <div>
        <div class="card-header">Leverantörer</div>
        <div class="card-body">
            <CDataTable
                :pagination="false"
                :items="ProviderList"
                :fields="headers"
                :tableFilter="{label: 'Sök', placeholder: 'ange text'}"
                :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
                column-filter
                table-filter
                hover
                outlined
                sorter
                responsive>
        <template #show_details="{item, index}">
            <td class="py-2">
            <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
            >
                {{Boolean(item._toggled) ? 'Dölj' : 'Visa'}}
            </CButton>
            </td>
        </template>
        <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration" style="border-top:1px solid #d8dbe0;">
                <div class="col-12 py-2">
                    <h4>
                    {{item.CompanyName}}
                    </h4>
                    <div class="row">

                    </div>
                    <p class="text-muted">
                    Telefon: {{item.companyPhone}}<br />
                    Epost: {{item.companyEmail}}
                    </p>
                    <CButton @click="editProvider(item)" size="sm" color="info" class="">
                    Redigera
                    </CButton>
                    <CButton @click="removeProvider(item)" size="sm" color="danger" class="ml-1">
                    Radera
                    </CButton>
                </div>
                </CCollapse>
        </template>
        </CDataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProviderEntity } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
@Component({})
export default class ProviderList extends Vue {
    @Prop() private providerList!: ProviderEntity[];
    private ProviderList: ProviderEntity[] = [];
    private headers = [
        { key: 'companyName', label: 'Företag', _style: 'width:20%' },
        { key: 'country', label: 'Land', _style: 'width:20%;' },
        { key: 'email', label: 'E-post', _style: 'width:20%;' },
        { key: 'phone', label: 'Telefon', _style: 'width:20%' },
        { key: 'show_details', label: '', _style: 'width:1%', sorter: false, filter: false },
    ];
  private details = [];
  private collapseDuration = 0;
  @Watch('providerList') private ProviderListChanged() {
    this.$nextTick(() => {
      this.ProviderList = this.providerList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
    });
  }
  private toggleDetails(item: any) {
    this.$set(this.ProviderList[item.ToogleId], '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => { this.collapseDuration = 0; });
  }
  private created() {
    this.ProviderList = this.providerList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
  }
  private editProvider(selected: ProviderEntity) {
    this.$router.push(`/provider/edit/${selected.id}`);
  }
  private async removeProvider(selected: ProviderEntity) {
    const employeeName = selected.companyName
    this.$beplugins.DeleteDialog(employeeName!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.provider_Delete(selected.id!).then((response) => {
          if (response.isSuccess && response.messages != null) {
              this.$beplugins.ApiSuccess(response);
              this.$emit('reload');
          } else {
              this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
