<template>
  <div v-if="!isLoading" class="container-fluid">
    <div class="animated fadeIn">
      <div class="row mt-3">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-12 text-center">
                  <label><strong>Offert : {{ customer.companyName }}</strong></label>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label>Giltigt till</label>
                  <VueCtkDateTimePicker :no-button-now="true" format="YYYY-MM-DD HH:mm" formatted="YYYY-MM-DD HH:mm"
                    class="deliveryDate form-control form-control-sm" :no-label="true" locale="sv"
                    v-model="vModel.offerValidDate" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-1">
                  <label>Artikelnr</label>
                </div>
                <div class="col-3">
                  <label>Produkt</label>
                </div>
                <div class="col-1">
                  <label>Saldo</label>
                </div>
                <div class="col-1">
                  <label>Enhet</label>
                </div>
                <div class="col-1">
                  <label>Antal</label>
                </div>
                <div class="col-1">
                  <label>Inpris</label>
                </div>
                <div class="col-1">
                  <label>À-pris</label>
                </div>
                <div class="col-1">
                  <label>Pris</label>
                </div>
                <div class="col-1">
                  <label>TG</label>
                </div>
                <div class="col-1"></div>
              </div>
              <div class="row" :style="`background: ${item.incomingPrice > item.price
                ? 'red; padding-top:10px;'
                : ''
                }`
                " v-for="(item, itemIndex) in vModel.products" :key="`order-item-${itemIndex}`">
                <div class="col-1">
                  <label class="form-control form-control-sm">{{
                    item.productNumber
                  }}</label>
                </div>
                <div class="col-3">
                  <div class="row">
                    <div class="col-11">
                      <v-select :disabled="vModel.orderStatus === 5" class="product-select"
                        :ref="`product-select-${itemIndex}`" :clearable="false" placeholder="sök..."
                        :options="GetProducts" label="name" :value="item.name" @input="(product) =>
                          $beplugins.OrderProductSelected(
                            vModel,
                            product,
                            itemIndex,
                            $refs
                          )
                          " :filter-by="$beplugins.OrderProductFilter">
                        <div slot="no-options">hittar ingen data!</div>
                        <template v-slot:option="option">
                          <div class="row" :style="`background: ${customer.customer_Products.findIndex(
                            (x) => x.productId === option.id
                          ) !== -1 &&
                            option.id !==
                            '00000000-0000-0000-0000-000000000000'
                            ? '#FBB13C'
                            : ''
                            }`
                            ">
                            <div class="col-12">
                              {{ option.name }}
                              <span v-if="customer.customer_Products.findIndex(
                                (x) => x.productId === option.id
                              ) !== -1 &&
                                option.id !==
                                '00000000-0000-0000-0000-000000000000'
                                " class="fa fa-star pull-right" style="color: #2a1ab9; margin-top:3px"></span>
                            </div>
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-1 text-left">
                      <a v-if="customer.customer_Products.findIndex(
                        (x) => x.productId === item.productFK
                      ) === -1 &&
                        item.productFK !==
                        '00000000-0000-0000-0000-000000000000'
                        " @click="CreateAsFavorite(item.productFK, true)" href="javascript:void(0)">
                        <i class="fa fa-star-o" style="color: #2a1ab9;"></i>
                      </a>
                      <a v-else-if="customer.customer_Products.findIndex(
                        (x) => x.productId === item.productFK
                      ) !== -1 &&
                        item.productFK !==
                        '00000000-0000-0000-0000-000000000000'
                        " @click="CreateAsFavorite(item.productFK, false)" href="javascript:void(0)">
                        <i class="fa fa-star" style="color: #2a1ab9;"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <label class="form-control form-control-sm text-center">{{
                    item.inStock
                  }}</label>
                </div>
                <div class="col-1">
                  <select :disabled="vModel.orderStatus === 5" @keydown.enter="
                    $beplugins.OrderUnitSelected(itemIndex, $refs)
                    " @change="
    $beplugins.OrderUnitSelected(
      products,
      item,
      itemIndex,
      $refs
    )
    " :ref="`unit-select-${itemIndex}`" v-model="item.unit" class="form-control form-control-sm">
                    <option :value="unitEnum.value" v-for="unitEnum in $beplugins.GetEnumsByType('UnitEnums')"
                      :key="unitEnum.label">{{ $t(`enums.${unitEnum.label}`) }}</option>
                  </select>
                </div>
                <div class="col-1">
                  <input :disabled="vModel.orderStatus === 5" :ref="`quntity-amount-${itemIndex}`"
                    @keyup="$beplugins.InputValidatorMoney(item, 'quantity')" @keydown.enter="CreateNewRow(item)"
                    v-model="item.quantity" @change="
                      $beplugins.OrderQuantityChanged(
                        products,
                        item,
                        itemIndex,
                        $refs
                      )
                      " class="form-control form-control-sm text-center" type="text" />
                </div>
                <div class="col-1">
                  <input type="text" readonly v-model="item.incomingPrice" class="form-control form-control-sm float-left"
                    style="width: 80px; background: #fff;" />
                </div>
                <div class="col-1">
                  <input @keyup="$beplugins.InputValidatorMoney(item, 'price')" type="text"
                    :disabled="vModel.orderStatus === 5" :ref="`price-amount-${itemIndex}`"
                    @keydown.enter="CreateNewRow(item)" v-model="item.price"
                    class="form-control form-control-sm float-left" style="width:100px;" />
                </div>
                <div class="col-1">
                  <label class="form-control form-control-sm text-right">
                    {{ item.price * item.pieces }}</label>
                </div>
                <div class="col-1">
                  <label class="form-control form-control-sm text-right">{{
                    $beplugins.CalculateTG(item.price, item.incomingPrice)
                  }}
                    %</label>
                </div>
                <div class="col-1 text-center">
                  <div class="btn-group pull-right" role="group" aria-label="Basic example">
                    <button type="button" v-if="vModel.orderStatus === 5" class="btn btn-primary btn-sm"
                      @click="EditInvoicedProduct(item)">
                      <i class="fa fa-sort-numeric-asc"></i>
                    </button>
                    <button type="button" :disabled="vModel.orderStatus === 5 || vModel.products.length < 2"
                      class="btn btn-primary btn-sm" @click="vModel.products.splice(itemIndex, 1)">
                      <i class="fa fa-trash"></i>
                    </button>
                    <button :disabled="vModel.orderStatus === 5" type="button" class="btn btn-primary btn-sm"
                      @click="$beplugins.OrderInsertProduct(vModel, $nextTick, false, [])">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-3 text-center">
                  <strong>TB
                    {{
                      $beplugins.CalculateTB(
                        $beplugins.TotalPriceOrderViewModelItem(vModel, false),
                        $beplugins.TotalIncomingPriceOrderViewModelItem(
                          vModel,
                          false
                        )
                      )
                    }}
                    {{ customer.currency }}</strong>
                </div>
                <div class="col-3 text-center">
                  <strong>TG
                    {{
                      $beplugins.CalculateTG(
                        $beplugins.TotalPriceOrderViewModelItem(vModel, false),
                        $beplugins.TotalIncomingPriceOrderViewModelItem(
                          vModel,
                          false
                        )
                      )
                    }}
                    %</strong>
                </div>
                <div class="col-3 text-center">
                  <strong>exkl. moms
                    {{
                      $beplugins.TotalPriceOrderViewModelItem(vModel, false)
                      | money
                    }}
                    {{ customer.currency }}</strong>
                </div>
                <div class="col-3 text-center">
                  <strong>inkl. moms
                    {{
                      $beplugins.TotalPriceOrderViewModelItem(vModel, true)
                      | money
                    }}
                    {{ customer.currency }}</strong>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-12">
                  <label>Kommentar</label>
                  <textarea v-model="vModel.notes" rows="4" class="form-control form-control-sm"></textarea>
                </div>
                <div class="col-12 mt-3">
                  <button @click="CreateOrder(false)" :disabled="vModel.products.filter((x) => x.productNumber).length ===
                    0
                    " type="button" class="btn btn-sm btn-success pull-right">
                    Spara
                  </button>
                  <button :disabled="vModel.products.filter((x) => x.productNumber).length ===
                    0
                    " @click="$modal.show('order-preview')" type="button"
                    class="btn btn-sm btn-primary pull-right mr-3">
                    Förhandsgranska
                  </button>
                  <button @click="$beplugins.CloseWindowConfirmation('Avbryt order?')" type="button"
                    class="btn btn-sm btn-danger pull-right mr-3">
                    Avbryt
                  </button>
                </div>
                <div class="col-12">
                  <OrderedCustomerProductList :productList="orderedProducts" />
                </div>
              </div>
            </div>
            <loading :active.sync="isLoading" :color="'#FC6534'" :is-full-page="true"></loading>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="IsInvoiceProductEditVisual" name="edit-product-modal" :draggable="true" :clickToClose="false"
      :height="'auto'" :scrollable="true" :transition="'fade'">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-sm-12">
              <h6 class="mb-3">Produkt: {{ selectedEditInvoicedProduct.name }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center"></div>
            <div class="col-4 text-center">
              <label>Enhet</label>
            </div>
            <div class="col-4 text-center">
              <label>Antal</label>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-4 text-center">
              <label>Sålt</label>
            </div>
            <div class="col-4">
              <select disabled v-model="selectedEditInvoicedProduct.unit" class="form-control form-control-sm">
                <option :value="unitEnum.value" v-for="unitEnum in $beplugins.GetEnumsByType('UnitEnums')"
                  :key="unitEnum.label">{{ $t(`enums.${unitEnum.label}`) }}</option>
              </select>
            </div>
            <div class="col-4">
              <input disabled v-model="selectedEditInvoicedProduct.quantity"
                class="form-control form-control-sm text-center" type="text" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-4 text-center">
              <label>Kreditera</label>
            </div>
            <div class="col-4">
              <select v-model="selectedEditInvoicedProductUnitCredit" class="form-control form-control-sm">
                <option :value="unitEnum.value" v-for="unitEnum in $beplugins.GetEnumsByType('UnitEnums')"
                  :key="unitEnum.label">{{ $t(`enums.${unitEnum.label}`) }}</option>
              </select>
            </div>
            <div class="col-4">
              <input v-model="selectedEditInvoicedProductQuantityCredit" class="form-control form-control-sm text-center"
                type="text" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid py-3">
        <div class="row">
          <div class="col-12">
            <button @click="SaveEditInvoicedProduct()" type="button" class="btn btn-sm btn-primary pull-right mr-3">
              Kreditera
            </button>
            <button @click="CancelEditInvoicedProduct()" type="button" class="btn btn-sm btn-danger pull-right mr-3">
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal v-if="!isLoading" name="order-preview" :draggable="true" :clickToClose="false" :height="'auto'" :width="'90%'"
      :scrollable="true" :transition="'fade'">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-sm-4">
              <h6 class="mb-3">Kund:</h6>
              <div>
                <strong>{{ customer.companyName }}</strong>
              </div>
              <div>
                {{
                  `${GetDeliveryAddress.address
                    ? GetDeliveryAddress.address + ","
                    : ""
                  }, ${GetDeliveryAddress.postalCode
                    ? GetDeliveryAddress.postalCode + ","
                    : ""
                  } ${GetDeliveryAddress.city ? GetDeliveryAddress.city + "," : ""
                  }, ${GetDeliveryAddress.country
                    ? GetDeliveryAddress.country + ","
                    : ""
                  }`
                }}
              </div>
              <div>E-post: {{ customer.email }}</div>
              <div>Telefon: {{ customer.phone }}</div>
            </div>

            <div class="col-sm-4"></div>

            <div class="col-sm-4">
              <h6 class="mb-3">Offert</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-5" style="font-weight: bold;">
              Kommentar: <br />
              {{ vModel.notes }}
            </div>
          </div>
          <div class="table-responsive-sm">
            <table class="table table-sm table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Artikelnr</th>
                  <th>Produkt</th>
                  <th class="text-center">Saldo</th>
                  <th>Enhet</th>
                  <th class="text-center">Antal</th>
                  <th class="text-center">Inpris</th>
                  <th class="text-center">À-pris</th>
                  <th class="text-center">Pris</th>
                  <th class="text-center">TG</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, rowIndex) in vModel.products.filter(
                  (x) => x.productNumber
                )" :key="`${row.productNumber}-${row.name}-${rowIndex}`" :index="rowIndex">
                  <td>{{ ++rowIndex }}</td>
                  <td>{{ row.productNumber }}</td>
                  <td>{{ row.name }}</td>
                  <td class="text-center">{{ row.inStock }}</td>
                  <td>
                    {{
                      $t(
                        `enums.${$beplugins.GetEnumsByValue(
                          "UnitEnums",
                          row.unit
                        )}`
                      )
                    }}
                  </td>
                  <td class="text-center">{{ row.quantity }}</td>
                  <td class="text-center">{{ row.incomingPrice }}</td>
                  <td class="text-center">
                    {{ row.price }}
                    <span class="ml-2">{{ customer.currency }}</span>
                  </td>
                  <td class="text-center">
                    {{ (row.price * row.pieces) | money }}
                    {{ customer.currency }}
                  </td>
                  <td class="text-center">
                    {{ $beplugins.CalculateTG(row.price, row.incomingPrice) }} %
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <strong>Antal rader:
                      {{
                        vModel.products.filter((x) => x.productNumber).length
                      }}</strong>
                  </td>
                  <td>
                    <strong>TB
                      {{
                        $beplugins.CalculateTB(
                          $beplugins.TotalPriceOrderViewModelItem(
                            vModel,
                            false
                          ),
                          $beplugins.TotalIncomingPriceOrderViewModelItem(
                            vModel,
                            false
                          )
                        )
                      }}
                      {{ customer.currency }}</strong>
                  </td>
                  <td>
                    <strong>TG
                      {{
                        $beplugins.CalculateTG(
                          $beplugins.TotalPriceOrderViewModelItem(
                            vModel,
                            false
                          ),
                          $beplugins.TotalIncomingPriceOrderViewModelItem(
                            vModel,
                            false
                          )
                        )
                      }}
                      %</strong>
                  </td>
                  <td colspan="2">
                    <strong>exkl. moms
                      {{
                        $beplugins.TotalPriceOrderViewModelItem(vModel, false)
                        | money
                      }}
                      {{ customer.currency }}</strong>
                  </td>
                  <td colspan="2">
                    <strong>inkl. moms
                      {{
                        $beplugins.TotalPriceOrderViewModelItem(vModel, true)
                        | money
                      }}
                      {{ customer.currency }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="container-fluid py-3">
        <div class="row">
          <div class="col-12">
            <button type="button" @click="PrintOffer()" class="btn btn-sm btn-success pull-right">
              Skriv ut
            </button>
            <button @click="CreateOrder(false)" type="button" class="btn btn-sm btn-primary pull-right mr-3">
              Spara
            </button>
            <button @click="$modal.hide('order-preview')" type="button" class="btn btn-sm btn-danger pull-right mr-3">
              Avbryt
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {
  CustomerEntity,
  DeliveryAddressEntity,
  ProductEntity,
  OrderEntity,
  OrderProductEntity,
  Join_PriceTemplate_Product,
  Join_Customer_Product,
  CompanyDetails,
  OrderStatusEnums,
  ProductStatusEnums,
  UnitEnums,
  OrderProductDTO,
  OrderProductCreditDto,
} from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
import Loading from "vue-loading-overlay";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import vSelect from "vue-select";
import Moment from "moment";
import OrderedCustomerProductList from "@/components/orders/OrderedCustomerProductList.vue";
const moment = Moment;
@Component({
  components: {
    Loading,
    VueCtkDateTimePicker,
    vSelect,
    OrderedCustomerProductList,
  },
})
export default class EditOffer extends Vue {
  private settings: CompanyDetails = new CompanyDetails();
  private products: ProductEntity[] = [];
  private orderedProducts: OrderProductDTO[] = [];
  private isLoading: boolean = true;
  private IsInvoiceProductEditVisual: boolean = false;
  private selectedEditInvoicedProduct?: OrderProductEntity = undefined;
  private selectedEditInvoicedProductUnitCredit?: UnitEnums = undefined;
  private selectedEditInvoicedProductQuantityCredit?: number = undefined;
  private vModel: OrderEntity = new OrderEntity({
    customerMode: 0,
    deliveryMode: 1,
    orderStatus: OrderStatusEnums.Offer,
    paymentStatus: 0,
    isLocked: false,
    orderId: "",
    id: "",
    sellerFK: "",
    customerFK: "",
    sellerAlias: "",
    deliveryAddressFK: "",
    notes: "",
    products: [],
    deliveryDate: moment().format("YYYY-MM-DD HH:mm"),
    createdDate: moment().format("YYYY-MM-DD HH:mm"),
    offerValidDate: moment().format("YYYY-MM-DD HH:mm"),
    createdBy: "",
    totalWeight: 0,
    selectedForPrint: false,
    printedDeliveryNote: false,
    printedPickList: false,
    isOutDated: false,
  });
  private customer: CustomerEntity = new CustomerEntity();
  get GetProducts() {
    return this.products.filter((x) => x.status === ProductStatusEnums.Active);
  }
  get GetDeliveryAddress() {
    const itemIndex = this.customer!.addresses!.findIndex(
      (x) => x.id === this.vModel.deliveryAddressFK
    );
    if (itemIndex !== -1) {
      return this.customer.addresses![itemIndex];
    } else {
      return new DeliveryAddressEntity();
    }
  }
  private EditInvoicedProduct(orderProduct: OrderProductEntity) {
    this.selectedEditInvoicedProduct = orderProduct;
    this.selectedEditInvoicedProductUnitCredit = this.selectedEditInvoicedProduct.unit;
    this.selectedEditInvoicedProductQuantityCredit = 0;
    this.IsInvoiceProductEditVisual = true;
    setTimeout(() => {
      this.$modal.show('edit-product-modal')
    }, 300);
  }
  private CancelEditInvoicedProduct() {
    this.selectedEditInvoicedProduct = undefined;
    this.selectedEditInvoicedProductUnitCredit = undefined;
    this.selectedEditInvoicedProductQuantityCredit = undefined
    this.IsInvoiceProductEditVisual = false;
    this.$modal.hide('edit-product-modal')
  }
  private async SaveEditInvoicedProduct() {
    await apiGeneral.order_CreditProductStock(new OrderProductCreditDto({
      orderId: this.vModel.id,
      productId: this.selectedEditInvoicedProduct?.productFK!,
      quantity: this.selectedEditInvoicedProductQuantityCredit!,
      unit: this.selectedEditInvoicedProductUnitCredit!
    }));
    this.selectedEditInvoicedProduct = undefined;
    this.selectedEditInvoicedProductUnitCredit = undefined;
    this.selectedEditInvoicedProductQuantityCredit = undefined
    this.IsInvoiceProductEditVisual = false;
    this.$modal.hide('edit-product-modal')
    await apiGeneral
      .companyDetail_Get()
      .then((response) => {
        this.settings = response;
      })
      .then(
        async () =>
          await this.LoadOrder().then(() => {
            this.isLoading = false;
            window.setTimeout(() => {
              this.$beplugins.OrderInsertProduct(
                this.vModel,
                this.$nextTick,
                true,
                this.products,
                this.$refs
              );
            }, 1000);
          })
      );
  }
  private CreateNewRow(payload: OrderProductEntity) {
    const productIndex = this.customer.priceTemplate!.priceTemplate_Products?.findIndex(
      (x) => x.productId === payload.productFK
    )!;
    const priceTemplateId = this.customer.priceTemplate!.id;
    const originalPrice =
      productIndex !== -1
        ? this.customer.priceTemplate!.priceTemplate_Products![productIndex]
          .price
        : 0;
    // const newPrice = payload.unit === UnitEnums.Carton ? payload.price / payload.pieces : payload.price;
    const newPrice = payload.price;
    if (
      newPrice < payload.lastPrice &&
      !confirm(`Du gör en förlust på angivet pris, vill du fortsätta?`)
    ) {
      return;
    }
    if (newPrice !== originalPrice) {
      this.$swal({
        icon: "warning",
        title: "Är du säker?",
        text: `Vill du uppdatera priset?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja",
        cancelButtonText: "Nej",
      }).then(async (result: any) => {
        if (result.value) {
          await apiGeneral
            .priceTemplate_UpdatePrice(
              priceTemplateId,
              payload.productFK,
              newPrice
            )
            .then((response) => {
              if (response.isSuccess && response.messages != null) {
                if (productIndex !== -1) {
                  this.customer.priceTemplate!.priceTemplate_Products![
                    productIndex
                  ].price = newPrice;
                } else {
                  this.customer.priceTemplate!.priceTemplate_Products!.push(
                    new Join_PriceTemplate_Product({
                      price: newPrice,
                      productId: payload.productFK,
                      priceTemplateId: priceTemplateId,
                    })
                  );
                }
                const productListIndex = this.products.findIndex(
                  (x) => x.id === payload.productFK
                );
                if (productListIndex !== -1) {
                  this.products[productListIndex].price = newPrice;
                }
                this.$beplugins.ApiSuccess(response);
              } else {
                this.$beplugins.ApiError(response);
              }
            });
        }
      });
    }
    this.$beplugins.OrderInsertProduct(
      this.vModel,
      this.$nextTick,
      false,
      [],
      this.$refs
    );
  }
  private async LoadOrder() {
    await apiGeneral
      .order_GetOrderById(this.$route.params.orderId)
      .then((response) => {
        this.vModel = response;
        this.customer = JSON.parse(JSON.stringify(this.vModel.customer!));
        this.vModel.customer = null;
        this.vModel.sellerAlias = this.$beplugins.GetOidcUser().name;
        this.vModel.sellerFK = this.$beplugins.GetOidcUser().sub;
      })
      .then(
        async () =>
          await apiGeneral.product_GetAll().then((products) => {
            this.products = products;
            this.customer.priceTemplate!.priceTemplate_Products!.forEach(
              (pp) => {
                const productIndex = this.products.findIndex(
                  (x) => x.id === pp.product!.id
                );
                if (productIndex !== -1) {
                  this.products[productIndex].price = pp.price;
                }
              }
            );
            this.products.forEach((p) => {
              if (p.price === 0) {
                p.price = p.priceBase;
              }
            });
          })
      );
    this.orderedProducts = await apiGeneral.orderProduct_GetAllOrderedProductsByCustomerId(
      this.customer.id
    );
  }
  private async created() {
    await apiGeneral
      .companyDetail_Get()
      .then((response) => {
        this.settings = response;
      })
      .then(
        async () =>
          await this.LoadOrder().then(() => {
            this.isLoading = false;
            window.setTimeout(() => {
              this.$beplugins.OrderInsertProduct(
                this.vModel,
                this.$nextTick,
                true,
                this.products,
                this.$refs
              );
            }, 1000);
          })
      );
  }
  private async CreateOrder(isPrint: boolean) {
    this.isLoading = true;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.vModel.products!.length; i++) {
      if (this.vModel.products![i].productFK === "") {
        this.vModel.products!.splice(i, 1);
      }
    }
    this.vModel.products = this.vModel.products!.filter(
      (x) => x.productFK !== "00000000-0000-0000-0000-000000000000"
    );
    if (!this.vModel.printedPickList && isPrint) {
      this.vModel.printedPickList = true;
    } else if (!this.vModel.printedDeliveryNote && isPrint) {
      this.vModel.printedDeliveryNote = true;
      this.vModel.orderStatus = OrderStatusEnums.Transit;
    }
    await apiGeneral
      .order_Update(this.vModel)
      .then(async (response) => {
        if (response.isSuccess && response.messages != null) {
          if (isPrint) {
            this.vModel.customer = this.customer;
            if (
              this.vModel.printedPickList &&
              !this.vModel.printedDeliveryNote
            ) {
              await this.$beplugins.PrintingPickList(
                [this.vModel],
              );
            } else {
              await this.$beplugins.PrintingDeliveryNote(
                [this.vModel],
              );
            }
          }
          window.close();
        } else {
          this.$beplugins.ApiError(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.isLoading = false;
  }
  private async CreateAsFavorite(productId: string, isFavorite: boolean) {
    this.isLoading = true;
    await apiGeneral
      .product_ToogleCustomerFavorite(this.customer.id, productId, isFavorite)
      .then((response) => {
        if (response.isSuccess && response.messages != null) {
          if (isFavorite) {
            this.customer.customer_Products?.push(
              new Join_Customer_Product({
                isFavorite: true,
                customerId: this.customer.id,
                productId: productId,
              })
            );
          } else {
            const itemIndex = this.customer.customer_Products?.findIndex(
              (x) =>
                x.customerId === this.customer.id && x.productId === productId
            );
            if (itemIndex !== -1) {
              this.customer.customer_Products?.splice(itemIndex!, 1);
            }
          }
        } else {
          this.$beplugins.ApiError(response);
        }
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  }
  private async PrintOffer() {
    await this.$beplugins.PrintingOffer(
      [this.vModel],
    );
  }
}
</script>
<style lang="scss"></style>
  