<template>
<div v-if="!isLoading" class="card-body">
    <div class="row">
        <div class="col-12">
        <p class="text-center mt-0"><strong>Tillgängliga ordrar</strong></p>
        </div>
        <div style="border: 1px solid #eee; background: #eee;overflow-x: auto;height:180px;width:100%;" >
        <Container orientation="horizontal" style="min-width:300px;max-width:300px;min-height:150px;" group-name="car" :get-child-payload="itemIndex => GetChildPayload(0, itemIndex)" @drop="onDrop(0, $event)">
            <Draggable v-for="item in vModel[0]" :key="`order0-${item.id}`" style="min-width:300px;max-width:300px;min-height:150px">
                <div class="card" style="min-height:70px;width:305px !important;">
                <div class="card-body pt-1">
                    <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" v-popover="{ name: item.orderId }" class="btn btn-info btn-sm btn-pill shadow">
                        <CIcon style="font-size:10px;" name="cil-zoom" />
                        </button>
                    </div>
                    </div>
                    <div class="draggable-item">
                    <p class="m-0"><span>Kund:</span><span class="float-right">{{ item.customer.companyType === 0 ? item.customer.companyName : `${item.customer.firstName} ${item.customer.lastName}`}}</span></p>
                    <p class="m-0"><span>Stad:</span><span class="float-right">{{ `${item.customer.city} (${item.customer.country})` }}</span></p>
                    <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p>
                    </div>
                </div>
                <popover transition="show-from-bottom" class="popover-preview" :pointer="false" :name="item.orderId">
                    <div class="row">
                    <div class="col-12">
                        <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ item.orderId }}</span></p>
                        <p class="m-0"><span>Kund:</span><span class="float-right">{{ item.customer.companyType === 0 ? item.customer.companyName : `${item.customer.firstName} ${item.customer.lastName}`}}</span></p>
                        <p class="m-0"><span>Stad:</span><span class="float-right">{{ `${item.customer.city} (${item.customer.country})` }}</span></p>
                        <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p>
                        <div v-if="item.notes !== ''">
                        <hr>
                        <p class="m-0"><span>Meddelande:</span></p>
                        <p class="m-0"><span>{{ item.notes }}</span></p>
                        </div>
                    </div>
                    </div>
                </popover>
                </div>
            </Draggable>
            </Container>
        </div>
        </div>
        <div class="col-12">
        <p class="text-center mt-3"><strong>Tillgängliga bilar</strong></p>
        </div>
        <div class="row flex-row flex-nowrap" style="overflow-x: auto;">
        <div style="border: 1px solid #eee;min-width:300px;max-width:300px;" class="col-6 col-md-2" v-for="(car, carIndex) in cars" :key="car.id">
            <div v-if="vModel[carIndex + 1] !== undefined && vModel[carIndex + 1].length > 0" class="row">
            <div class="col-12 mt-1 mb-0 text-center">
            <button type="button" v-popover="{ name: `carIndexPop-${carIndex}` }" class="btn btn-info btn-sm btn-pill shadow">
                <CIcon style="font-size:10px;" name="cil-zoom" />
            </button>
            </div>
            </div>
            <popover transition="show-from-bottom" class="popover-preview" :pointer="false" :name="`carIndexPop-${carIndex}`">
                <div class="row m-2" v-for="item in vModel[carIndex + 1]" :key="`carPop-${carIndex + 1}-${item.id}`" style="border: 1px solid #eee">
                    <div class="col-12">
                    <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ item.orderId }}</span></p>
                    <p class="m-0"><span>Kund :</span><span class="float-right">{{ item.customer.companyType === 0 ? item.customer.companyName : `${item.customer.firstName} ${item.customer.lastName}`}}</span></p>
                    <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p>
                    <p class="m-0"><span>Säljare:</span><span class="float-right">{{ item.sellerName }}</span></p>
                    <div v-if="item.notes !== ''">
                        <hr>
                        <p class="m-0"><span>Meddelande:</span></p>
                        <p class="m-0"><span>{{ item.notes }}</span></p>
                    </div>
                    </div>
                </div>
            </popover>
            <p class="mb-0 "><span>Bil:</span><span class="float-right">{{ `${car.brand} - ${car.model} (${car.numberPlate})` }}</span></p>
            <p class="mb-0"><span>Max vikt: </span><span class="float-right">{{car.maxLoad}} kg</span></p>
            <p class="mb-0 my-2">
            <span>Chaufför:</span>
            <span class="float-right">
                <select :ref="`driver-select-${carIndex + 1}`" class="form-control form-control-sm">
                    <option v-for="driver in drivers" :value="driver.id" :key="driver.id">{{driver.alias}}</option>
                </select>
            </span>
            </p>
            <p class="mb-0 my-3">
            <span>Tid:</span>
            <span class="float-right">
                <input :ref="`Time-input-${carIndex + 1}`" value="12:00" class="form-control form-control-sm" type="time" />
            </span>
            </p>
            <hr class="mb-1" />
            <button type="button" @click="CreatePlan(carIndex + 1, car)" class="btn btn-info btn-block btn-sm m-0">Lägg till kalender</button>
            <hr class="mt-1" />
            <Container style="min-height:150px;" group-name="car" :get-child-payload="itemIndex => GetChildPayload(carIndex + 1, itemIndex)" @drop="onDrop(carIndex + 1, $event)">
            <Draggable v-for="item in vModel[carIndex + 1]" :key="`car${carIndex + 1}-${item.id}`">
                <div class="card my-3">
                <div class="card-body pt-1">
                    <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" v-popover="{ name: `car-${item.id}-${item.orderId}` }" class="btn btn-info btn-sm btn-pill shadow">
                        <CIcon style="font-size:10px;" name="cil-zoom" />
                        </button>
                    </div>
                    </div>
                    
                    <div class="draggable-item">
                    <!-- <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ item.orderId }}</span></p> -->
                    <p class="m-0"><span>Kund :</span><span class="float-right">{{ item.customer.companyType === 0 ? item.customer.companyName : `${item.customer.firstName} ${item.customer.lastName}`}}</span></p>
                    <!-- <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p> -->
                    <!-- <p class="m-0"><span>Säljare:</span><span class="float-right">{{ item.sellerName }}</span></p> -->
                    <!-- <hr> -->
                    <!-- <p class="m-0"><span>Meddelande:</span></p> -->
                    <!-- <p class="m-0"><span>{{ item.notes }}</span></p> -->
                    </div>
                    <popover transition="show-from-bottom" class="popover-preview" :pointer="false" :name="`car-${item.id}-${item.orderId}`">
                    <div class="row">
                        <div class="col-12">
                        <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ item.orderId }}</span></p>
                        <p class="m-0"><span>Kund:</span><span class="float-right">{{ item.customer.companyType === 0 ? item.customer.companyName : `${item.customer.firstName} ${item.customer.lastName}`}}</span></p>
                        <p class="m-0"><span>Stad:</span><span class="float-right">{{ `${item.customer.city} (${item.customer.country})` }}</span></p>
                        <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p>
                        <div v-if="item.notes !== ''">
                            <hr>
                            <p class="m-0"><span>Meddelande:</span></p>
                            <p class="m-0"><span>{{ item.notes }}</span></p>
                        </div>
                        </div>
                    </div>
                </popover>
                </div>
                </div>
            </Draggable>
            </Container>
        </div>
        </div>
        <div class="col-12">
        <p class="text-center mt-3"><strong>Planeringar</strong></p>
        </div>
        <div class="row flex-row flex-nowrap" style="overflow-x: auto;">
        <div style="border: 1px solid #eee;" class="col-6 col-md-2" v-for="(plan, planIndex) in plannings" :key="plan.id" :index="planIndex">
            <div class="col-12 mt-1 mb-0 text-center">
            <button type="button" v-popover="{ name: `car-Selected-${planIndex}-${plan.id}` }" class="btn btn-info btn-sm btn-pill shadow">
                <CIcon style="font-size:10px;" name="cil-zoom" />
            </button>
            </div>
            <popover transition="show-from-bottom" class="popover-preview" :pointer="false" :name="`car-Selected-${planIndex}-${plan.id}`">
            <div class="row m-2" v-for="(order, orderIndex) in plan.orders" :key="`order-${orderIndex}-${order.id}`" style="border: 1px solid #eee">
                <div class="col-12">
                    <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ order.orderId }}</span></p>
                    <p class="m-0"><span>Kund :</span><span class="float-right">{{ order.customer.companyType === 0 ? order.customer.companyName : `${order.customer.firstName} ${order.customer.lastName}`}}</span></p>
                    <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p>
                <div v-if="order.notes !== ''">
                    <hr>
                    <p class="m-0"><span>Meddelande:</span></p>
                    <p class="m-0"><span>{{ order.notes }}</span></p>
                </div>
                </div>
            </div>
            </popover>
            <p class="mb-0"><span>Leveransdatum:</span><span class="float-right">{{ moment(plan.transportDateTime).format('YYYY-MM-DD HH:mm') }}</span></p>
            <p class="mb-0 mt-3"><span>Bil:</span><span class="float-right">{{ `${plan.car.brand} - ${plan.car.model} (${plan.car.numberPlate})` }}</span></p>
            <!-- <p class="mb-0"><span>Max vikt: </span><span class="float-right">{{plan.car.maxLoad}} kg</span></p> -->
            <!-- <p class="mb-0"><span>Total vikt: </span><span class="float-right">{{plan.totalWeight}} kg</span></p> -->
            <!-- <p class="mb-0"><span>Chaufför: </span><span class="float-right">{{plan.driverAlias}}</span></p> -->
            <hr class="mb-1" />
            <button @click="RemovePlanning(plan)" type="button" class="btn btn-danger btn-block btn-sm m-0">Ta bort</button>
            <hr class="mt-1" />
            <div style="min-height:150px;">
            <div v-for="(order, orderIndex) in plan.orders" :key="`order-${orderIndex}-${order.id}`">
                <div class="card">
                <div class="card-body pt-1">
                    <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" v-popover="{ name: `car-Selected-${planIndex}-${plan.id}-${order.id}` }" class="btn btn-info btn-sm btn-pill shadow">
                        <CIcon style="font-size:10px;" name="cil-zoom" />
                        </button>
                    </div>
                    </div>
                    <div class="draggable-item">
                    <!-- <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ order.orderId }}</span></p> -->
                    <p class="m-0"><span>Kund :</span><span class="float-right">{{ order.customer.companyType === 0 ? order.customer.companyName : `${order.customer.firstName} ${order.customer.lastName}`}}</span></p>
                    <!-- <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p> -->
                    <!-- <hr class="mb-1" /> -->
                    <button type="button" @click="RemovePlanningItem(order, plan)" style="position:absolute; top:-0.5rem;right: -1rem" class="btn btn-danger btn-sm btn-pill shadow"><CIcon style="font-size:10px;" name="cil-trash" /></button>
                    </div>
                    <popover class="popover-preview" transition="show-from-bottom" :pointer="false" :name="`car-Selected-${planIndex}-${plan.id}-${order.id}`">
                    <div class="row">
                        <div class="col-12">
                        <p class="m-0"><span>Ordernr:</span><span class="float-right">{{ order.orderId }}</span></p>
                        <p class="m-0"><span>Kund:</span><span class="float-right">{{ order.customer.companyType === 0 ? order.customer.companyName : `${order.customer.firstName} ${order.customer.lastName}`}}</span></p>
                        <p class="m-0"><span>Stad:</span><span class="float-right">{{ `${order.customer.city} (${order.customer.country})` }}</span></p>
                        <p class="m-0"><span>Total vikt:</span><span class="float-right">0 kg</span></p>
                        <div v-if="order.notes !== ''">
                            <hr>
                            <p class="m-0"><span>Meddelande:</span></p>
                            <p class="m-0"><span>{{ order.notes }}</span></p>
                        </div>
                        </div>
                    </div>
                </popover>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { OrderEntity, CarEntity, EmployeeEntity, CarPlanningEntity } from '@/services/api/ApiGeneral';
import { applyDrag, generateItems } from '@/helpers/helpers'
import Moment from 'moment';
import { Container, Draggable, smoothDnD } from "vue-smooth-dnd";
@Component({
  components: {
    Container,
    Draggable,
    smoothDnD
  }
})
export default class DragMode extends Vue {
    @Prop() private selectedDate!: string;
    private filterDate: string = this.selectedDate;
  private isDragMode: boolean = false;
  private isLoading: boolean = true;
  private vModel: any[] = [];
  private cars: CarEntity[] = [];
  private orders: OrderEntity[] = [];
  private drivers: EmployeeEntity[] = [];
  private plannings: CarPlanningEntity[] = [];
  private moment = Moment;
  private payload: CarPlanningEntity = new CarPlanningEntity({
    id: 0,
    carNr: 0,
    transportDateTime: '',
    deliveredDateTime: null,
    status: 0,
    totalWeight: 0,
    driverAlias: '',
    orders: [],
    tempOrderIds: [],
    driverFk: '',
    carFk: 0,
    selectedForPrint: false,
  });
  @Watch('selectedDate') private async WatchFilterDate(payload: string) {
    this.filterDate = payload;
    await this.LoadAll();
  }
  private onDropOrder(dropResult: any) {
    this.orders = applyDrag(this.orders, dropResult);
  }
  private onDrop(rowIndex: number, dropResult: any) {
    const result = applyDrag(this.vModel[rowIndex], dropResult);
    this.$set(this.vModel, rowIndex, result)
  }
  private GetChildPayloadOrders(index: number) {
    return this.orders[index];
  }
  private GetChildPayload(rowIndex: number, itemIndex: number) {
    return this.vModel[rowIndex][itemIndex];
  }
  private async LoadPlannings() {
    await apiGeneral.carPlanning_GetAll(this.filterDate).then((response) => {
      this.plannings = response;
    });
  }
  private async LoadOrders() {
    await apiGeneral.order_GetAllOrdersByDate(this.filterDate).then((response) => {
      this.orders = response;
      this.plannings.forEach((plan) => {
        plan.orders!.forEach((order) => {
          const itemIndex = this.orders.findIndex((item) => item!.id === order!.id)
          this.orders.splice(itemIndex, 1);
        });
      });
      this.vModel = [];
      this.$set(this.vModel, 0, this.orders);
    });
  }
  private async LoadCars() {
    await apiGeneral.car_GetAll().then((data) => {
      this.cars = data;
      this.cars.forEach((car, carIndex) => {
        this.$set(this.vModel, carIndex + 1, [] as OrderEntity[])
      });
    });
  }
  private async LoadDrivers() {
    await apiGeneral.employee_GetAllDrivers(true).then((response) => {
      this.drivers = response;
    });
  }
  private async LoadAll() {
    await this.LoadPlannings().then(async () => await this.LoadOrders().then(async () => await this.LoadCars().then(async () => await this.LoadDrivers().then(() => {
      this.isLoading = false;
    }))));
  }
  private async created() {
    this.filterDate = this.selectedDate;
    await this.LoadAll();
  }
  private async CreatePlan(index: number, selectedCar: CarEntity) {
    if (this.vModel[index].length === 0) {
      alert('Ordrar saknas för denna bil!')
      return;
    } else {
      this.payload.orders = [];
      this.payload.tempOrderIds = [];
      this.payload.transportDateTime = `${this.filterDate} ${(this.$refs[`Time-input-${index}`] as HTMLInputElement[])[0].value}`;
      const driverId = (this.$refs[`driver-select-${index}`] as HTMLSelectElement[])[0].value;
      const driver = this.drivers.find((x) => x.id === driverId);
      this.payload.driverAlias = driver!.alias;
      this.payload.driverFk = driver!.id;
      this.payload.carFk = selectedCar.id;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.vModel[index].length; i++) {
        const tempOrder = this.vModel[index][i] as OrderEntity;
        this.payload.tempOrderIds!.push(this.vModel[index][i].id);
      }
      await apiGeneral.carPlanning_Create(this.payload).then(async (response) => {
        if (response.isSuccess && response.messages != null) {
          this.payload.tempOrderIds!.forEach((item) => {
            const itemIndex = this.vModel[index].findIndex((ord: any) => ord.id === item)
            this.vModel[index].splice(itemIndex, 1);
          })
          this.payload = new CarPlanningEntity({
            id: 0,
            carNr: 0,
            transportDateTime: '',
            deliveredDateTime: null,
            status: 0,
            totalWeight: 0,
            driverAlias: '',
            orders: [],
            tempOrderIds: [],
            driverFk: '',
            carFk: 0,
            selectedForPrint: false
          });
          await this.LoadPlannings();
        } else {
          this.$beplugins.ApiError(response);
        }
      });
    }
  }
  private async RemovePlanning(plan: CarPlanningEntity) {
    const orders = plan!.orders!.map((x) => x);
    await apiGeneral.carPlanning_Delete(plan.id).then(async (response) => {
      if (response.isSuccess && response.messages != null) {
        orders.forEach((item) => {
          this.vModel[0].push(item);
        });
        await this.LoadPlannings();
      } else {
        this.$beplugins.ApiError(response);
      }
    });
  }
  private async RemovePlanningItem(item: OrderEntity, plan: CarPlanningEntity) {
    await apiGeneral.carPlanning_RemoveOrderItem(item.id, plan.id).then(async (response) => {
      if (response.isSuccess && response.messages != null) {
        this.vModel[0].push(item);
        await this.LoadPlannings();
      } else {
        this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
<style lang="scss" scoped>
[data-popover='foo'] {

  box-shadow: none;
}
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.popover-preview {
 position: fixed !important;
 width: 100% !important;
 max-height: 300px !important;
 top: 0 !important;
 left:0 !important;
 z-index: 9999 !important;
 overflow-y: scroll !important;
}
</style>