<template>
  <div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="CustomerList"
        :fields="GetTableHeaders"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
        <template #show_details="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="toggleDetails(item, index)"
            >
              {{ Boolean(item._toggled) ? "Dölj" : "Visa" }}
            </CButton>
          </td>
        </template>
        <template #details="{item}">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
            style="border-top:1px solid #d8dbe0;"
          >
            <div class="col-12 py-2">
              <h4>
                {{ item.CompanyName }}
              </h4>
              <div class="row"></div>
              <p class="text-muted">
                Telefon: {{ item.phone }}<br />
                Epost: {{ item.email }}
              </p>
              <CButton
                @click.stop.prevent="createOffer(item)"
                size="sm"
                color="warning"
                style="color:white"
              >
                Skapa offert
              </CButton>
              <CButton
                @click="listOffers(item)"
                size="sm"
                color="info"
                class="ml-1"
              >
                Offerter
              </CButton>
              <CButton
                @click.stop.prevent="createOrder(item)"
                size="sm"
                color="success"
                class="ml-1"
              >
                Skapa order
              </CButton>
              <CButton
                @click="listOrders(item)"
                size="sm"
                color="info"
                class="ml-1"
              >
                Ordrar
              </CButton>
              <CButton
                @click="editUser(item)"
                size="sm"
                color="info"
                class="ml-1"
              >
                Redigera
              </CButton>
              <CButton
                @click="removeUser(item)"
                size="sm"
                color="danger"
                class="ml-1"
              >
                Radera
              </CButton>
            </div>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CustomerEntity, CustomerModeEnums } from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
@Component({})
export default class CustomerList extends Vue {
  @Prop() private customerList!: CustomerEntity[];
  @Prop() private customerMode!: CustomerModeEnums;
  private CustomerList: CustomerEntity[] = [];
  private headersCompany = [
    { key: "customerNo", label: "KundNr", _style: "width:20%" },
    { key: "companyName", label: "Företag", _style: "width:20%" },
    { key: "country", label: "Land", _style: "width:20%;" },
    { key: "email", label: "E-post", _style: "width:20%;" },
    { key: "phone", label: "Telefon", _style: "width:20%" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private headersPrivate = [
    { key: "customerNo", label: "KundNr", _style: "width:20%" },
    { key: "firstName", label: "Förnamn", _style: "width:20%" },
    { key: "lastName", label: "Efternamn", _style: "width:20%" },
    { key: "country", label: "Land", _style: "width:20%;" },
    { key: "email", label: "E-post", _style: "width:20%;" },
    { key: "phone", label: "Telefon", _style: "width:20%" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  get GetTableHeaders() {
    return this.customerMode === 0 ? this.headersCompany : this.headersPrivate;
  }
  private collapseDuration = 0;
  private selectedUser!: CustomerEntity;
  @Watch("customerList") private CustomerListChanged() {
    this.$nextTick(() => {
      this.CustomerList = this.customerList.map((item: any, ToogleId: any) => ({
        ...item,
        ToogleId,
      }));
    });
  }
  private created() {
    this.CustomerList = this.customerList.map((item: any, ToogleId: any) => ({
      ...item,
      ToogleId,
    }));
  }
  private toggleDetails(item: any) {
    this.$set(this.CustomerList[item.ToogleId], "_toggled", !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  private createOrder(user: CustomerEntity) {
    const h = 800;
    const w = 1200;
    const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
    const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
    const new_window = window.open(
      "/OrderCreate/" + user.id,
      user.companyName!,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        y +
        ", left=" +
        x
    );
    new_window!.onbeforeunload = () => {
      // this.isLoading = true;
      // Promise.all([this.LoadOrders(), this.LoadCustomer()]).then(() => {
      //   this.isLoading = false;
      // });
    };
  }

  private createOffer(user: CustomerEntity) {
    const h = 800;
    const w = 1200;
    const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
    const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
    const new_window = window.open(
      "/OfferCreate/" + user.id,
      user.companyName!,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        y +
        ", left=" +
        x
    );
    new_window!.onbeforeunload = () => {
      // this.isLoading = true;
      // Promise.all([this.LoadOrders(), this.LoadCustomer()]).then(() => {
      //   this.isLoading = false;
      // });
    };
  }
  private async listOrders(selected: CustomerEntity) {
    this.$router.push(`/order/list/${selected.id}`);
  }
  private async listOffers(selected: CustomerEntity) {
    this.$router.push(`/offer/list/${selected.id}`);
  }
  private editUser(selected: CustomerEntity) {
    this.$router.push(`/customer/edit/${selected.id}`);
  }
  private async removeUser(selected: CustomerEntity) {
    const customerName =
      selected.customerMode === 0
        ? selected.companyName
        : `${selected.firstName} ${selected.lastName}`;
    this.$beplugins.DeleteDialog(customerName!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.customer_Delete(selected.id).then((response) => {
          if (response.isSuccess && response.messages != null) {
            this.$beplugins.ApiSuccess(response);
            this.$emit("reload");
          } else {
            this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
