<template>
  <div class="animated fadeIn">
    <Incoming :productList="productList" @reload="loadProducts" />
    <Outcoming :productList="productList" @reload="loadProducts" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from "@/services/api/ApiServices";
import Incoming from '@/components/coming/Incoming';
import Outcoming from '@/components/coming/Outcoming';
import { ProductEntity } from '@/services/api/ApiGeneral';
@Component({
  components: {
    Incoming,
    Outcoming
  }
})
export default class InAndOutComing extends Vue {
  private productList: ProductEntity[] = [];
  private async loadProducts() {
    await apiGeneral.product_GetAll().then((response) => {
      this.productList = response;
    });
  }
  private async created() {
    await this.loadProducts();
  }
}
</script>
