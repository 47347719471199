<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card" v-if="stockList.length > 0">
                <StockList :stockList="GetStockList" @reload="loadData" @dateChanged="loadData" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { StockViewModel } from '@/services/api/ApiGeneral';
import StockList from '@/components/stock/StockList.vue';
import moment from 'moment';
@Component({
  components: {
      StockList
  },
})
export default class Stock extends Vue {
    private selectedDate: string = moment().format("yyyy-MM-DD")
    private stockList: StockViewModel[] = [];
    private async created() {
        await this.loadData(this.selectedDate);
    }
    private async loadData(selectedDate: string) {
        await apiGeneral.stock_GetStockData(selectedDate).then((data) => {
            this.stockList = data;
        }).catch((err) => {
            console.log(err);
        });
    }
    get GetStockList() {
        return this.stockList;
    }
}
</script>
