import { render, staticRenderFns } from "./PriceTemplateList.vue?vue&type=template&id=0410b5d9"
import script from "./PriceTemplateList.vue?vue&type=script&lang=ts"
export * from "./PriceTemplateList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports