<template>
    <div>
        <div class="card-body">
          <CDataTable
              :pagination="false"
              :items="EmployeeList"
              :fields="headers"
              :tableFilter="{label: 'Sök', placeholder: 'ange text'}"
              :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
              :itemsPerPageSelect="{label: 'Antal rader'}"
              column-filter
              table-filter
              hover
              outlined
              sorter
              responsive>
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleDetails(item, index)"
                >
                  {{Boolean(item._toggled) ? 'Dölj' : 'Visa'}}
                </CButton>
              </td>
            </template>
            <template #details="{item}">
                  <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration" style="border-top:1px solid #d8dbe0;">
                    <div class="col-12 py-2">
                      <h4>
                        {{item.CompanyName}}
                      </h4>
                      <div class="row">

                      </div>
                      <p class="text-muted">
                        Telefon: {{item.phone}}<br />
                        Epost: {{item.email}}
                      </p>
                      <CButton @click="editUser(item)" size="sm" color="info" class="ml-1">
                        Redigera
                      </CButton>
                      <CButton @click="removeUser(item)" size="sm" color="danger" class="ml-1">
                        Radera
                      </CButton>
                    </div>
                  </CCollapse>
            </template>
          </CDataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { EmployeeEntity } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
@Component({})
export default class EmployeeList extends Vue {
  @Prop() private employeeList!: EmployeeEntity[];
  private EmployeeList: EmployeeEntity[] = [];
  private headers = [
    { key: 'firstName', label: 'Förnamn', _style: 'width:20%' },
    { key: 'lastName', label: 'Efternamn', _style: 'width:20%' },
    { key: 'country', label: 'Land', _style: 'width:20%;' },
    { key: 'email', label: 'E-post', _style: 'width:20%;' },
    { key: 'phone', label: 'Telefon', _style: 'width:20%' },
    { key: 'show_details', label: '', _style: 'width:1%', sorter: false, filter: false },
  ];
  private collapseDuration = 0;
  @Watch('employeeList') private EmployeeListChanged() {
    this.$nextTick(() => {
      this.EmployeeList = this.employeeList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
    });
  }
  private created() {
    this.EmployeeList = this.employeeList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
  }
  private toggleDetails(item: any) {
    this.$set(this.EmployeeList[item.ToogleId], '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => { this.collapseDuration = 0; });
  }
  private editUser(selected: EmployeeEntity) {
    this.$router.push(`/employee/edit/${selected.id}`);
  }
  private async removeUser(selected: EmployeeEntity) {
    const employeeName = `${selected.lastName} ${selected.firstName}`
    this.$beplugins.DeleteDialog(employeeName!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.employee_Delete(selected.email!).then((response) => {
          if (response.isSuccess && response.messages != null) {
              this.$beplugins.ApiSuccess(response);
              this.$emit('reload');
          } else {
              this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
