<template>
    <div>
        <div class="card-body">
          <CDataTable
              :pagination="false"
              :items="CustomerList"
              :fields="headers"
              :tableFilter="{label: 'Sök', placeholder: 'ange text'}"
              :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
              column-filter
              table-filter
              hover
              outlined
              sorter
              responsive>
          </CDataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CustomerSubset } from '@/services/api/ApiGeneral';
@Component({})
export default class FortnoxCustomerList extends Vue {
  @Prop() private customerList!: CustomerSubset[];
  private CustomerList: CustomerSubset[] = [];
  private headers = [
    { key: 'customerNumber', label: 'KundNr', _style: 'width:20%' },
    { key: 'name', label: 'Företag', _style: 'width:20%' },
    { key: 'organisationNumber', label: 'OrgNr', _style: 'width:20%;' },
    { key: 'email', label: 'E-post', _style: 'width:20%;' },
    { key: 'phone', label: 'Telefon', _style: 'width:20%' },
  ];
  private collapseDuration = 0;
  @Watch('customerList') private CustomerListChanged() {
    this.$nextTick(() => {
      this.CustomerList = this.customerList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
    });
  }
  private created() {
    this.CustomerList = this.customerList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
  }
  private toggleDetails(item: any) {
    this.$set(this.CustomerList[item.ToogleId], '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => { this.collapseDuration = 0; });
  }
}
</script>
