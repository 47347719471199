<template>
  <div class="animated fadeIn">
    <CarUpdate :carId="carId" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CarUpdate from '@/components/car/CarUpdate.vue';
@Component({
  components: {
      CarUpdate,
  },
})
export default class CarEdit extends Vue {
    private carId!: string;
    private created() {
        this.carId = this.$route.params.id;
    }
}
</script>
