import GlobalConfig from '@/global/GlobalConfig';
export const config = {
  authority: GlobalConfig.Oidc.authority,
  clientId: 'easyasiabackend',
  redirectUri: GlobalConfig.Oidc.redirect_uri,
  responseType: 'code',
  scope: 'openid profile offline_access',
  postLogoutRedirectUri: GlobalConfig.Oidc.post_logout_redirect_uri,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  silentRedirectUri: GlobalConfig.Oidc.silent_redirect_uri,
};
