<template>
<ValidationObserver ref="observer">
    <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <strong>Prismall</strong>
          </div>
          <div class="card-body">
            <div class="row">
                <div class="form-group col-sm-6">
                    <label class="form-label" for="name">Namn</label>
                    <ValidationProvider rules="required" v-slot="v">
                        <input v-model="vModel.name" name="name" class="form-control" id="cpname" type="text" placeholder="Vänligen ange namn..." />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="form-group col-sm-6">
                  <label class="form-label" for="isenabled">Valuta för mall</label>
                    <select v-model="vModel.currencyISO" class="form-control" id="currency">
                      <option :value="currency.ISO" v-for="(currency, currencyIndex) in GetCurrencies" :key="currency.CurrencyNumber" :index="currencyIndex">{{`${currency.Name} - ${currency.ISO}`}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group my-2">
              <input v-model="filterText" type="text" class="form-control" placeholder="Sök produkt..." />
              <div class="list-group mt-2">
                <a v-for="(product, productIndex) in filteredList" :key="product.id" :index="productIndex" href="javascript:void(0)" class="list-group-item list-group-item-action">
                  <div class="form-group my-1 row">
                    <label class="col-sm-10 col-form-label col-form-label-sm">{{ product.name }}</label>
                    <div class="col-sm-2">
                      <div class="input-group input-group-sm">
                        <input type="text" v-model="product.priceBase" class="form-control text-right" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="inputGroup-sizing-sm">{{ vModel.currencyISO }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <button type="submit" class="btn btn-success pull-right">Spara</button>
          </div>
        </div>
      </div>
    </form>
</ValidationObserver>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { PriceTemplateViewModel } from '@/services/api/ApiGeneral';
import currencies from '@/assets/data/currencies.ts';
@Component({})
export default class PriceTemplateNew extends Vue {
  private isLoading: boolean = true;
  private filterText: string | null = null;
  private vModel: PriceTemplateViewModel = new PriceTemplateViewModel({
      id: '00000000-0000-0000-0000-000000000000',
      name: '',
      currencyISO: 'SEK',
      products: []
  });
  get GetCurrencies() {
    return currencies;
  }
  private async LoadData() {
    await apiGeneral.product_GetAll().then((response) => {
        this.vModel.products = response
    });
  }
  private async created() {
    await this.LoadData();
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    await apiGeneral.priceTemplate_Create(this.vModel).then(async (response) => {
      if (response.isSuccess && response.messages != null) {
          this.$beplugins.ApiSuccess(response);
          this.vModel = new PriceTemplateViewModel({
            id: '00000000-0000-0000-0000-000000000000',
            name: '',
            currencyISO: 'SEK',
            products: []
          });
          await this.LoadData();
          (this.$refs.observer as any).reset();
      } else {
          this.$beplugins.ApiError(response);
      }
    });
  }
  get filteredList() {
    if (!this.filterText) { return this.vModel.products; }
    const searchText = this.filterText.toLowerCase();
    return this.vModel.products!.filter((u) => {
        return u.name!.toLowerCase().includes(searchText) || u.productNumber!.toLowerCase().includes(searchText) || u.tags!.some((e) => e.toLowerCase().includes(searchText));
    });
  }
}
</script>
