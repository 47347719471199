<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div ref="testPrint" class="col-10">Offertlista</div>
              <div class="col-2">
                <select v-model="filterMode" class="form-control">
                  <option :value="0">Alla</option>
                  <option :value="1">Ej fakturerade</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <CDataTable
              :pagination="false"
              :items="GetOrders"
              :fields="headers"
              :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
              :noItemsView="{
                noResults: 'hittar inget!',
                noItems: 'data saknas!',
              }"
              column-filter
              table-filter
              hover
              outlined
              sorter
              responsive
              @filtered-items-change="(x) => UpdateFilteredItems(x)"
            >
              <template #orderId="{item}">
                <td :style="`background: ${setBackgroundFlag(item)}`">
                  <a href="javascript:void(0)" @click="editOffer(item)">
                    {{ item.orderId }}
                  </a>
                </td>
              </template>
              <template #isSelected="{item}">
                <td class="p-auto text-center">
                  <input
                    type="checkbox"
                    v-model="item.isSelected"
                    :disabled="item.orderStatus === 5"
                  />
                </td>
              </template>
              <template #printedPickList="{item}">
                <td class="p-auto text-center">
                  <input
                    type="checkbox"
                    v-model="item.printedPickList"
                    @change="PrintSingle(item, false)"
                  />
                </td>
              </template>
              <template #printedDeliveryNote="{item}">
                <td class="p-auto text-center">
                  <input
                    type="checkbox"
                    v-model="item.printedDeliveryNote"
                    @change="PrintSingle(item, true)"
                  />
                </td>
              </template>
              <template #isInvoiced="{item}">
                <td class="p-auto text-center">
                  <input
                    type="checkbox"
                    :disabled="item.orderStatus === 5"
                    v-model="item.isInvoiced"
                    @change="InvoiceOrder(item)"
                  />
                  <!-- <input type="checkbox" v-model="item.isInvoiced" @change="InvoiceOrder(item)" /> -->
                </td>
              </template>
              <template #show_details="{item, index}">
                <td
                  class="py-2"
                  style="display: flex; justify-content: center;"
                >
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    class="mx-1"
                    @click="toggleDetails(item, index)"
                  >
                    {{ Boolean(item._toggled) ? "Dölj" : "Visa" }}
                  </CButton>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse
                  :show="Boolean(item._toggled)"
                  :duration="collapseDuration"
                  style="border-top:1px solid #d8dbe0;"
                >
                  <div class="col-12 py-2">
                    <h4>
                      {{
                        item.customer.customerMode === 0
                          ? `${item.customer.companyName}`
                          : `${item.customer.firstName} ${item.customer.lastName}`
                      }}
                    </h4>
                    <div class="row"></div>
                    <p class="text-muted">
                      Säljare: {{ item.sellerName }}<br />
                      Kontaktperson:
                      {{ `${item.customer.firstName} ${item.customer.lastName}`
                      }}<br />
                      Telefon: {{ item.customer.phone }}<br />
                      Epost: {{ item.customer.email }}
                    </p>
                    <CButton
                      @click="removeOrder(item)"
                      size="sm"
                      color="danger"
                      class="ml-1"
                      :disabled="item.isLocked"
                      v-if="$beplugins.GetIfPowerUser()"
                    >
                      Radera
                    </CButton>
                  </div>
                </CCollapse>
              </template>
              
            </CDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  CompanyDetails,
  InvoiceEntity,
  InvoiceProductEntity,
  OrderEntity,
  OrderStatusEnums,
  ProductEntity,
  UnitEnums,
} from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
import Moment from "moment";
const moment = Moment;
@Component({
  components: {},
})
export default class OrderList extends Vue {
  private products: ProductEntity[] = [];
  private deliveryDate = moment().format("YYYY-MM-DD");
  private offerValidDate = moment().format("YYYY-MM-DD");
  private customerFk: string = "";
  private filterMode: number = 0;
  private isLoading: boolean = true;
  private orders: OrderEntity[] = [];
  private settings: CompanyDetails = new CompanyDetails();
  private headers = [
    // { key: "isSelected", label: "", sorter: false, filter: false },
    { key: "orderId", label: "Offertnr" },
    { key: "CompanyName", label: "Kund", _style: "min-width:150px" },
    { key: "Price_ex", label: "ex moms", _style: "min-width:150px" },
    { key: "Price_inc", label: "ink moms", _style: "min-width:150px" },
    { key: "DeliveryDate", label: "Offertdatum", _style: "min-width:150px" },
    { key: "OfferValidDate", label: "Giltigt till", _style: "min-width:150px" },
    { key: "OrderStatus", label: "Orderstatus" },
    {
      key: "show_details",
      label: "",
      _style: "min-width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private details = [];
  private collapseDuration = 0;
  private filteredItems: OrderEntity[] = [];
  @Watch("$route.params.userId") private async UrlChangeEvent() {
    await this.LoadOrders();
  }
  @Watch("filterMode") private async WatchFilterDate() {
    await this.LoadOrders();
  }
  @Watch("deliveryDate") private async WatchFilterDateChange() {
    await this.LoadOrders();
  }
  private UpdateFilteredItems(payload: OrderEntity[]) {
    this.filteredItems = payload;
  }
  private RemapOrders(payload: OrderEntity[]) {
    this.orders = payload.map((item: any, ToogleId: any) => ({
      ...item,
      ToogleId,
      CreatedDate: moment(item.createdDate).format("YYYY-MM-DD HH:mm"),
      DeliveryDate: moment(item.deliveryDate).format("YYYY-MM-DD HH:mm"),
      OfferValidDate: item.offerValidDate ? moment(item.offerValidDate).format("YYYY-MM-DD HH:mm") : '-',
      CompanyName:
        item.customer!.customerMode === 0
          ? `${item.customer!.companyName}`
          : `${item.customer!.firstName} ${item.customer!.lastName}`,
      Price_ex: `${this.$beplugins.TotalPriceOrderViewModelItem(item, false)} ${
        item.customer!.currency
      }`,
      Price_inc: `${this.$beplugins.TotalPriceOrderViewModelItem(item, true)} ${
        item.customer!.currency
      }`,
      DeliveryMode: this.$t(
        `enums.${this.$beplugins.GetEnumsByValue(
          "DeliveryModeEnums",
          item.deliveryMode
        )}`
      ),
      OrderStatus: this.$t(
        `enums.${this.$beplugins.GetEnumsByValue(
          "OrderStatusEnums",
          item.orderStatus
        )}`
      ),
      PaymentStatus: this.$t(
        `enums.${this.$beplugins.GetEnumsByValue(
          "PaymentStatusEnums",
          item.paymentStatus
        )}`
      ),
      isSelected: false,
      isInvoiced: item.invoiceFk !== null,
    }));
    this.isLoading = false;
  }
  private async LoadOrders() {
    if (this.$route.params.userId === undefined) {
      const response = await apiGeneral.offer_GetAllOffers();
      this.RemapOrders(response);
    } else {
      const response = await apiGeneral.offer_GetCustomerOfferByFilterMode(this.customerFk);
      this.RemapOrders(response)
    }
  }
  private async LoadSettings() {
    await apiGeneral.companyDetail_Get().then((response) => {
      this.settings = response;
    });
  }
  private async created() {
    this.$beplugins.GetIfPowerUser();
    this.customerFk = this.$route.params.userId;
    await this.LoadOrders().then(
      async () =>
        await this.LoadSettings().then(
          async () =>
            await apiGeneral.product_GetAll().then((pList) => {
              this.products = pList;
            })
        )
    );
  }
  get GetOrders() {
    this.orders.forEach((order) => {
      order.products?.forEach((product) => {
        const tempProduct = this.products.find(
          (x) => x.id === product.productFK
        );
        product.incomingPrice = tempProduct?.priceIncoming!;
      });
    });
    return this.orders
    // return this.orders.filter((x) => {
    //   return moment(x.deliveryDate).isSameOrAfter(moment(this.deliveryDate))
    // });
  }
  private setBackgroundFlag(order: OrderEntity) {
    return order.products!.some((x) => x.incomingPrice > x.price) ? "red" : "";
  }
  private toggleDetails(item: any) {
    this.$set(item, "_toggled", !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  private async removeOrder(item: OrderEntity) {
    this.$beplugins
      .DeleteDialog(`ordernr: ${item.orderId!}`)
      .then(async (isAccept) => {
        if (isAccept) {
          await apiGeneral
            .order_Delete(item.id!, true)
            .then(async (response) => {
              if (response.isSuccess && response.messages != null) {
                this.$beplugins.ApiSuccess(response);
                await this.LoadOrders();
              } else {
                this.$beplugins.ApiError(response);
              }
            });
        }
      });
  }
  private async PrintSingle(payload: OrderEntity, isDeliveryNote: boolean) {
    if (isDeliveryNote) {
      if (payload.printedDeliveryNote) {
        if (payload.orderStatus !== 5) {
          this.$set(payload, "orderStatus", OrderStatusEnums.Transit);
        }
        this.$set(
          payload,
          "OrderStatus",
          this.$t(
            `enums.${this.$beplugins.GetEnumsByValue(
              "OrderStatusEnums",
              payload.orderStatus
            )}`
          )
        );
        if (confirm("Vill du skriva ut?")) {
          await this.$beplugins.PrintingDeliveryNote([payload]);
        }
      } else {
        if (payload.orderStatus !== 5) {
          this.$set(payload, "orderStatus", OrderStatusEnums.Pending);
        }
        this.$set(
          payload,
          "OrderStatus",
          this.$t(
            `enums.${this.$beplugins.GetEnumsByValue(
              "OrderStatusEnums",
              payload.orderStatus
            )}`
          )
        );
      }
    } else {
      if (payload.printedPickList && confirm("Vill du skriva ut?")) {
        await this.$beplugins.PrintingPickList(
          [payload],
        );
      }
    }
    if (payload.orderStatus !== 5) {
      await apiGeneral.order_UpdateOrderPrintingStatusByIds(
        [payload.id],
        isDeliveryNote,
        true
      );
    }
  }
  private editOffer(item: OrderEntity) {
    this.$beplugins.EditOffer(item.id!).then(async (result) => {
      await this.LoadOrders();
    });
  }
  private async InvoiceOrder(selected: any) {
    const isConfirmed = confirm(`Vill du fakturera ${selected.orderId}?`);
    if (isConfirmed === true) {
      const invoice: InvoiceEntity = new InvoiceEntity({
        roundingPrice: 0,
        id: 0,
        interestRateDelay: 0,
        paymentDueDays: selected.customer!.invoiceDueDays,
        status: 0,
        contactPerson: `${selected.customer!.firstName} ${
          selected.customer!.lastName
        }`,
        sellerName: selected.sellerAlias,
        isLocked: true,
        created: "2000-01-01",
        paymentDue: "2000-01-01",
        amount: parseFloat(
          this.$beplugins.TotalPriceOrderViewModelItem(selected, false)
        ),
        amountIncVAT: parseFloat(
          this.$beplugins.TotalPriceOrderViewModelItem(selected, true)
        ),
        invoiceNumber: "",
        customerNo: selected.customer!.customerNo,
        customerName:
          selected.customer!.customerMode === 0
            ? selected.customer!.companyName
            : `${selected.customer!.firstName} ${selected.customer!.lastName}`,
        currency: selected.customer!.currency,
        invoiceAddress: selected.customer!.address,
        invoiceAddress2: selected.customer!.address2,
        invoiceZipCode: selected.customer!.zipCode,
        invoiceCity: selected.customer!.city,
        invoiceCountry: selected.customer!.country,
        invoicePhone: selected.customer!.phone,
        invoiceEmail: selected.customer!.invoiceEmail,
        deliveryAddress: selected.customer!.addresses![0].address,
        deliveryAddress2: selected.customer!.addresses![0].address2,
        deliveryZipCode: selected.customer!.addresses![0].zipCode,
        deliveryCity: selected.customer!.addresses![0].city,
        deliveryCountry: selected.customer!.addresses![0].country,
        deliveryPhone: selected.customer!.addresses![0].phone,
        deliveryEmail: selected.customer!.addresses![0].email,
        customerFK: selected.customer!.id,
        products: selected.products!.map((pr: any) => {
          return new InvoiceProductEntity({
            id: 0,
            productType: pr.productType,
            quantity: pr.quantity,
            unit: pr.unit,
            price:
              pr.unit === UnitEnums.Carton
                ? (pr.pieces * pr.price) / pr.quantity
                : pr.price,
            weightInKg: 0,
            vat: pr.vat,
            partnumber: pr.productNumber,
            name: pr.name,
            invoiceFk: 0,
          });
        }),
        orders: [
          new OrderEntity({
            id: selected.id!,
            customerMode: selected.customerMode!,
            deliveryDate: selected.deliveryDate!,
            createdDate: selected.createdDate!,
            isLocked: selected.isLocked,
            deliveryMode: selected.deliveryMode,
            orderStatus: selected.orderStatus,
            paymentStatus: selected.paymentStatus,
            customerFK: selected.customerFK,
            orderId: selected.orderId,
            notes: selected.notes,
            customer: selected.customer,
            deliveryAddressFK: selected.deliveryAddressFK,
            sellerFK: selected.sellerFK,
            products: undefined,
            currency: selected.customer!.currency,
            createdBy: selected.createdBy,
            invoiceFk: 0,
            totalWeight: 0,
            selectedForPrint: false,
            printedDeliveryNote: false,
            printedPickList: false,
            isOutDated: false,
          }),
        ],
      });
      await apiGeneral
        .invoice_Create(invoice)
        .then(async (response) => {
          await apiGeneral.fortnox_CreateInvoiceList([invoice]);
          alert("Fakturan är skapad!");
          this.$nextTick(() => {
            selected.orderStatus = 5;
            selected.isLocked = true;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.$nextTick(() => {
        selected.isInvoiced = !selected.isInvoiced;
      });
    }
  }
}
</script>
