<template>
  <div class="animated fadeIn">
    <PlanningList />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PlanningList from '@/components/planning/PlanningList.vue';
@Component({
  components: {
    PlanningList
  }
})
export default class Plannings extends Vue {
}
</script>
