import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { configure } from 'vee-validate';
import { localize } from 'vee-validate';
import sv from '@/assets/rules/localization/sv.json';
import en from '@/assets/rules/localization/en.json';
import '@/assets/rules/rules';
const config = {
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
  bails: true,
  skipOptional: true,
  mode: 'aggressive',
  useConstraintAttrs: true,
};
configure(config);

localize({
  en: {
    messages: en.messages,
    names: {
      email: 'E-mail Address',
      password: 'Password',
      positive: 'test',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
      positive: {
        positive: 'test',
      },
    },
  },
  sv: sv
});
localize('sv');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// import VeeValidate from 'vee-validate';
// import '@/assets/rules/InputRules';
// Vue.use(VeeValidate, {
//   locale: 'sv',
//   dictionary: {
//     sv,
//   },
//   classes: true,
//   fieldsBagName: 'vvFields',
//   classNames: {
//     valid: 'is-valid',
//     invalid: 'is-invalid',
//   },
// });
