<template>
  <div class="animated fadeIn">
    <PriceTemplateUpdate :priceTemplateId="priceTemplateId" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PriceTemplateUpdate from '@/components/priceTemplate/PriceTemplateUpdate.vue';
@Component({
  components: {
    PriceTemplateUpdate
  }
})
export default class PriceTemplateEdit extends Vue {
  private priceTemplateId!: string;
  private created() {
    this.priceTemplateId = this.$route.params.id;
  }
}
</script>
