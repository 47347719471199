<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <ProductList :productList="GetProductList" @reload="loadData" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { ProductEntity } from '@/services/api/ApiGeneral';
import ProductList from '@/components/product/ProductList.vue';
@Component({
  components: {
      ProductList
  },
})
export default class Products extends Vue {
    private productList: ProductEntity[] = [];
    private async created() {
        await this.loadData();
    }
    private async loadData() {
        await apiGeneral.product_GetAll().then((data) => {
            this.productList = data;
        }).catch((err) => {
            console.log(err);
        });
    }
    get GetProductList() {
        return this.productList;
    }
}
</script>
