<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <strong
            >Produkthistorik - {{ vModel.name }} ({{
              vModel.productNumber
            }})</strong
          >
        </div>
        <div class="card-body">
          <CDataTable
            :pagination="false"
            :items="orderList"
            :fields="headers"
            :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
            :noItemsView="{
              noResults: 'hittar inget!',
              noItems: 'data saknas!',
            }"
            column-filter
            table-filter
            hover
            outlined
            sorter
            responsive
          >
            <template #deliveryDate="{item}">
              <td>
                <a href="javascript:void(0)" @click="editOrder(item.id)">
                  {{ moment(item.deliveryDate).format("YYYY-MM-DD") }}
                </a>
              </td>
            </template>
            <template #customer="{item}">
              <td>
                {{ item.customer.companyName }}
              </td>
            </template>
            <template #quantity="{item}">
              <td v-if="item.products.length > 1">
                <tr
                  v-for="(product, productIndex) in item.products"
                  :key="`customer-${item.customer.id}-${productIndex}`"
                >
                  <td>
                    {{ `${product.quantity} (${product.pieces} st)` }}
                  </td>
                </tr>
              </td>

              <td v-else>
                {{
                  `${item.products[0].quantity} (${item.products[0].pieces} st)`
                }}
              </td>
            </template>
            <template #singlePrice="{item}">
              <td v-if="item.products.length > 1">
                <tr
                  v-for="(product, productIndex) in item.products"
                  :key="`customer-${item.customer.id}-${productIndex}`"
                >
                  <td>
                    {{ product.price }}
                  </td>
                </tr>
              </td>

              <td v-else>
                {{ item.products[0].price }}
              </td>
            </template>
            <template #totalPrice="{item}">
              <td>
                {{ calculateTotalPrice(item.products) }}
              </td>
            </template>
          </CDataTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { apiGeneral } from "@/services/api/ApiServices";
import {
  OrderDTO,
  OrderProductDTO,
  ProductEntity,
} from "@/services/api/ApiGeneral";
import moment from "moment";
@Component({
  components: {},
})
export default class ProductHistoryDetails extends Vue {
  @Prop() private productId!: string;
  private vModel: ProductEntity | null = null;
  private orderList: OrderDTO[] = [];
  private headers = [
    { key: "deliveryDate", label: "Datum", _style: "width:20%", sorter: false },
    { key: "customer", label: "Kund", _style: "width:20%;", sorter: false },
    { key: "quantity", label: "Antal", _style: "width:20%;", sorter: false },
    { key: "singlePrice", label: "Á-pris", _style: "width:20%", sorter: false },
    { key: "totalPrice", label: "Total", _style: "width:20%", sorter: false },
  ];
  private async created() {
    await this.LoadData();
  }
  private async editOrder(orderId: string) {
    await this.$beplugins.EditOrder(orderId);
  }
  private async LoadData() {
    await apiGeneral.product_GetById(this.productId).then((prod) => {
      this.vModel = prod;
    });
    await apiGeneral
      .orderProduct_GetAllOrdersByProductId(this.productId)
      .then((prod) => {
        this.orderList = prod;
      });
  }
  private calculateTotalPrice(products: OrderProductDTO[]) {
    let total = 0;
    products.forEach((item) => {
      total += item.price * item.pieces;
    });
    return total;
  }
}
</script>
<style lang="scss" scoped></style>
