<template>
  <ValidationObserver ref="observer">
    <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Kunduppgifter</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="currency">Kundtyp</label>
                <select v-model="vModel.customerMode" class="form-control" id="currency">
                  <option :value="customerModeEnum.value" v-for="customerModeEnum in $beplugins.GetEnumsByType(
                    'CustomerModeEnums'
                  )" :key="customerModeEnum.label">{{ $t(`enums.${customerModeEnum.label}`) }}</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label class="form-label">Status</label>
                <select v-model="vModel.isEndabled" class="form-control">
                  <option :value="true">Aktiv</option>
                  <option :value="false">Inaktiv</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="pricetemplate">Prismall</label>
                <select v-model="vModel.priceTemplateFk" class="form-control" id="pricetemplate">
                  <option value="00000000-0000-0000-0000-000000000000">Ny Prismall</option>
                  <option :value="template.id" v-for="(template, templateIndex) in priceTemplateList" :key="template.id"
                    :index="templateIndex">{{ `${template.name} (${template.currencyISO})` }}</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="currency">Valuta</label>
                <select v-model="vModel.currency" class="form-control" id="currency">
                  <option :value="currency.ISO" v-for="(currency, currencyIndex) in GetCurrencies"
                    :key="currency.CurrencyNumber" :index="currencyIndex">{{ `${currency.Name} - ${currency.ISO}` }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="vModel.customerMode === 0" class="row">
              <div class="form-group col-6">
                <label class="form-label" for="companyNo">Orgnummer</label>
                <ValidationProvider rules="required|companyNo" v-slot="v">
                  <input v-model="vModel.companyNo" name="companyNo" class="form-control" id="companyNo" type="text"
                    placeholder="Vänligen ange orgnummer..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label">Momsnummer</label>
                <input v-model="vModel.vatNo" name="vatNo" class="form-control" id="vatNo" type="text"
                  placeholder="Vänligen ange momsnummer..." />
              </div>
            </div>
            <div v-if="vModel.customerMode === 0" class="form-group">
              <label class="form-label" for="companytype">Bolagsform</label>
              <select v-model="vModel.companyType" class="form-control" id="companytype">
                <option :value="companyTypeEnum.value" v-for="companyTypeEnum in $beplugins.GetEnumsByType(
                  'CompanyEnums'
                )" :key="companyTypeEnum.label">{{ $t(`enums.${companyTypeEnum.label}`) }}</option>
              </select>
            </div>
            <div v-if="vModel.customerMode === 0" class="form-group">
              <label class="form-label" for="companyname">Företagsnamn</label>
              <ValidationProvider rules="required|name" v-slot="v">
                <input v-model="vModel.companyName" name="companyname" class="form-control" id="companyname" type="text"
                  placeholder="Vänligen ange företagsnamn..." />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="firstName">Förnamn</label>
                <ValidationProvider rules="required|name" v-slot="v">
                  <input v-model="vModel.firstName" name="firstName" class="form-control" id="firstName" type="text"
                    placeholder="Vänligen ange förnamn..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="lastName">Efternamn</label>
                <ValidationProvider rules="required|name" v-slot="v">
                  <input v-model="vModel.lastName" name="lastName" class="form-control" id="lastName" type="text"
                    placeholder="Vänligen ange efternamn..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="address">Adress</label>
              <ValidationProvider rules="required" v-slot="v">
                <input v-model="vModel.address" name="address" class="form-control" id="address" type="text"
                  placeholder="Vänligen ange adress..." />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label" for="address2">Adress 2</label>
              <input v-model="vModel.address2" name="address2" class="form-control" id="address2" type="text"
                placeholder="Vänligen ange adress 2..." />
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label class="form-label" for="country">Land</label>
                <select v-model="vModel.country" class="form-control" id="country">
                  <option v-for="(country, countryIndex) in GetCountries" :key="country.code" :index="countryIndex"
                    :value="country.code">{{ country.name }}</option>
                </select>
              </div>
              <div class="form-group col-4">
                <label class="form-label" for="city">Stad</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.city" name="city" class="form-control" id="city" type="text"
                    placeholder="Vänligen ange stad..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-4">
                <label class="form-label" for="zipCode">Postnummer</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.zipCode" name="zipCode" class="form-control" id="zipCode" type="text"
                    placeholder="Vänligen ange postnummer..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="phone">Telefon</label>
                <input v-model="vModel.phone" name="phone" class="form-control" id="phone" type="text"
                  placeholder="Vänligen ange telefon..." />
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="email">E-post</label>
                <ValidationProvider rules="email" v-slot="v">
                  <input v-model="vModel.email" name="email" class="form-control" id="email" type="text"
                    placeholder="Vänligen ange e-post..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="webUrl">Hemsida</label>
              <input v-model="vModel.webUrl" name="webUrl" class="form-control" id="webUrl" type="text"
                placeholder="Vänligen ange hemsida..." />
            </div>
            <div class="form-group">
              <label class="form-label" for="notes">Anteckningar</label>
              <textarea rows="3" v-model="vModel.notes" id="notes" name="notes" class="form-control"
                placeholder="Vänligen ange anteckningar..."></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <strong>Leveransadresser</strong>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label" for="Leveransadress">Leveransadress</label>
                  <select v-model="selectedAddressId" class="form-control" id="Leveransadress">
                    <option value="00000000-0000-0000-0000-000000000000">Ny leveransadress</option>
                    <option :value="address.id" v-for="(address, addressIndex) in vModel.addresses"
                      :key="`address-row-${addressIndex}`">{{ address.name }}</option>
                  </select>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <button @click="DeleteAddress(selectedAddressId)" :disabled="
                      vModel.addresses.length < 2 ||
                      selectedAddressId ===
                      '00000000-0000-0000-0000-000000000000'
                    " type="button" class="btn btn-danger pull-right">
                      Ta bort
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label class="form-label" for="name">Namn</label>
                    <ValidationProvider rules="required|name" v-slot="v">
                      <input v-model="deliveryAddress.name" name="name" class="form-control" id="name" type="text"
                        placeholder="Vänligen ange namn..." />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-6">
                    <label class="form-label" for="isDefault">Förvald</label>
                    <select v-model="deliveryAddress.isDefault" class="form-control" id="isDefault">
                      <option :value="true">Ja</option>
                      <option :value="false">Nej</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="deliveryCondition">Leveranstid (från)</label>
                    <VueCtkDateTimePicker :no-button-now="false" format="HH:mm" formatted="HH:mm"
                      class="deliveryDate form-control form-control-sm" label="Välj tid" :no-label="true"
                      :only-time="true" :right="true" :noClearButton="true" :auto-close="false" locale="sv"
                      v-model="deliveryAddress.deliveryTimeFrom" />
                  </div>
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="deliveryCondition">Leveranstid (till)</label>
                    <VueCtkDateTimePicker :no-button-now="false" format="HH:mm" formatted="HH:mm"
                      class="deliveryDate form-control form-control-sm" label="Välj tid" :no-label="true"
                      :only-time="true" :right="true" :noClearButton="true" :auto-close="false" locale="sv"
                      v-model="deliveryAddress.deliveryTimeTo" />
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label" for="address">Adress</label>
                  <ValidationProvider rules="required" v-slot="v">
                    <input v-model="deliveryAddress.address" name="address" class="form-control" id="address"
                      type="text" placeholder="Vänligen ange adress..." />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="form-label" for="address2">Adress 2</label>
                  <input v-model="deliveryAddress.address2" name="address2" class="form-control" id="address2"
                    type="text" placeholder="Vänligen ange adress 2..." />
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label class="form-label" for="country">Land</label>
                    <select v-model="deliveryAddress.country" class="form-control" id="country">
                      <option v-for="(country, countryIndex) in GetCountries" :key="country.code" :index="countryIndex"
                        :value="country.code">{{ country.name }}</option>
                    </select>
                  </div>
                  <div class="form-group col-4">
                    <label class="form-label" for="city">Stad</label>
                    <ValidationProvider rules="required" v-slot="v">
                      <input v-model="deliveryAddress.city" name="city" class="form-control" id="city" type="text"
                        placeholder="Vänligen ange stad..." />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-4">
                    <label class="form-label" for="zipCode">Postnummer</label>
                    <ValidationProvider rules="required" v-slot="v">
                      <input v-model="deliveryAddress.zipCode" name="zipCode" class="form-control" id="zipCode"
                        type="text" placeholder="Vänligen ange postnummer..." />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label class="form-label" for="phone">Telefon</label>
                    <input v-model="deliveryAddress.phone" name="phone" class="form-control" id="phone" type="text"
                      placeholder="Vänligen ange telefon..." />
                  </div>
                  <div class="form-group col-6">
                    <label class="form-label" for="email">E-post</label>
                    <ValidationProvider rules="email" v-slot="v">
                      <input v-model="deliveryAddress.email" name="email" class="form-control" id="email" type="text"
                        placeholder="Vänligen ange e-post..." />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <button @click="CloneAddress()" type="button" class="btn btn-success pull-right">
                  Kopiera Kunduppgifter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <strong>Betal- och Leveransvillkor</strong>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="title">Säljare</label>
                    <select v-model="vModel.sellerId" class="form-control" id="title">
                      <option :value="seller.id" v-for="(seller, sellerIndex) in sellerList" :key="seller.id"
                        :index="sellerIndex">{{ seller.alias }}</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="title">Följesedel</label>
                    <select v-model="vModel.showPriceInShippingNote" class="form-control" id="title">
                      <option :value="false">Visa ej pris</option>
                      <option :value="true">Visa pris</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="deliveryCondition">Leveransvillkor</label>
                    <select v-model="vModel.deliveryCondition" class="form-control" id="deliveryCondition">
                      <option value="FK">Fritt kund</option>
                      <option value="FVL">Fritt vårt lager</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="deliveryMode">Leveranssätt</label>
                    <select v-model="vModel.deliveryMode" class="form-control" id="deliveryMode">
                      <option :value="deliveryModeEnum.value" v-for="deliveryModeEnum in $beplugins.GetEnumsByType(
                        'DeliveryModeEnums'
                      )" :key="deliveryModeEnum.label">{{ $t(`enums.${deliveryModeEnum.label}`) }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="paymentDays">Betalningsvillkor</label>
                    <select v-model="vModel.invoiceDueDays" class="form-control" id="paymentDays">
                      <option :value="0">0 dagar</option>
                      <option :value="10">10 dagar</option>
                      <option :value="15">15 dagar</option>
                      <option :value="20">20 dagar</option>
                      <option :value="30">30 dagar</option>
                    </select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label class="form-label" for="creditLimit">Kredit</label>
                    <ValidationProvider rules="money" v-slot="v">
                      <input v-model.number="vModel.creditLimit" :disabled="vModel.invoiceDueDays === 0"
                        name="creditLimit" class="form-control" id="creditLimit" type="text"
                        placeholder="Vänligen ange kreditsumma..." />
                      <span class="text-danger">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label" for="InvoiceEnums">Faktureringsmetod</label>
                  <select v-model.number="vModel.invoiceMode" class="form-control" id="InvoiceEnums">
                    <option :value="invoiceEnum.value" v-for="invoiceEnum in $beplugins.GetEnumsByType(
                      'InvoiceEnums'
                    )" :key="invoiceEnum.label">{{ $t(`enums.${invoiceEnum.label}`) }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label" for="invoiceEmail">E-post</label>
                  <ValidationProvider :rules="
                    `${vModel.invoiceMode === 0 ? 'required|email' : 'email'}`
                  " v-slot="v">
                    <input v-model="vModel.invoiceEmail" name="invoiceEmail" class="form-control" id="invoiceEmail"
                      type="text" placeholder="Vänligen ange e-post..." />
                    <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <button type="submit" class="btn btn-success pull-right">
                  Uppdatera
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { apiGeneral } from "@/services/api/ApiServices";
import {
  CustomerEntity,
  EmployeeEntity,
  PriceTemplateViewModel,
  DeliveryAddressEntity,
} from "@/services/api/ApiGeneral";
import currencies from "@/assets/data/currencies.ts";
import countries from "@/assets/data/countries.ts";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
@Component({
  components: {
    VueCtkDateTimePicker,
  },
})
export default class CustomerUpdate extends Vue {
  @Prop() private customerId!: string;
  private selectedAddressId: string = "00000000-0000-0000-0000-000000000000";
  private deliveryAddress: DeliveryAddressEntity = new DeliveryAddressEntity({
    id: "00000000-0000-0000-0000-000000000000",
    customerFk: "00000000-0000-0000-0000-000000000000",
    isDefault: false,
    name: "",
    address: "",
    address2: "",
    zipCode: "",
    city: "",
    country: "SE",
    phone: "",
    email: "",
    deliveryTimeFrom: "08:00",
    deliveryTimeTo: "16:00",
  });
  private vModel: CustomerEntity = new CustomerEntity({
    id: "00000000-0000-0000-0000-000000000000",
    isEndabled: true,
    customerMode: 0,
    showPriceInShippingNote: false,
    companyType: 0,
    deliveryCondition: "FVL",
    deliveryMode: 0,
    invoiceMode: 0,
    invoiceDueDays: 0,
    creditLimit: 0,
    isUserCreated: false,
    customerNo: "",
    companyNo: "",
    companyName: "",
    vatNo: "",
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    zipCode: "",
    city: "",
    country: "SE",
    phone: "",
    email: "",
    webUrl: "",
    invoiceEmail: "",
    currency: "SEK",
    userId: null,
    addresses: [],
    priceTemplate: null,
    priceTemplateFk: "00000000-0000-0000-0000-000000000000",
    sellerId: "",
  });
  private sellerList: EmployeeEntity[] = [];
  private priceTemplateList: PriceTemplateViewModel[] = [];
  private DeleteAddress(payload: string) {
    const deliveryIndex = this.vModel.addresses!.findIndex(
      (x) => x.id === payload
    );
    if (deliveryIndex !== -1) {
      this.vModel.addresses!.splice(deliveryIndex, 1);
      this.deliveryAddress = this.vModel.addresses![0];
      this.selectedAddressId = this.deliveryAddress.id;
    }
  }
  private CloneAddress() {
    this.deliveryAddress.address = this.vModel.address;
    this.deliveryAddress.address2 = this.vModel.address2;
    this.deliveryAddress.country = this.vModel.country;
    this.deliveryAddress.city = this.vModel.city;
    this.deliveryAddress.zipCode = this.vModel.zipCode;
    this.deliveryAddress.phone = this.vModel.phone;
    this.deliveryAddress.email = this.vModel.email;
  }
  @Watch("vModel.invoiceDueDays") private PaymentDaysEvent(payload: number) {
    if (payload === 0) {
      this.vModel.creditLimit = 0;
    }
  }
  @Watch("selectedAddressId") private DeliveryAddressEvent(payload: string) {
    if (payload === "00000000-0000-0000-0000-000000000000") {
      this.deliveryAddress = new DeliveryAddressEntity({
        id: "00000000-0000-0000-0000-000000000000",
        customerFk: this.vModel.id,
        isDefault: false,
        name: "",
        address: "",
        address2: "",
        zipCode: "",
        city: "",
        country: "SE",
        phone: "",
        email: "",
        deliveryTimeFrom: "08:00",
        deliveryTimeTo: "16:00",
      });
    } else {
      const deliveryIndex = this.vModel.addresses!.findIndex(
        (x) => x.id === payload
      );
      if (deliveryIndex !== -1) {
        this.deliveryAddress = this.vModel.addresses![deliveryIndex];
      }
    }
  }
  private async created() {
    await this.GetSellerList().then(
      async () =>
        await this.GetPriceTemplate().then(async () => await this.GetCustomer())
    );
  }
  get GetCurrencies() {
    return currencies;
  }
  get GetCountries() {
    return countries;
  }
  private async GetCustomer() {
    await apiGeneral
      .customer_GetById(this.customerId, true, true, false, false)
      .then((response) => {
        this.vModel = response;
        this.deliveryAddress = this.vModel.addresses![0];
        this.selectedAddressId = this.deliveryAddress.id;
      });
  }
  private async GetSellerList() {
    await apiGeneral.employee_GetAllSellers(true).then((response) => {
      this.sellerList = response;
    });
  }
  private async GetPriceTemplate() {
    await apiGeneral.priceTemplate_GetAll().then((response) => {
      this.priceTemplateList = response;
    });
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    if (this.deliveryAddress.id === "00000000-0000-0000-0000-000000000000") {
      this.vModel.addresses!.push(this.deliveryAddress);
    }
    await apiGeneral.customer_Update(this.vModel).then((response) => {
      if (response.isSuccess && response.messages != null) {
        this.$beplugins.ApiSuccess(response);
        (this.$refs.observer as any).reset();
        this.$router.push("/customer/list");
      } else {
        this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
