<template>
      <ValidationObserver ref="observer">
        <form class="row" @submit.prevent="onSubmit" novalidate>
            <div class="col-sm-6">
                <div class="card">
                <div class="card-header">
                    <strong>Leverantöruppgifter</strong>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-label">Status</label>
                        <select v-model="vModel.isEnabled" class="form-control">
                            <option :value="false">Inaktiv</option>
                            <option :value="true">Aktiv</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="form-group col-6">
                            <label class="form-label" for="companyNo">Orgnummer</label>
                            <ValidationProvider rules="required|companyNo" v-slot="v">
                                <input
                                v-model="vModel.companyNo"
                                name="companyNo"
                                class="form-control"
                                id="companyNo"
                                type="text"
                                placeholder="Vänligen ange orgnummer..."
                                />
                                <span class="text-danger">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label">Momsnummer</label>
                            <ValidationProvider rules="required" v-slot="v">
                                <input
                                v-model="vModel.vatNo"
                                name="vatNo"
                                class="form-control"
                                id="vatNo"
                                type="text"
                                placeholder="Vänligen ange momsnummer..."
                                />
                                <span class="text-danger">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="companyname">Företagsnamn</label>
                            <ValidationProvider rules="required|name" v-slot="v">
                                <input
                                v-model="vModel.companyName"
                                name="companyname"
                                class="form-control"
                                id="companyname"
                                type="text"
                                placeholder="Vänligen ange företagsnamn..."
                                />
                            <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    
              <div class="form-group">
                <label class="form-label" for="address">Adress</label>
                <ValidationProvider rules="required" v-slot="v">
                <input
                  v-model="vModel.address"
                  name="address"
                  class="form-control"
                  id="address"
                  type="text"
                  placeholder="Vänligen ange adress..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
              </div>
              <div class="row">
                <div class="form-group col-4">
                  <label class="form-label" for="country">Land</label>
                  <select
                    v-model="vModel.country"
                    class="form-control"
                    id="country"
                  >
                    <option
                      v-for="(country, countryIndex) in GetCountries"
                      :key="country.code"
                      :index="countryIndex"
                      :value="country.code"
                      >{{ country.name }}</option
                    >
                  </select>
                </div>
                <div class="form-group col-4">
                  <label class="form-label" for="city">Stad</label>
                  <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model="vModel.city"
                    name="city"
                    class="form-control"
                    id="city"
                    type="text"
                    placeholder="Vänligen ange stad..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group col-4">
                  <label class="form-label" for="zipCode">Postnummer</label>
                  <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model="vModel.zipCode"
                    name="zipCode"
                    class="form-control"
                    id="zipCode"
                    type="text"
                    placeholder="Vänligen ange postnummer..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              
              <div class="form-group">
                  <label class="form-label" for="webUrl">Hemsida</label>
                  <input
                    v-model="vModel.webUrl"
                    name="webUrl"
                    class="form-control"
                    id="webUrl"
                    type="text"
                    placeholder="Vänligen ange hemsida..."
                  />
                </div>
                <div class="form-group">
                    <label class="form-label">Information</label>
                    <textarea v-model="vModel.notes" rows="5" class="form-control" type="text" placeholder="Vänligen ange extra information..." />
                </div>
                </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                <div class="card-header">
                    <strong>Kontaktperson & Ekonomi</strong>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label class="form-label">Inköpare</label>
                        <select
                            v-model="vModel.buyerId"
                            class="form-control"
                            id="buyer"
                            >
                            <option
                                :value="buyer.id"
                                v-for="(buyer, buyerIndex) in buyerList"
                                :key="buyer.id"
                                :index="buyerIndex"
                                >{{ buyer.alias }}</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="form-group col-6">
                        <label class="form-label" for="firstName">Förnamn</label>
                        <ValidationProvider rules="required|name" v-slot="v">
                        <input
                            v-model="vModel.firstName"
                            name="firstName"
                            class="form-control"
                            id="firstName"
                            type="text"
                            placeholder="Vänligen ange förnamn..."
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                        </div>
                        <div class="form-group col-6">
                        <label class="form-label" for="lastName">Efternamn</label>
                        <ValidationProvider rules="required|name" v-slot="v">
                        <input
                            v-model="vModel.lastName"
                            name="lastName"
                            class="form-control"
                            id="lastName"
                            type="text"
                            placeholder="Vänligen ange efternamn..."
                        />
                        <span class="text-danger">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-6">
                            <label class="form-label" for="phone">Telefon</label>
                            <input
                            v-model="vModel.phone"
                            name="phone"
                            class="form-control"
                            id="phone"
                            type="text"
                            placeholder="Vänligen ange telefon..."
                            />
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label" for="email">E-post</label>
                            <ValidationProvider rules="email" v-slot="v">
                            <input
                            v-model="vModel.email"
                            name="email"
                            class="form-control"
                            id="email"
                            type="text"
                            placeholder="Vänligen ange e-post..."
                            />
                            <span class="text-danger">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-4">
                            <label class="form-label">IBAN</label>
                            <input v-model="vModel.bankIban" name="BankIban" class="form-control" type="text" placeholder="Vänligen ange IBAN..." />
                        </div>
                        <div class="form-group col-4">
                            <label class="form-label">BIC-kod</label>
                            <input v-model="vModel.bankBic" name="BankBic" class="form-control" type="text" placeholder="Vänligen ange BIC..." />
                        </div>
                        <div class="form-group col-4">
                            <label class="form-label">SWIFT-kod</label>
                            <input v-model="vModel.bankSwift" name="BankSwift" class="form-control" type="text" placeholder="Vänligen ange SWIFT..." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-6">
                            <label class="form-label">Bankgiro</label>
                            <input v-model="vModel.bankGiro" name="BankGiro" class="form-control" type="text" placeholder="Vänligen ange BankGiro..." />
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label">Plusgiro</label>
                            <input v-model="vModel.plusGiro" name="PlusGiro" class="form-control" type="text" placeholder="Vänligen ange PlusGiro..." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-6">
                            <label class="form-label">Kreditvärdighet</label>
                            <ValidationProvider rules="money" v-slot="v">
                                <input v-model.number="vModel.creditAmount" 
                                name="creditLimit" 
                                class="form-control" 
                                type="text" 
                                placeholder="Vänligen ange kreditvärdighet..." />
                                <span class="text-danger">{{ v.errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-6">
                            <label class="form-label" for="currency">Valuta</label>
                            <select
                                v-model="vModel.currency"
                                class="form-control"
                                id="currency"
                            >
                                <option
                                :value="currency.ISO"
                                v-for="(currency, currencyIndex) in GetCurrencies"
                                :key="currency.CurrencyNumber"
                                :index="currencyIndex"
                                >{{ `${currency.Name} - ${currency.ISO}` }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-success pull-right">Spara</button>
                </div>
                </div>
            </div>
        </form>
      </ValidationObserver>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { ProviderEntity, EmployeeEntity } from '@/services/api/ApiGeneral';
import currencies from '@/assets/data/currencies.ts';
import countries from '@/assets/data/countries.ts';
@Component({})
export default class ProviderUpdate extends Vue {
    @Prop() private providerId!: string;
  private vModel: ProviderEntity = new ProviderEntity({
      buyerId: '',
      companyName: '',
      isEnabled: true,
      id: '00000000-0000-0000-0000-000000000000',
      email: '',
      creditAmount: 0,
      customerNo: '',
      companyNo: '',
      vatNo: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      zipCode: '',
      city: '',
      country: 'SE',
      phone: '',
      webUrl: '',
      currency: 'SEK',
      bankIban: '',
      bankBic: '',
      bankSwift: '',
      bankGiro: '',
      plusGiro: '',
      notes: '',
      provider_Products: []
  });
  private buyerList: EmployeeEntity[] = [];
  get GetCountries() {
    return countries;
  }
  get GetCurrencies() {
    return currencies;
  }
  private async GetProvider() {
    await apiGeneral.provider_GetById(this.providerId).then((response) => {
      this.vModel = response;
    });
  }
  private async GetBuyersList() {
    await apiGeneral.employee_GetAllBuyers(true).then((response) => {
      this.buyerList = response;
    });
  }
  private async created() {
    await this.GetBuyersList().then(async () => await this.GetProvider());
  }
private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    await apiGeneral.provider_Update(this.vModel).then((response) => {
      if (response.isSuccess && response.messages != null) {
          this.$beplugins.ApiSuccess(response);
          (this.$refs.observer as any).reset();
          this.$router.push('/provider/list')
      } else {
          this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
