<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img src="@/assets/images/newLogo.png" class="c-avatar-img " />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Konto</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Meddelande
      <CBadge color="success" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Uppgifter
      <CBadge color="danger" class="ml-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem to='/signout'> <CIcon name="cil-lock-locked" /> Logga ut </CDropdownItem>
  </CDropdown>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class HeaderDropdownAccnt extends Vue {
  private itemsCount = 0;
}
</script>
<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
