<template>
  <div class="animated fadeIn">
    <EmployeeNew />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import EmployeeNew from '@/components/employee/EmployeeNew.vue';
@Component({
  components: {
      EmployeeNew,
  },
})
export default class EmployeeCreate extends Vue {}
</script>
