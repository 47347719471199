<template>
  <ValidationObserver ref="observer">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <strong>Inköpslista</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-2">
                <label class="form-label">Status</label>
                <select
                  :disabled="vModel.isCompleted"
                  v-model="vModel.status"
                  class="form-control"
                >
                  <option
                    v-for="(status, statusIndex) in $beplugins.GetEnumsByType(
                      'PurchaseStatusEnums'
                    )"
                    :key="`status-row-${statusIndex}-${status.label}`"
                    :value="status.value"
                    >{{ $t(`enums.${status.label}`) }}</option
                  >
                </select>
              </div>
              <div class="form-group col-sm-2">
                <label class="form-label">Leveransdatum</label>
                <VueCtkDateTimePicker
                  :disabled="vModel.isCompleted"
                  :no-button-now="true"
                  format="YYYY-MM-DD HH:mm"
                  formatted="YYYY-MM-DD HH:mm"
                  class="deliveryDate form-control form-control-sm"
                  :no-label="true"
                  locale="sv"
                  v-model="vModel.arrivalAt"
                />
              </div>
              <div class="form-group col-sm-4">
                <label class="form-label">Leverantör</label>
                <v-select
                  class="product-select"
                  ref="v-select-product-list"
                  :clearable="false"
                  placeholder="sök..."
                  :disabled="vModel.isCompleted || vModel.products.length > 0"
                  :options="providerList"
                  :filter-by="$beplugins.ProviderFilter"
                  @input="(provider) => ProviderChangeEvent(provider)"
                  v-model="selectedProvider"
                  label="companyName"
                >
                  <div slot="no-options">hittar inga leverantörer!</div>
                  <template v-slot:option="option">
                    {{ option.companyNo }} - {{ option.companyName }}
                  </template>
                </v-select>
              </div>
              <div
                v-if="
                  vModel.providerFk !== '00000000-0000-0000-0000-000000000000'
                "
                class="form-group col-sm-4"
              >
                <label class="form-label">Produkter</label>
                <v-select
                  :disabled="vModel.isCompleted"
                  class="product-select"
                  :clearable="false"
                  placeholder="sök..."
                  :options="GetAllProductsFilter"
                  :reset-on-options-change="true"
                  :filter-by="$beplugins.OrderProductFilter"
                  @input="(product) => ProductChangeEvent(product)"
                  label="name"
                >
                  <div slot="no-options">hittar inga produkter!</div>
                  <template v-slot:option="option">
                    {{ option.productNumber }} - {{ option.name }}
                    <span class="float-right"
                      >Saldo: {{ option.inStock }} st</span
                    >
                  </template>
                </v-select>
              </div>
            </div>
            <button
              v-if="isProductSelected"
              @click="addProduct()"
              class="btn btn-success pull-right"
            >
              Lägg till produkt
            </button>
          </div>
        </div>
        <div v-if="vModel.products.length > 0" class="card">
          <div class="card-header">
            <strong>Kundvagn</strong>
          </div>
          <div class="card-body">
            <div class="table-responsive-sm">
              <table class="table table-sm  table-hover">
                <thead>
                  <tr>
                    <th style="width:80px;">#</th>
                    <th style="width:200px;">Artikelnr</th>
                    <th>Produkt</th>
                    <th style="width:200px;" class="text-center">Antal</th>
                    <th style="width:200px;" class="text-center">À-pris</th>
                    <th style="width:200px;" class="text-center">Belopp</th>
                    <th style="width:300px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(prod, prodIndex) in vModel.products"
                    :key="`row-${prodIndex}-${prod.name}`"
                  >
                    <td>{{ ++prodIndex }}</td>
                    <td>{{ prod.productNumber }}</td>
                    <td>{{ prod.name }}</td>
                    <td class="text-center">
                      <input
                        class="table-input-number"
                        @keyup="
                          $beplugins.InputValidatorMoney(prod, 'quantity')
                        "
                        v-model="prod.quantity"
                      />{{
                        $t(
                          `enums.${$beplugins.GetEnumsByValue(
                            "ProductUnitEnums",
                            prod.unit
                          )}`
                        )
                      }}
                    </td>
                    <td class="text-center">
                      <input
                        class="table-input-number"
                        @keyup="$beplugins.InputValidatorMoney(prod, 'price')"
                        v-model="prod.price"
                      />
                      {{ selectedProvider.currency }}
                    </td>
                    <td class="text-right">
                      {{ (prod.price * prod.quantity) | money }}
                      {{ selectedProvider.currency }}
                    </td>
                    <td class="text-right">
                      <CIcon
                        @click.native="showHistory(prod)"
                        class="mr-4"
                        style="font-size:10px;cursor: pointer"
                        v-popover="{ name: prod.productFK }"
                        name="cil-zoom"
                      />
                      <CButton
                        :disabled="vModel.isCompleted"
                        @click="updateProviderPrice(prod.productFK, prod.price)"
                        size="sm"
                        color="info"
                        class="ml-1"
                      >
                        Uppdatera leverantörspris
                      </CButton>
                      <CButton
                        :disabled="vModel.isCompleted"
                        @click="removeProduct(prodIndex - 1)"
                        size="sm"
                        color="danger"
                        class="ml-1"
                      >
                        Ta bort
                      </CButton>
                    </td>
                    <popover
                      transition="show-from-bottom"
                      class="popover-preview"
                      :pointer="false"
                      :name="prod.productFK"
                    >
                      <div
                        v-if="selectedProductLog.id !== ''"
                        class="row mx-auto my-4"
                      >
                        <div class="col-6">
                          <h3 class="text-left">
                            Leverantör: {{ selectedProvider.companyName }} -
                            {{ selectedProvider.companyNo }}
                          </h3>
                        </div>
                        <div class="col-6">
                          <h3 class="text-right">
                            Produkt: {{ prod.name }} - {{ prod.productNumber }}
                          </h3>
                        </div>
                        <hr
                          v-if="
                            selectedProductLog.orderList &&
                              selectedProductLog.orderList.length > 0
                          "
                          style="border-bottom: 1px solid #d8dbe0 !important; width: 100%;"
                        />

                        <div
                          v-if="
                            selectedProductLog.orderList &&
                              selectedProductLog.orderList.length > 0
                          "
                          class="col-12"
                        >
                          <table class="table table-sm">
                            <thead>
                              <th style="border-top:none;">Företag</th>
                              <th class="text-left" style="border-top:none">
                                Ordernr
                              </th>
                              <th class="text-right" style="border-top:none">
                                Pris
                              </th>
                              <th class="text-right" style="border-top:none;">
                                Datum
                              </th>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(purchase,
                                purchaseIndex) in selectedProductLog.orderList"
                                :key="
                                  `purchase-pruchaserow-${purchaseIndex}-id-${purchase.id}`
                                "
                              >
                                <td>
                                  {{ purchase.purchase.provider.companyName }}
                                </td>
                                <td>{{ purchase.purchase.purchaseId }}</td>
                                <td class="text-right">
                                  {{
                                    `${purchase.price} ${purchase.purchase.provider.currency}`
                                  }}
                                </td>
                                <td class="text-right">
                                  {{ purchase.purchase.created }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr
                          style="border-bottom: 1px solid #d8dbe0 !important; width: 100%;"
                        />
                        <div
                          v-show="prov.logs.length > 0"
                          class="col-12 col-md-6"
                          v-for="(prov,
                          provIndex) in selectedProductLog.provider_Products"
                          :key="
                            `log-provider-row-${provIndex}-id-${prov.providerId}`
                          "
                        >
                          <h3>
                            {{ prov.provider.companyName }} -
                            {{ prov.provider.companyNo }}
                          </h3>
                          <table class="table table-sm">
                            <thead>
                              <th style="border-top:none;">Pris</th>
                              <th class="text-center" style="border-top:none">
                                Diff ({{ prov.provider.currency }})
                              </th>
                              <th class="text-center" style="border-top:none">
                                Diff (%)
                              </th>
                              <th class="text-right" style="border-top:none;">
                                Datum
                              </th>
                              <th class="text-right" style="border-top:none;">
                                Användare
                              </th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <CIcon
                                    v-if="prov.logs.length > 0"
                                    :name="
                                      `cil-level-${
                                        prov.price > prov.logs[0].price
                                          ? 'up'
                                          : 'down'
                                      }`
                                    "
                                    :style="
                                      `color:${
                                        prov.price > prov.logs[0].price
                                          ? 'red'
                                          : 'green'
                                      }`
                                    "
                                  />
                                  <span class="float-right"
                                    >{{ prov.price.toFixed(2) }}
                                    {{ prov.provider.currency }}</span
                                  >
                                </td>
                                <td class="text-center">
                                  <span v-if="prov.logs.length > 0">{{
                                    `${
                                      prov.price > prov.logs[0].price ? "+" : ""
                                    }${(
                                      prov.price - prov.logs[0].price
                                    ).toFixed(2)}`
                                  }}</span>
                                  <span v-else> - </span>
                                </td>
                                <td class="text-center">
                                  <span v-if="prov.logs.length > 0">{{
                                    `${
                                      prov.price > prov.logs[0].price ? "+" : ""
                                    }${(
                                      ((prov.price - prov.logs[0].price) /
                                        prov.price) *
                                      100
                                    ).toFixed(2)} %`
                                  }}</span>
                                  <span v-else> - </span>
                                </td>
                                <td class="text-right">-</td>
                                <td class="text-right">-</td>
                              </tr>
                              <tr
                                v-for="(log, logIndex) in prov.logs"
                                :key="
                                  `log-provider-row-${logIndex}-id-${prov.providerId}-id-${log.id}`
                                "
                              >
                                <td>
                                  <CIcon
                                    v-if="prov.logs.length !== logIndex + 1"
                                    :name="
                                      `cil-level-${
                                        prov.logs[logIndex + 1].price >
                                        log.price
                                          ? 'down'
                                          : 'up'
                                      }`
                                    "
                                    :style="
                                      `color:${
                                        prov.logs[logIndex + 1].price >
                                        log.price
                                          ? 'green'
                                          : 'red'
                                      }`
                                    "
                                  />
                                  <span class="float-right"
                                    >{{ log.price.toFixed(2) }}
                                    {{ prov.provider.currency }}</span
                                  >
                                </td>
                                <td class="text-center">
                                  <span
                                    v-if="prov.logs.length !== logIndex + 1"
                                    >{{
                                      `${
                                        prov.logs[logIndex + 1].price >
                                        log.price
                                          ? ""
                                          : "+"
                                      }${(
                                        log.price -
                                        prov.logs[logIndex + 1].price
                                      ).toFixed(2)}`
                                    }}</span
                                  >
                                  <span v-else> - </span>
                                </td>
                                <td class="text-center">
                                  <span
                                    v-if="prov.logs.length !== logIndex + 1"
                                    >{{
                                      `${
                                        prov.logs[logIndex + 1].price >
                                        log.price
                                          ? ""
                                          : "+"
                                      }${(
                                        ((log.price -
                                          prov.logs[logIndex + 1].price) /
                                          log.price) *
                                        100
                                      ).toFixed(2)} %`
                                    }}</span
                                  >
                                  <span v-else> - </span>
                                </td>
                                <td class="text-right">
                                  {{
                                    moment(log.updated).format(
                                      "yyyy-MM-DD HH:mm"
                                    )
                                  }}
                                </td>
                                <td class="text-right">
                                  {{ log.updatedByName }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </popover>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="5">
                      Antal rader:
                      {{
                        vModel.products.filter((x) => x.productNumber).length
                      }}
                    </td>
                    <td colspan="2">
                      <strong>Summa: </strong>
                      <span class="float-right"
                        >{{
                          $beplugins.TotalPriceOrderViewModelItem(
                            vModel,
                            false
                          ) | money
                        }}
                        {{ selectedProvider.currency }}</span
                      >
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <button
              :disabled="vModel.isCompleted"
              @click="onSubmit"
              type="button"
              class="btn btn-success pull-right"
            >
              Spara
            </button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { apiGeneral } from "@/services/api/ApiServices";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import vSelect from "vue-select";
import {
  PurchaseEntity,
  ProviderEntity,
  ProductEntity,
  PurchaseProductEntity,
  Provider_Product_LogEntity,
  Join_Provider_Product,
} from "@/services/api/ApiGeneral";
import currencies from "@/assets/data/currencies.ts";
import countries from "@/assets/data/countries.ts";
import Moment from "moment";
const moment = Moment;
@Component({
  components: {
    VueCtkDateTimePicker,
    vSelect,
  },
})
export default class PurchaseUpdate extends Vue {
  @Prop() private providerId!: string;
  private providerList: ProviderEntity[] = [];
  private selectedProvider: ProviderEntity = new ProviderEntity({
    id: "",
    buyerId: "",
    creditAmount: 0,
    isEnabled: false,
    customerNo: "",
    companyNo: "",
    companyName: "",
    vatNo: "",
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    zipCode: "",
    city: "",
    country: "",
    phone: "",
    email: "",
    webUrl: "",
    currency: "",
    bankIban: "",
    bankBic: "",
    bankSwift: "",
    bankGiro: "",
    plusGiro: "",
    notes: "",
  });
  private productList: ProductEntity[] = [];
  private selectedProduct: ProductEntity = new ProductEntity({
    id: "",
    mainProviderFk: "",
    priceIncoming: 0,
    minPointDays: 0,
    priceBase: 0,
    pricePrivate: 0,
    packingSorting: 0,
    inStock: 0,
    weight: 0,
    averageWeight: 0,
    cartonPieces: 0,
    cartonWeight: 0,
    status: 0,
    price: 0,
    productType: 0,
    vat: 0,
    unit: 0,
    quantityInUnit: 0,
    productNumber: "",
    name: "",
    designation: "",
    description: "",
    tags: [],
    barCode: "",
    qrCode: "",
    storageLocation: "",
    grossWeight: 0,
  });
  private selectedProductLog: ProductEntity = new ProductEntity();
  private isProductSelected: boolean = false;
  private addPurchaseProduct: PurchaseProductEntity = new PurchaseProductEntity(
    {
      id: "00000000-0000-0000-0000-000000000000",
      unit: 0,
      quantity: 1,
      purchaseFk: "00000000-0000-0000-0000-000000000000",
      productFK: "00000000-0000-0000-0000-000000000000",
      price: 0,
      vat: 0,
      name: "",
      productNumber: "",
    }
  );
  private vModel: PurchaseEntity = new PurchaseEntity({
    id: "00000000-0000-0000-0000-000000000000",
    providerFk: "00000000-0000-0000-0000-000000000000",
    products: [],
    sellerFK: "00000000-0000-0000-0000-000000000000",
    checkedInAt: null,
    arrivalAt: null,
    sellerAlias: null,
    created: moment().format("yyyy-MM-DD HH:mm"),
    status: 0,
    provider: null,
    isCompleted: false,
  });
  get GetAllProductsFilter(): ProductEntity[] {
    return this.productList!.filter(
      (x) => !this.vModel.products!.filter((y) => y.productFK === x.id).length
    );
  }
  private async loadProviders() {
    await apiGeneral.provider_GetAll().then((data) => {
      this.providerList = data;
    });
  }
  private async loadProducts() {
    await apiGeneral.product_GetAll().then((response) => {
      this.productList = response;
    });
  }
  private async ProviderChangeEvent(payload: ProviderEntity) {
    this.selectedProvider = payload;
    this.isProductSelected = false;
    this.selectedProduct = new ProductEntity({
      id: "",
      mainProviderFk: "",
      priceIncoming: 0,
      minPointDays: 0,
      priceBase: 0,
      pricePrivate: 0,
      packingSorting: 0,
      inStock: 0,
      weight: 0,
      averageWeight: 0,
      cartonPieces: 0,
      cartonWeight: 0,
      status: 0,
      price: 0,
      productType: 0,
      vat: 0,
      unit: 0,
      quantityInUnit: 0,
      grossWeight: 0,
    });
    this.vModel.providerFk = this.selectedProvider.id;
    window.setTimeout(() => {
      const el = document.getElementsByClassName("vs__search");
      (el[1] as any).focus();
    }, 100);
  }
  private ProductChangeEvent(payload: ProductEntity) {
    this.selectedProduct = payload === null ? new ProductEntity() : payload;
    this.isProductSelected = payload === null ? false : true;
    if (payload !== null) {
      const providerIndex = payload.provider_Products!.findIndex(
        (x) => x.providerId === this.vModel.providerFk
      );
      this.addPurchaseProduct = new PurchaseProductEntity({
        id: "00000000-0000-0000-0000-000000000000",
        unit: 0,
        quantity: 1,
        purchaseFk: "00000000-0000-0000-0000-000000000000",
        productFK: this.selectedProduct.id,
        price:
          providerIndex !== -1
            ? payload.provider_Products![providerIndex].price
            : 0,
        vat: this.selectedProduct.vat,
        name: this.selectedProduct.name,
        productNumber: this.selectedProduct.productNumber,
      });
      this.addProduct();
    } else {
      this.addPurchaseProduct = new PurchaseProductEntity({
        id: "00000000-0000-0000-0000-000000000000",
        unit: 0,
        quantity: 1,
        purchaseFk: "00000000-0000-0000-0000-000000000000",
        productFK: "00000000-0000-0000-0000-000000000000",
        price: 0,
        vat: 0,
        name: "",
        productNumber: "",
      });
    }
  }
  private async updateProviderPrice(productId: string, price: number) {
    const user = this.$beplugins.GetOidcUser();
    const priceLog: Provider_Product_LogEntity = new Provider_Product_LogEntity(
      {
        id: 0,
        price: price,
        updated: moment().format("yyyy-MM-DD HH:mm"),
        updatedById: user.sub,
        updatedByName: user.name,
        provider_Product: new Join_Provider_Product({
          price: price,
          providerId: this.vModel.providerFk!,
          productId: productId,
        }),
      }
    );
    await apiGeneral
      .providerProductLog_Create(priceLog)
      .then(async (response) => {
        if (response.isSuccess && response.messages != null) {
          this.$beplugins.ApiSuccess(response);
          (this.$refs.observer as any).reset();
          await this.loadProviders().then(
            async () => await this.loadProducts()
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  private showHistory(product: PurchaseProductEntity) {
    const productIndex = this.productList.findIndex(
      (x) => x.id === product.productFK
    );
    if (productIndex !== -1) {
      this.selectedProductLog = this.productList[productIndex];
    }
  }
  private addProduct() {
    this.vModel.products!.push(this.addPurchaseProduct);
    window.setTimeout(() => {
      const el = document.getElementsByClassName("vs__search");
      (el[1] as any).focus();
    }, 100);
  }
  private removeProduct(index: number) {
    this.vModel.products!.splice(index, 1);
  }
  private async LoadData() {
    await apiGeneral.purchase_GetById(this.providerId).then((result) => {
      this.vModel = result;
    });
  }
  private async created() {
    await Promise.all([
      this.LoadData(),
      this.loadProviders(),
      this.loadProducts(),
    ]).then(() => {
      this.selectedProvider = this.providerList.find(
        (x) => x.id === this.vModel.providerFk
      )!;
    });
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    await apiGeneral.purchase_Update(this.vModel).then((response) => {
      if (response.isSuccess && response.messages != null) {
        this.$beplugins.ApiSuccess(response);
        (this.$refs.observer as any).reset();
        this.$router.push("/purchase/list");
      } else {
        this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.popover-preview {
  position: fixed !important;
  width: 100% !important;
  max-height: 50% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  overflow-y: scroll !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
