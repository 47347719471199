<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img class="c-sidebar-brand-full" src="@/assets/images/newLogo.png" height="50" alt="Easy Asia AB">
      <img class="c-sidebar-brand-minimized" src="@/assets/images/newLogo.png" width="40"  alt="EA">
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import nav from '@/assets/data/nav';
@Component({})
export default class Sidebar extends Vue {
  private created() {
    (this.$options as any).nav = nav.items;
  }
  get show() {
    return this.$store.state.sidebarShow;
  }
  get minimize() {
    return this.$store.state.sidebarMinimize;
  }
}
</script>
<style lang="scss">
.c-sidebar .c-sidebar-brand {
  background: #fff !important;
}
</style>
