<template>
  <div class="animated fadeIn">
    <PurchaseNew />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PurchaseNew from '@/components/purchase/PurchaseNew.vue';
@Component({
  components: {
    PurchaseNew
  }
})
export default class PurchaseCreate extends Vue {
}
</script>
