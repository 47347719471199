<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding: 0.3rem .3rem !important;">
          <label>Fortnox artiklar</label> 
          <CButton @click.stop.prevent="UpdateArticles()" size="sm" color="primary" class="pull-right mr-2">
            Uppdatera artiklar
          </CButton>
        </div>
        <FortnoxArticleList :articleList="GetArticleList" @reload="loadArticles" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { ArticleSearch, ArticleSubset } from '@/services/api/ApiGeneral';
import FortnoxArticleList from '@/components/fortnox/articles/FortnoxArticleList.vue';
@Component({
  components: {
    FortnoxArticleList,
  },
})
export default class FortnoxArticles extends Vue {
  private articleList: ArticleSubset[] = [];
  private searchFilter: ArticleSearch = new ArticleSearch({
    page: 1,
    limit: 1000000,
  });
  private async created() {
    await this.loadArticles();
  }
  private async loadArticles() {
    await apiGeneral.fortnox_GetAllArticles(this.searchFilter).then((data) => {
      this.articleList = data;
    }).catch((err) => {
      console.log(err);
    });
  }
  private async UpdateArticles() {
    await apiGeneral.fortnox_CloneAllArticlesToFortnox(this.searchFilter).then(async () => {
      await this.loadArticles();
    });
  }
  get GetArticleList() {
    return this.articleList;
  }
}
</script>
