<template>
    <div>
        <div class="card-header">Prismallar</div>
        <div class="card-body">
        <CDataTable
            :pagination="false"
            :items="PriceTemplateList"
            :fields="headers"
            :tableFilter="{label: 'Sök', placeholder: 'ange text'}"
            :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
            column-filter
            table-filter
            hover
            outlined
            sorter
            responsive
    >
        <template #show_details="{item, index}">
            <td class="py-2">
            <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="toggleDetails(item, index)"
            >
                {{Boolean(item._toggled) ? 'Dölj' : 'Visa'}}
            </CButton>
            </td>
        </template>
        <template #details="{item}">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration" style="border-top:1px solid #d8dbe0;">
                <div class="col-12 py-2">
                    <h4>
                    {{item.companyName}}
                    </h4>
                    <div class="row">

                    </div>
                    <p class="text-muted">
                    Telefon: {{item.phone}}<br />
                    Epost: {{item.email}}
                    </p>
                    <CButton @click="editPriceTemplate(item)" size="sm" color="info" class="ml-1">
                    Redigera
                    </CButton>
                    <CButton @click="removePriceTemplate(item)" size="sm" color="danger" class="ml-1">
                    Radera
                    </CButton>
                </div>
                </CCollapse>
        </template>
        </CDataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PriceTemplateViewModel } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
@Component({})
export default class PriceTemplateList extends Vue {
@Prop() private priceTemplateList!: PriceTemplateViewModel[];
  private PriceTemplateList: PriceTemplateViewModel[] = [];
  private headers = [
    { key: 'name', label: 'Namn', _style: 'width:20%' },
    { key: 'show_details', label: '', _style: 'width:1%', sorter: false, filter: false },
  ];
  private details = [];
  private collapseDuration = 0;
  private toggleDetails(item: any) {
    this.$set(this.PriceTemplateList[item.ToogleId], '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => { this.collapseDuration = 0; });
  }
  @Watch('priceTemplateList') private ProviderListChanged() {
    this.$nextTick(() => {
        this.PriceTemplateList = this.priceTemplateList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
    });
  }
  private created() {
    this.PriceTemplateList = this.priceTemplateList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
  }
  private editPriceTemplate(selected: PriceTemplateViewModel) {
    this.$router.push(`/pricetemplate/edit/${selected.id}`);
  }
  private async removePriceTemplate(selected: PriceTemplateViewModel) {
    const productName = selected.name
    this.$beplugins.DeleteDialog(productName!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.priceTemplate_Delete(selected.id!).then((response) => {
          if (response.isSuccess && response.messages != null) {
              this.$beplugins.ApiSuccess(response);
              this.$emit('reload');
          } else {
              this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
