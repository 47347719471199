import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import "./registerServiceWorker";
import CoreuiVue from "@coreui/vue";
import BePlugins from "@/plugins/BePlugin";
import moment from "moment";
Vue.prototype.moment = moment;
import "@/assets/rules/index";
Vue.use(BePlugins);
// @ts-ignore
import { iconsSet as icons } from "@/assets/icons/icons";

import VueSweetalert2 from "vue-sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import VTooltip from "v-tooltip";
import vmodal from "vue-js-modal";
import "@/filters/formats";
import i18n from "./i18n";
import Popover from "vue-js-popover";

Vue.use(Popover);
Vue.use(CoreuiVue as any);
Vue.use(vmodal);
Vue.use(VTooltip);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  // @ts-ignore
  icons,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
