<template>
  <div v-if="GetLoadingState" id="spinner-container">
    <div id="card-container" class="mx-auto" style="max-width:350px; border-radius:10px;">
      <h5 class="text-center">
        <div class="logo">
          <div class="logo__front" />
          <div class="logo__edge">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
          <div class="logo__back" />
          <div class="logo__shadow" />
        </div>
        <div style="margin-top:50px;" class="row">
          <div style="text-align:center; padding-bottom:0;color:#fff;" class="col-12">
            <b style="font-size:12px;">Loading...</b>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center" style="color:#fff;">
            Powered by
            <a href="http://www.benader.com" class="powered-by" style="color:#fff;" target="_blank"
              >Benader AB</a
            >
            &#169;{{ new Date().getFullYear() }}
          </div>
        </div>
      </h5>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class Spinner extends Vue {
  get GetLoadingState() {
    return this.$store.getters['getLoadingState'];
  }
}
</script>
<style scoped lang="scss">
$logo-diameter: 200px;
$logo-thickness: 10px;
$logo-color: #ffffff;
$logo-front: '@/assets/images/newLogo.png';
$logo-back: '@/assets/images/newLogo.png';
$edge-faces: 80;
$edge-face-length: 3.14 * $logo-diameter/$edge-faces;
$turn-time: 8s;
.powered-by {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
#spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9989 !important;
  height: 100% !important;
  width: 100%;
  #card-container {
    top: 50%;
    margin-top: -170px;
    position: fixed;
    height: 340px;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    margin-left: auto;
    margin-right: auto;
  }
  .loading-message {
    color: #d32f2f;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
}
.logo {
  position: relative;
  width: $logo-diameter;
  height: $logo-diameter;
  transform-style: preserve-3d;
  animation: rotate3d $turn-time linear infinite;
  transition: all 0.3s;
  margin: 0 auto;
  padding-top: 20px;
}

.logo__front,
.logo__back {
  position: absolute;
  width: $logo-diameter;
  height: $logo-diameter;
  border-radius: 50%;
  overflow: hidden;
  background-color: $logo-color;

  &:after {
    content: '';
    position: absolute;
    left: -$logo-diameter/2;
    bottom: 100%;
    display: block;
    height: $logo-diameter/1.5;
    width: $logo-diameter * 2;
    background: #fff;
    opacity: 0.3;
    animation: shine linear $turn-time/2 infinite;
  }
}

.logo__front {
  background-image: url($logo-front);
  background-size: cover;
  transform: translateZ($logo-thickness/2);
}
.logo__back {
  background-image: url($logo-back);
  background-size: cover;
  transform: translateZ(-$logo-thickness/2) rotateY(180deg);
}

.logo__edge {
  @for $i from 1 through $edge-faces {
    div:nth-child(#{$i}) {
      position: absolute;
      height: $edge-face-length;
      width: $logo-thickness;
      background: darken(
        $logo-color,
        (($i - $edge-faces/2) * ($i - $edge-faces/2)) /
          ($edge-faces * $edge-faces/4) *
          100 *
          0.7
      );
      transform: translateY(#{$logo-diameter/2-$edge-face-length/2})
        translateX(#{$logo-diameter/2-$logo-thickness/2})
        rotateZ(360deg / $edge-faces * $i + 90)
        translateX(#{$logo-diameter/2})
        rotateY(90deg);
    }
  }
}

.logo__shadow {
  position: absolute;
  width: $logo-diameter;
  height: $logo-thickness;
  border-radius: 50%;
  background: #000;
  box-shadow: 0 0 $logo-thickness * 5 $logo-thickness * 5 #000;
  opacity: 0.125;
  transform: rotateX(90deg) translateZ(-$logo-diameter * 1.1) scale(0.5);
}
@keyframes rotate3d {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

@keyframes shine {
  0%,
  15% {
    transform: translateY($logo-diameter * 2) rotate(-40deg);
  }
  50% {
    transform: translateY(-$logo-diameter) rotate(-40deg);
  }
}
</style>
