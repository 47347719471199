<template>
    <div>
        <div class="card-body">
          <CDataTable
              :pagination="false"
              :items="CarList"
              :fields="headers"
              :tableFilter="{label: 'Sök', placeholder: 'ange text'}"
              :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
              column-filter
              table-filter
              hover
              outlined
              sorter
              responsive>
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleDetails(item, index)"
                >
                  {{Boolean(item._toggled) ? 'Dölj' : 'Visa'}}
                </CButton>
              </td>
            </template>
            <template #details="{item}">
                  <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration" style="border-top:1px solid #d8dbe0;">
                    <div class="col-12 py-2">
                      <h4>
                        {{item.CompanyName}}
                      </h4>
                      <div class="row">

                      </div>
                      <p class="text-muted">
                        Telefon: {{item.phone}}<br />
                        Epost: {{item.email}}
                      </p>
                      <CButton @click="editCar(item)" size="sm" color="info" class="ml-1">
                        Redigera
                      </CButton>
                      <CButton @click="removeCar(item)" size="sm" color="danger" class="ml-1">
                        Radera
                      </CButton>
                    </div>
                  </CCollapse>
            </template>
          </CDataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CarEntity } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
@Component({})
export default class CarList extends Vue {
  @Prop() private carList!: CarEntity[];
  private CarList: CarEntity[] = [];
  private headers = [
    { key: 'name', label: 'Namn', _style: 'width:20%' },
    { key: 'brand', label: 'Märke', _style: 'width:20%' },
    { key: 'model', label: 'Modell', _style: 'width:20%' },
    { key: 'numberPlate', label: 'Regnummer', _style: 'width:20%' },
    { key: 'show_details', label: '', _style: 'width:1%', sorter: false, filter: false },
  ];
  private collapseDuration = 0;
  @Watch('carList') private CustomerListChanged() {
    this.$nextTick(() => {
      this.CarList = this.carList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
    });
  }
  private created() {
    this.CarList = this.carList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
  }
  private toggleDetails(item: any) {
    this.$set(this.CarList[item.ToogleId], '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => { this.collapseDuration = 0; });
  }
  private editCar(selected: CarEntity) {
    this.$router.push(`/car/edit/${selected.id}`);
  }
  private async removeCar(selected: CarEntity) {
    this.$beplugins.DeleteDialog(selected.name!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.car_Delete(selected.id).then((response) => {
          if (response.isSuccess && response.messages != null) {
              this.$beplugins.ApiSuccess(response);
              this.$emit('reload');
          } else {
              this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
