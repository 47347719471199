import { extend } from 'vee-validate';
import { required, email, numeric } from 'vee-validate/dist/rules';
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Minimum eight characters, at least one letter and one number:
const passwordRegex1 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

// Minimum eight characters, at least one letter, one number and one special character:
const passwordRegex2 = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
const passwordRegex3 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
const passwordRegex4 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

// Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
const passwordRegex5 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/;
extend('numeric', numeric);
extend('email', email);
extend('required', required);
extend('name', {
    validate(value) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1 && value.length > 1 && value.length < 51
      }
    },
    computesRequired: true
  });
extend('companyNo', {
    validate(value) {
      return {
        required: true,
        valid: /^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/.test(value)
      }
    },
    computesRequired: true
  });
extend('money', {
    validate(value) {
      return {
        required: true,
        valid: /^[0-9]+(\.[0-9]{1,2})?$/.test(value)
      }
    },
    computesRequired: true
  });
extend('password', {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target;
  },
});
extend('passwordRegex', {
  validate(value) {
    return {
      required: true,
      valid: passwordRegex3.test(value)
    }
  },
  computesRequired: true
});
