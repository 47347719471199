<template>
  <div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
@Component
export default class OidcCallback extends Vue {
  @Action("oidcSignInCallback") private oidcSignInCallback!: () => Promise<string>;
  private created() {
    this.oidcSignInCallback()
      .then((redirectPath) => { this.$router.push(redirectPath); })
      .catch((err) => {
        console.error(err);
        this.$router.push("/signin-oidc-error"); // Handle errors any way you want
      });
  }
}
</script>