<template>
  <div v-if="!isLoading" class="card print" style="border:none !important; ">
    <img class="watermark-logo" src="@/assets/images/watermark.png" />
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-6 text-center" style="border-right: 1px solid rgba(0,0,21,.2)">
          <img class="" src="@/assets/images/newLogo.png" height="100" />
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <h2 class="text-left">
                Faktura
              </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <h6 class="mb-3"><b>Faktnr:</b><br />{{vModel.invoiceNumber}}</h6>
            </div>
            <div class="col-4">
              <h6 class="mb-3"><b>Kundnr:</b><br />{{vModel.customerNo}}</h6>
            </div>
            <div class="col-4">
              <h6 class="mb-3"><b>Fakturadatum:</b><br />{{moment(vModel.created).format('YYYY/MM/DD')}}</h6>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-6" style="border-right: 1px solid rgba(0,0,21,.2)">
          <h6 class="mb-3">
            <b>Leveransadress:</b><br />
            {{ `${vModel.customerName}`}}<br />
            {{ `${vModel.deliveryAddress}, ${vModel.deliveryZipCode} ${vModel.deliveryCity}, ${vModel.deliveryCountry}` }}<br />
            {{vModel.deliveryPhone}}<br />
            {{vModel.deliveryEmail}}
          </h6>
        </div>
        <div class="col-6">
          <h6 class="mb-3">
            <b>Fakturaadress:</b><br />
            {{ `${vModel.customerName}`}}<br />
            {{ `${vModel.invoiceAddress}, ${vModel.invoiceZipCode} ${vModel.invoiceCity}, ${vModel.invoiceCountry}` }}<br />
            {{vModel.invoicePhone}}<br />
            {{vModel.invoiceEmail}}
          </h6>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-6">
          <h6 class="mb-3">
            <table style="width: 100%;">
              <tr>
                <td><b>Er referens:</b></td>
                <td>{{vModel.sellerName}}</td>
              </tr>
              <tr>
                <td><b>Er ordernr:</b></td>
                <td>
                  <span style="width: 100%;" v-for="(order, orderIndex) in vModel.orders" :key="order.id">
                    {{ `(${order.orderId} - ${moment(order.deliveryDate).format('YYYY/MM/DD')} - ${$t( `enums.${$beplugins.GetEnumsByValue('DeliveryModeEnums', order.deliveryMode)}`)})` }} {{ orderIndex === vModel.orders.length -1 ? '' : ''}}
                    </span>
                </td>
              </tr>
            </table>
          </h6>
        </div>
        <div class="col-6">
          <h6 class="mb-3">
            <table style="width: 100%;">
              <tr>
                <td><b>Vår referens:</b></td>
                <td>{{vModel.contactPerson}}</td>
              </tr>
              <tr>
                <td><b>Betalningsvillkor:</b></td>
                <td>{{ vModel.paymentDueDays }} dagar netto</td>
              </tr>
              <tr>
                <td><b>Förfallodatum:</b></td>
                <td>{{ moment(vModel.paymentDue).format('YYYY/MM/DD') }}</td>
              </tr>
              <tr>
                <td><b>Dröjsmålränta:</b></td>
                <td>{{ vModel.interestRateDelay.toFixed(2) }} %</td>
              </tr>
            </table>
          </h6>
        </div>
      </div>
      <div class="table-responsive-sm mt-5">
        <table class="table table-sm table-striped table-hover table-printview">
          <thead>
            <tr>
              <th>#</th>
              <th>Artikelnr</th>
              <th>Produkt</th>
              <th>Antal</th>
              <th class="text-right">À-pris</th>
              <th class="text-right">Belopp</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in vModel.products"
              :key="`${row.partnumber}-${row.name}-${rowIndex}`"
              :index="rowIndex"
            >
              <td>{{ ++rowIndex }}</td>
              <td>{{ row.partnumber }} {{row.productType}}</td>
              <td>{{ row.name }}</td>
              <td>{{ row.quantity }} {{ $t( `enums.${$beplugins.GetEnumsByValue('UnitEnums', row.unit)}`) }}</td>
              <td class="text-right">
                {{ row.price | money }} {{ vModel.currency }}
              </td>
              <td class="text-right">
                {{ (row.price * row.quantity) | money }} {{ vModel.currency }}
              </td>
            </tr>
          </tbody>
           <tfoot>
            <tr>
              <td>
                <b>Netto</b><br />
                {{ vModel.amount | money }}
              </td>
              <td>
                <b>Exkl moms</b><br />
                {{ vModel.amount | money }}
              </td>
              <td>
                <b>Moms (%)</b><br />
                <span v-for="(vat, vatIndex) in vModel.vats" :key="`vat-${vat}-${vatIndex}`">{{ vat.vat | money }}<br /></span>
              </td>
              <td>
                <b>Moms ({{ vModel.currency}})</b><br />
                <span v-for="(vat, vatIndex) in vModel.vats" :key="`vat-${vat}-${vatIndex}`">{{ vat.total | money }}<br /></span>
                <p style="border-top:1px solid rgba(0,0,21,.2); width: 100%;">
                {{ vModel.amountIncVAT | money }}
                </p>
              </td>
              <td class="text-right">
                <b>Öresavr</b><br />
                {{ vModel.roundingPrice | money }}
              </td>
              <td class="text-right">
                <b>ATT BETALA:</b><br />
                {{ vModel.roundingPrice + vModel.amountIncVAT | money }} {{ vModel.currency }}
              </td>
            </tr>
          </tfoot>
        </table>
        
      </div>
    </div>
    <div class="table-responsive-sm mt-5">
      <div class="row" style="border-top:1px solid rgba(0,0,21,.2);">
        <div class="col-12">
          <h6 class="my-2 text-center">{{settings.invoiceMessage}}</h6>
        </div>
      </div>
      <table class="table-blue-fotter table table-borderless" style="border-top:1px solid rgba(0,0,21,.2);">
        <tr>
          <td>
            <b>Företag</b><br />
            {{settings.companyName}} <br />
            <b>Organisationsnr</b><br />
            {{settings.companyNr}}
          </td>
          <td>
            <b>Adress</b><br />
            {{settings.addressLine1}}<br />
            {{ `${settings.postalCode} ${settings.city},  ${settings.country}` }}<br />
            <b>Telefon</b><br />
            {{settings.phone}}
          </td>
          <td>
            <b>Hemsida</b><br />
            {{settings.website}}<br />
            <b>E-post</b><br />
            {{settings.email1}}<br />
            {{settings.email2}}
          </td>
          <td>
            <div v-if="settings.bankGiro !== ''">
              <b>Bankgiro</b><br />
              {{settings.bankGiro}}<br />
            </div>
            <div v-if="settings.plusGiro !== ''">
              <b>Plusgiro</b><br />
              {{settings.plusGiro}}<br />
            </div>
            <div v-if="settings.iban !== ''">
              <b>IBAN</b><br />
              {{settings.iban}}<br />
            </div>
          </td>
          <td>
            <b>Momsreg.nr</b><br />
            {{settings.vatNr}}<br />
            <b>Innehar F-skattebevis</b>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { InvoiceEntity, CompanyDetails } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
import Loading from 'vue-loading-overlay';
@Component({
  components: {
    Loading,
  },
})
export default class PrintInvoice extends Vue {
  private isLoading: boolean = true;
  private settings: CompanyDetails = new CompanyDetails();
  private totalWeight: number = 0;
  private vModel: InvoiceEntity = new InvoiceEntity();

  private async LoadOrder() {
    await apiGeneral.invoice_GetById(parseInt(this.$route.params.id, 0)).then((response) => {
      this.vModel = new InvoiceEntity(response);
    });
  }
  private async LoadSettings() {
    await apiGeneral.companyDetail_Get().then((response) => {
      this.settings = response;
    });
  }
  private async created() {
    await this.LoadOrder().then(async () => await this.LoadSettings().then(() => {
      this.isLoading = false;
    }));
  }
}
</script>
<style lang="scss">

</style>
