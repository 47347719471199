<template>
  <div class="animated fadeIn">
    <CustomerUpdate :customerId="customerId" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CustomerUpdate from '@/components/customer/CustomerUpdate.vue';
@Component({
  components: {
      CustomerUpdate,
  },
})
export default class CustomerEdit extends Vue {
    private customerId!: string;
    private created() {
        this.customerId = this.$route.params.id;
    }
}
</script>
