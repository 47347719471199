<template>
  <ValidationObserver ref="observer">
    <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Produktuppgifter</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label">Artikelnr</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model="vModel.productNumber"
                    name="productNumber"
                    class="form-control"
                    type="text"
                    placeholder="Vänligen ange artikelnr..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label">Status</label>
                <select v-model="vModel.status" class="form-control">
                  <option :value="0">Aktiv</option>
                  <option :value="1">Inaktiv</option>
                  <option :value="2">Utgående</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Namn</label>
              <ValidationProvider rules="required" v-slot="v">
                <input
                  v-model="vModel.name"
                  name="name"
                  class="form-control"
                  type="text"
                  placeholder="Vänligen ange namn..."
                />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label">Benämning</label>
              <input
                v-model="vModel.designation"
                name="designation"
                class="form-control"
                type="text"
                placeholder="Vänligen ange benämning..."
              />
            </div>
            <div class="form-group">
              <label class="form-label">Beskrivning</label>
              <textarea
                v-model="vModel.description"
                rows="3"
                name="description"
                class="form-control"
                placeholder="Vänligen ange beskrivning..."
              ></textarea>
            </div>
            <div class="row">
              <div class="form-group col-3">
                <label class="form-label">Typ</label>
                <select v-model="vModel.productType" class="form-control">
                  <option
                    :value="productTypeEnum.value"
                    v-for="productTypeEnum in $beplugins.GetEnumsByType(
                      'ProductTypeEnums'
                    )"
                    :key="productTypeEnum.label"
                    >{{ $t(`enums.${productTypeEnum.label}`) }}</option
                  >
                </select>
              </div>
              <div class="form-group col-3">
                <label class="form-label">Enhet</label>
                <select v-model="vModel.unit" class="form-control">
                  <option
                    :value="unitEnum.value"
                    v-for="unitEnum in $beplugins.GetEnumsByType(
                      'ProductUnitEnums'
                    )"
                    :key="unitEnum.label"
                    >{{ $t(`enums.${unitEnum.label}`) }}</option
                  >
                </select>
              </div>
              <div class="form-group col-3">
                <label class="form-label">Nettovikt (kg)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.weight"
                    name="totalWeightInKg"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange nettovikt..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label">Bruttovikt (kg)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.grossWeight"
                    name="grossWeightInKg"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange bruttovikt..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-3">
                <label class="form-label">Minpunkt (dagar)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.minPointDays"
                    name="minPointDays"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange minpunkt..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label">Antal i kartong (st)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.cartonPieces"
                    :disabled="vModel.unit === 2"
                    name="cartonPieces"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange antal i enhet..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label">Totalvikt (kg)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.cartonWeight"
                    :disabled="vModel.unit === 2"
                    name="cartonWeight"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange totalvikt..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <label class="form-label">Genomsnittsvikt (kg)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.averageWeight"
                    name="averageWeight"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange genomsnittsvikt..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label class="form-label">Lagersaldo (st)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model.number="vModel.inStock"
                    name="inStock"
                    class="form-control"
                    type="number"
                    placeholder="Vänligen ange antal i enhet..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-4">
                <label class="form-label">Lagerplats</label>
                <input
                  v-model="vModel.storageLocation"
                  name="storageLocation"
                  class="form-control"
                  type="text"
                  placeholder="Vänligen ange lagerplats..."
                />
              </div>
              <div class="form-group col-4">
                <label class="form-label">Sorteringplats</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    v-model="vModel.packingSorting"
                    name="packingSorting"
                    class="form-control"
                    type="text"
                    placeholder="Vänligen ange sorteringplats..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label class="form-label">Momskod</label>
                <select v-model="vModel.vat" class="form-control">
                  <option
                    v-for="item in VatList"
                    :value="item.value"
                    :key="item.label"
                    >{{ item.label }}</option
                  >
                </select>
              </div>
              <div class="form-group col-4">
                <label class="form-label">Baspris</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    @keyup="$beplugins.InputValidatorMoney(vModel, 'priceBase')"
                    v-model="vModel.priceBase"
                    name="priceBase"
                    class="form-control"
                    placeholder="Vänligen ange baspris..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-4">
                <label class="form-label">Pris (Privatkund)</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input
                    @keyup="
                      $beplugins.InputValidatorMoney(vModel, 'pricePrivate')
                    "
                    v-model="vModel.pricePrivate"
                    name="pricePrivate"
                    class="form-control"
                    placeholder="Vänligen ange pris..."
                  />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Extra information</strong>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label class="form-label">Taggar</label>
              <vue-tags-input
                v-model="tag"
                placeholder="Lägg till taggar"
                :tags="tags"
                @tags-changed="(newTags) => (tags = newTags)"
              />
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label">Streckkod</label>
                <input
                  v-model="vModel.barCode"
                  name="barCode"
                  class="form-control"
                  type="text"
                  placeholder="Vänligen ange streckkod..."
                />
              </div>
              <div class="form-group col-6">
                <label class="form-label">QR-kod</label>
                <input
                  v-model="vModel.qrCode"
                  name="qrCode"
                  class="form-control"
                  type="text"
                  placeholder="Vänligen ange QR-kod..."
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label">Inpris</label>
                <input
                  @keyup="
                    $beplugins.InputValidatorMoney(vModel, 'priceIncoming')
                  "
                  v-model="vModel.priceIncoming"
                  name="priceIncoming"
                  class="form-control"
                  type="text"
                  placeholder="Vänligen ange inpris..."
                />
              </div>
              <div class="form-group col-6">
                <label class="form-label">Huvudleverantör</label>
                <ValidationProvider rules="required" v-slot="v">
                  <select
                    v-model="vModel.mainProviderFk"
                    name="Huvudleverantör"
                    class="form-control"
                  >
                    <option
                      v-for="item in selectedProviders"
                      :value="item.Id"
                      :key="item.Label"
                      >{{ item.Label }}</option
                    >
                  </select>
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Leverantörer</label>
              <DualListBox
                :source="sourceForDualList"
                :isValue="true"
                @DualListOnChange="UpdateProductProviders"
              />
            </div>
            <button
              v-if="$beplugins.HasRoles(['Ansvarig', 'Administrator', 'Admin'])"
              type="submit"
              class="btn btn-success pull-right"
            >
              Spara
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiGeneral } from "@/services/api/ApiServices";
import {
  ProviderEntity,
  ProductEntity,
  Join_Provider_Product,
  ProductUnitEnums,
} from "@/services/api/ApiGeneral";
import Vats from "@/assets/data/vat";
import { VueTagsInput } from "@johmun/vue-tags-input";
import DualListBox from "@/components/tools/DualListBox.vue";
import { DualListBoxViewModel } from "@/types/tools/DualListBoxViewModel";
@Component({
  components: {
    VueTagsInput,
    DualListBox,
  },
})
export default class ProductNew extends Vue {
  private selectedProviders: DualListBoxViewModel[] = [];
  private vModel: ProductEntity = new ProductEntity({
    mainProviderFk: "",
    priceIncoming: 0,
    priceBase: 0,
    minPointDays: 0,
    pricePrivate: 0,
    packingSorting: 0,
    inStock: 0,
    weight: 0,
    averageWeight: 0,
    cartonPieces: 0,
    cartonWeight: 0,
    status: 0,
    price: 0,
    id: "00000000-0000-0000-0000-000000000000",
    productType: 0,
    vat: 12,
    unit: 0,
    quantityInUnit: 0,
    productNumber: "",
    name: "",
    designation: "",
    description: "",
    tags: [],
    barCode: "",
    qrCode: "",
    storageLocation: "",
    provider_Products: [],
    priceTemplate_Products: [],
    grossWeight: 0,
  });
  private providerList: ProviderEntity[] = [];
  private tag: string = "";
  private tags: any[] = [];
  private sourceForDualList: DualListBoxViewModel[] = [];
  @Watch("selectedProviders") private SelectedProviderChangeEvent(
    providers: DualListBoxViewModel[]
  ) {
    if (
      this.vModel.mainProviderFk !== "" &&
      providers.findIndex((x) => x.Id === this.vModel.mainProviderFk) === -1
    ) {
      this.vModel.mainProviderFk = "";
    }
  }
  @Watch("vModel.unit") private CartonAvailableChangeEvent(
    value: ProductUnitEnums
  ) {
    if (value === ProductUnitEnums.Carton) {
      this.vModel.cartonPieces = 1;
      this.vModel.cartonWeight = this.vModel.weight;
    } else if (this.vModel.cartonPieces === 0) {
      this.vModel.cartonPieces = 0;
      this.vModel.cartonWeight = this.vModel.weight;
    } else {
      this.vModel.cartonWeight = this.vModel.cartonPieces * this.vModel.weight;
    }
  }
  @Watch("vModel.weight") private WeightChangeEvent(value: number) {
    if (this.vModel.unit === ProductUnitEnums.Carton) {
      this.vModel.cartonWeight = this.vModel.weight;
    } else if (this.vModel.cartonPieces === 0) {
      this.vModel.cartonWeight = value;
    } else {
      this.vModel.cartonWeight = this.vModel.cartonPieces * value;
    }
  }
  @Watch("vModel.cartonPieces") private CartonPiecesChangeEvent(value: number) {
    if (this.vModel.unit !== ProductUnitEnums.Carton) {
      this.vModel.cartonWeight = this.vModel.weight * value;
    }
    if (value === 0) {
      this.vModel.cartonWeight = this.vModel.weight;
    }
  }
  get VatList() {
    return Vats;
  }
  private async created() {
    await this.LoadData();
  }
  private async LoadData() {
    await apiGeneral.provider_GetAll().then((response) => {
      this.providerList = response;
      this.providerList.forEach((provider) => {
        this.sourceForDualList.push({
          Id: provider.id!,
          Label: provider.companyName!,
          Value: "0",
          Selected: false,
          Extra1: provider.currency!,
        });
      });
    });
  }
  private UpdateProductProviders(payload: DualListBoxViewModel[]) {
    this.selectedProviders = payload;
    this.vModel.provider_Products = [];
    if (payload !== null && payload.length > 0) {
      payload.forEach((item) => {
        this.vModel.provider_Products!.push(
          new Join_Provider_Product({
            providerId: item.Id,
            price: Number(item.Value),
            productId: "00000000-0000-0000-0000-000000000000",
          })
        );
      });
    }
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    // tslint:disable-next-line:arrow-return-shorthand
    this.vModel.tags = this.tags.map((x) => {
      return x.text;
    });
    await apiGeneral.product_Create(this.vModel).then(async (response) => {
      if (response.isSuccess && response.messages != null) {
        this.$beplugins.ApiSuccess(response);
        this.vModel = new ProductEntity({
          mainProviderFk: "",
          priceIncoming: 0,
          priceBase: 0,
          minPointDays: 0,
          pricePrivate: 0,
          packingSorting: 0,
          inStock: 0,
          weight: 0,
          averageWeight: 0,
          cartonPieces: 0,
          cartonWeight: 0,
          status: 0,
          price: 0,
          id: "00000000-0000-0000-0000-000000000000",
          productType: 0,
          vat: 0,
          unit: 0,
          quantityInUnit: 0,
          productNumber: "",
          name: "",
          designation: "",
          description: "",
          tags: [],
          barCode: "",
          qrCode: "",
          storageLocation: "",
          provider_Products: [],
          priceTemplate_Products: [],
          grossWeight: 0,
        });
        this.sourceForDualList.forEach((item) => {
          item.Selected = false;
          item.Value = "0";
        });
        (this.$refs.observer as any).reset();
      } else {
        this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.vue-tags-input {
  width: 100% !important;
  max-width: 100% !important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #ebde6e;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border: 1px solid #8b9396;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #ebde6e;
  color: #283944;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #ebde6e;
  color: #ebde6e;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}
</style>
