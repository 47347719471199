import { render, staticRenderFns } from "./ProductHistoryDetails.vue?vue&type=template&id=b72d2918&scoped=true"
import script from "./ProductHistoryDetails.vue?vue&type=script&lang=ts"
export * from "./ProductHistoryDetails.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b72d2918",
  null
  
)

export default component.exports