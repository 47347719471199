<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
            <div class="col-6">Fakturor</div>
            <div class="col-6">
              <VueCtkDateTimePicker
                style="width:120px !important;"
                :no-button-now="false"
                format="YYYY-MM-DD"
                formatted="YYYY-MM-DD"
                class="deliveryDate form-control form-control-sm float-right"
                :no-label="true"
                :only-date="true"
                :right="true"
                :noClearButton="true"
                :auto-close="true"
                locale="sv"
                @input="x => WatchFilterDate(x)"
                v-model="filterDate" />
                <span class="float-right mr-3 mt-1">Filtrera efter leveransdatum</span>
            </div>
            </div>
          </div>
          <div class="card-body">
            <CDataTable
              :pagination="false"
              :items="invoices"
              :fields="headers"
              :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
              :noItemsView="{
                noResults: 'hittar inget!',
                noItems: 'data saknas!',
              }"
              column-filter
              table-filter
              hover
              outlined
              sorter
              responsive
            >
            <template #isSelected="{item}">
                <td class="p-auto text-center">
                  <input type="checkbox" v-model="item.isSelected" />
                </td>
              </template>
              <template #invoiceNumber="{item}">
                <td>
                  <a href="javascript:void(0)" @click="$beplugins.PrintingInvoice([item], settings)">
                    {{ item.invoiceNumber }}
                  </a>
                </td>
              </template>
              <template #show_details="{item, index}">
                <td
                  class="py-2"
                  style="display: flex; justify-content: center;"
                >
                  <CButton
                    color="primary"
                    variant="outline"
                    square
                    size="sm"
                    class="mx-1"
                    @click="toggleDetails(item, index)"
                  >
                    {{ Boolean(item._toggled) ? 'Dölj' : 'Visa' }}
                  </CButton>
                </td>
              </template>
              <template #details="{item}">
                <CCollapse
                  :show="Boolean(item._toggled)"
                  :duration="collapseDuration"
                  style="border-top:1px solid #d8dbe0;"
                >
                  <div class="col-12 py-2">
                    <h4>test</h4>
                  </div>
                </CCollapse>
              </template>
              <template v-slot:under-table>
                <CButton
                  :disabled="invoices.filter(x => x.isSelected).length < 1"
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  class="my-2 mx-1 float-right"
                  @click="PrintSelected()"
                >
                  Skriv ut markerade
                </CButton>
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  class="my-2 mx-1 float-right"
                  @click="SelectAll()"
                >
                  Markera allt
                </CButton>
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  class="my-2 mx-1 float-right"
                  @click="DeSelectAll()"
                >
                  Avmarkera allt
                </CButton>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CompanyDetails, InvoiceEntity } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Moment from 'moment';
const moment = Moment;
@Component({
  components: {
    VueCtkDateTimePicker
  }
})
export default class InvoiceList extends Vue {
  private filterDate: string = '';
  private isLoading: boolean = true;
  private invoices: InvoiceEntity[] = [];
  private details = [];
  private collapseDuration = 0;
  private settings: CompanyDetails = new CompanyDetails();
  private headers = [
    { key: 'isSelected', label: '', sorter: false, filter: false },
    { key: 'invoiceNumber', label: 'FakturaNr' },
    { key: 'customerNo', label: 'KundNr' },
    { key: 'customerName', label: 'Kund', _style: 'min-width:150px' },
    { key: 'Price_ex', label: 'ex moms', _style: 'min-width:150px' },
    { key: 'Price_inc', label: 'ink moms', _style: 'min-width:150px' },
    { key: 'Created', label: 'Fakturadatum' },
    { key: 'PaymentDue', label: 'Förfallodatum' },
    { key: 'Status', label: 'Status', _style: 'text-align:center !important' },
    {
      key: 'show_details',
      label: '',
      _style: 'min-width:150px;border: none',
      sorter: false,
      filter: false,
    },
  ];
  private async WatchFilterDate(payload: string) {
    this.filterDate = payload;
    await this.LoadOrders();
  }
  private async LoadSettings() {
    await apiGeneral.companyDetail_Get().then((response) => {
      this.settings = response;
    });
  }
  private async LoadOrders() {
    await apiGeneral.invoice_GetAll(this.filterDate).then((response) => {
      this.invoices = response.map((item: any, ToogleId: any) => ({
        ...item,
        ToogleId,
        Created: moment(item.created).format('YYYY-MM-DD HH:mm'),
        PaymentDue: moment(item.paymentDue).format('YYYY-MM-DD HH:mm'),
        Price_ex: `${item.amount.toFixed(2)} ${item.currency}`,
        Price_inc: `${item.amountIncVAT.toFixed(2)} ${item.currency}`,
        Status: this.$t(`enums.${this.$beplugins.GetEnumsByValue('InvoiceStatusEnums', item.status)}`),
        isSelected: false
      }));
    });
  }
  private toggleDetails(item: any) {
    this.$set(item, '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  private async created() {
    const my_date = new Date();
    const first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    this.filterDate = Moment(first_date).format('YYYY-MM-DD');
    await this.LoadSettings();
    await this.LoadOrders();
  }
  private async removeInvoice(selected: InvoiceEntity) {
    this.$beplugins.DeleteDialog(`fakturanr: ${selected.invoiceNumber!}`).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.invoice_Delete(selected.id!).then(async (response) => {
          if (response.isSuccess && response.messages != null) {
            this.$beplugins.ApiSuccess(response);
            await this.LoadOrders();
          } else {
            this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
  private SelectAll() {
    this.invoices.forEach((x: any) => {
      x.isSelected = true;
    });
  }
  private DeSelectAll() {
    this.invoices.forEach((x: any) => {
      x.isSelected = false;
    });
  }
  private async PrintSelected() {
    const selectedItems = this.invoices.filter((x: any) => x.isSelected === true);
    await this.$beplugins.PrintingInvoice(selectedItems, this.settings);
  }
}
</script>
