<template>
  <div>
    <div class="card-header">Produkter</div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="ProductList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
        <template #productNumber="{item}">
          <td>
            <a href="javascript:void(0)" @click="editProduct(item)">{{
              item.productNumber
            }}</a>
          </td>
        </template>
        <template #Status="{item}">
          <td>
            <CBadge :color="getBadge(item.status).style">
              {{ getBadge(item.status).label }}
            </CBadge>
          </td>
        </template>
        <template #show_details="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="toggleDetails(item, index)"
            >
              {{ Boolean(item._toggled) ? "Dölj" : "Visa" }}
            </CButton>
          </td>
        </template>
        <template #details="{item}">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
            style="border-top:1px solid #d8dbe0;"
          >
            <div class="col-12 py-2 text-right">
              <CButton
                @click="removeProduct(item)"
                size="sm"
                color="danger"
                class="ml-1"
              >
                Radera
              </CButton>
              <CButton
                @click="editProduct(item)"
                size="sm"
                color="info"
                class="ml-1"
              >
                Redigera
              </CButton>
              <CButton
                @click="historyProduct(item)"
                size="sm"
                color="success"
                class="ml-1"
              >
                Historik
              </CButton>
            </div>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ProductEntity } from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
@Component({})
export default class ProductList extends Vue {
  @Prop() private productList!: ProductEntity[];
  private ProductList: ProductEntity[] = [];
  private headers = [
    { key: "productNumber", label: "ArtNr", _style: "width:5%" },
    { key: "name", label: "Namn", _style: "width:20%;" },
    { key: "inStock", label: "Lagerstatus", _style: "width:20%;" },
    { key: "Status", label: "Status", _style: "width:20%" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private details = [];
  private collapseDuration = 0;
  private getBadge(status: number) {
    switch (status) {
      case 0:
        return { style: "success", label: "Aktiv" };
      case 1:
        return { style: "danger", label: "Inaktiv" };
      default:
        return { style: "warning", label: "Utgående" };
    }
  }
  private toggleDetails(item: any) {
    this.$set(this.ProductList[item.ToogleId], "_toggled", !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  @Watch("productList") private ProviderListChanged() {
    this.$nextTick(() => {
      this.ProductList = this.productList.map((item: any, ToogleId: any) => ({
        ...item,
        ToogleId,
        Status:
          item.status === 0
            ? "Aktiv"
            : item.status === 1
            ? "Inaktiv"
            : "Utgående",
      }));
    });
  }
  private async created() {
    this.ProductList = this.productList.map((item: any, ToogleId: any) => ({
      ...item,
      ToogleId,
      Status:
        item.status === 0
          ? "Aktiv"
          : item.status === 1
          ? "Inaktiv"
          : "Utgående",
    }));
  }
  private editProduct(selected: ProductEntity) {
    window.open(`/product/edit/${selected.id}`, "_blank");
    // this.$router.push(`/product/edit/${selected.id}`);
  }
  private historyProduct(selected: ProductEntity) {
    window.open(`/product/history/${selected.id}`, "_blank");
  }
  private async removeProduct(selected: ProductEntity) {
    const productName = selected.name;
    this.$beplugins.DeleteDialog(productName!).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.product_Delete(selected.id!).then((response) => {
          if (response.isSuccess && response.messages != null) {
            this.$beplugins.ApiSuccess(response);
            this.$emit("reload");
          } else {
            this.$beplugins.ApiError(response);
          }
        });
      }
    });
  }
}
</script>
