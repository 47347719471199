<template>
  <div v-if="!isLoading" class="card print" style="border:none !important;">
    <img class="watermark-logo" src="@/assets/images/watermark.png" />
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-12">
          <h2>
            {{printProps.title}}
          </h2>
        </div>
        <div class="col-sm-4">
          <h6 class="mb-3">Kund:</h6>
          <div>
            <strong>{{ vModel.customer.customerMode === 0 ? `${vModel.customer.companyName}` : `${vModel.customer.firstName} ${vModel.customer.lastName}` }}</strong>
          </div>
          <div>{{ `${ GetDeliveryAddress.address }, ${GetDeliveryAddress.postalCode}, ${GetDeliveryAddress.city}, ${GetDeliveryAddress.country}` }}</div>
          <div>E-post: {{ vModel.customer.email }}</div>
          <div>Telefon: {{ vModel.customer.phone }}</div>
        </div>

        <div class="col-sm-4"></div>

        <div class="col-sm-4">
          <h6 class="mb-3">Leverans & Status:</h6>
          <div>
            Status:
            <strong>{{ $t( `enums.${$beplugins.GetEnumsByValue('OrderStatusEnums', vModel.orderStatus)}`) }}</strong>
          </div>
          <div>
            Leveranssätt
            <strong>{{ $t( `enums.${$beplugins.GetEnumsByValue('DeliveryModeEnums', vModel.deliveryMode)}`) }}</strong>
          </div>
          <div>
            Leveransdatum
            <strong>{{ vModel.deliveryDate }}</strong>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="printProps.isCommentVisible" class="col-sm-6" style="font-weight: bold;">
          Kommentar: <br />
          {{ vModel.notes }}
        </div>
        <div v-if="printProps.isTotalWeightVisible" class="col-sm-6" style="font-weight: bold;">
          <span class="float-right mr-3">Totala vikt: {{ Math.round(vModel.totalWeight * 100) / 100 }} kg</span>
        </div>
      </div>
      <div class="table-responsive-sm">
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Artikelnr</th>
              <th>Produkt</th>
              <th v-if="printProps.isStoragePlaceVisible" class="text-center">Lagerplats</th>
              <th>Enhet</th>
              <th class="text-center">Antal</th>
              <th v-if="printProps.isPriceVisible" class="text-right">À-pris</th>
              <th v-if="printProps.isPriceVisible" class="text-right">Belopp</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in vModel.products.filter((x) => x.productNumber)"
              :key="`${row.productNumber}-${row.name}-${rowIndex}`"
              :index="rowIndex"
            >
              <td>{{ ++rowIndex }}</td>
              <td>{{ row.productNumber }}</td>
              <td>{{ row.name }}</td>
              <td v-if="printProps.isStoragePlaceVisible" class="text-center">{{ row.storageLocation }}</td>
              <td>{{ $t( `enums.${$beplugins.GetEnumsByValue('UnitEnums', row.unit)}`) }}</td>
              <td class="text-center">{{ row.quantity }}</td>
              <td v-if="printProps.isPriceVisible" class="text-right">
                {{ row.price | money }} {{ vModel.currency }}
              </td>
              <td v-if="printProps.isPriceVisible" class="text-right">
                {{ (row.price * row.quantity) | money }} {{ vModel.currency }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="printProps.isPriceVisible">
            <tr>
              <td colspan="6">
                Antal rader:
                {{
                  vModel.products.filter((x) => x.productNumber).length
                }}
              </td>
              <td>
                <strong>Summa: </strong>
                <span class="float-right"
                  >{{ GetTotalAmount | money }} {{ vModel.currency }}</span
                >
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div v-if="Number(this.$route.params.printMode) === 2" class="table-responsive-sm mt-5">
      <div class="row">
        <div class="col-12 mx-2" style="font-weight: bold;">
          Kommentar: <br />
          {{ vModel.notes }}
        </div>
      </div>
      <div class="row" style="border-top:1px solid rgba(0,0,21,.2);">
        <div class="col-12">
          <h6 class="my-2 text-center">{{settings.invoiceMessage}}</h6>
        </div>
      </div>
      <table class="table-blue-fotter table table-borderless" style="border-top:1px solid rgba(0,0,21,.2);">
        <tr>
          <td>
            <b>Företag</b><br />
            {{settings.companyName}} <br />
            <b>Organisationsnr</b><br />
            {{settings.companyNr}}
          </td>
          <td>
            <b>Adress</b><br />
            {{settings.addressLine1}}<br />
            {{ `${settings.postalCode} ${settings.city},  ${settings.country}` }}<br />
            <b>Telefon</b><br />
            {{settings.phone}}
          </td>
          <td>
            <b>Hemsida</b><br />
            {{settings.website}}<br />
            <b>E-post</b><br />
            {{settings.email1}}<br />
            {{settings.email2}}
          </td>
          <td>
            <div v-if="settings.bankGiro !== ''">
              <b>Bankgiro</b><br />
              {{settings.bankGiro}}<br />
            </div>
            <div v-if="settings.plusGiro !== ''">
              <b>Plusgiro</b><br />
              {{settings.plusGiro}}<br />
            </div>
            <div v-if="settings.iban !== ''">
              <b>IBAN</b><br />
              {{settings.iban}}<br />
            </div>
          </td>
          <td>
            <b>Momsreg.nr</b><br />
            {{settings.vatNr}}<br />
            <b>Innehar F-skattebevis</b>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CustomerEntity, DeliveryAddressEntity, ProductEntity, OrderEntity, OrderProductEntity, OrderStatusEnums, CompanyDetails } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
import Loading from 'vue-loading-overlay';
@Component({
  components: {
    Loading,
  },
})
export default class PrintOrder extends Vue {
  private isLoading: boolean = true;
  private settings: CompanyDetails = new CompanyDetails();
  private printProps = {
    title: '',
    isPriceVisible: false,
    isCommentVisible: false,
    isStoragePlaceVisible: false,
    isTotalWeightVisible: false,
  };
  private totalWeight: number = 0;
  private vModel: OrderEntity = new OrderEntity();
  private products: ProductEntity[] = [];
  private async LoadOrder() {
    await apiGeneral.order_GetOrderById(this.$route.params.orderId).then((response) => {
      this.vModel = response;
    });
  }
  private async LoadSettings() {
    await apiGeneral.companyDetail_Get().then((response) => {
      this.settings = response;
    });
  }
  get GetDeliveryAddress() {
    const itemIndex = this.vModel.customer!.addresses!.findIndex((x) => x.id === this.vModel.deliveryAddressFK);
    if (itemIndex !== -1) {
      return this.vModel.customer!.addresses![itemIndex];
    } else {
      return new DeliveryAddressEntity();
    }
  }
  private async created() {
    if (Number(this.$route.params.printMode) === 1) {
      this.printProps = {
        title: 'Plocklista',
        isPriceVisible: false,
        isCommentVisible: true,
        isStoragePlaceVisible: true,
        isTotalWeightVisible: true,
      };
    } else if (Number(this.$route.params.printMode) === 2) {
      this.printProps = {
        title: 'Följesdel',
        isPriceVisible: false,
        isCommentVisible: false,
        isStoragePlaceVisible: false,
        isTotalWeightVisible: false,
      };
    } else {
      this.printProps = {
        title: 'Order',
        isPriceVisible: true,
        isCommentVisible: true,
        isStoragePlaceVisible: true,
        isTotalWeightVisible: true,
      };
    }
    await this.LoadOrder().then(async () => await this.LoadSettings().then(() => {
      if ((Number(this.$route.params.printMode) === 2)) {
        this.printProps.isPriceVisible = this.vModel.customer!.showPriceInShippingNote;
      }
      this.isLoading = false;
      if (!this.vModel.isLocked) {
        // window.setTimeout(() => {
        //   window.print();
        //   window.close();
        // }, 1000);
      }
    }));
  }
  // används
  get GetTotalAmount() {
    let tempTotal = 0;
    this.vModel.products!.forEach((item) => {
      tempTotal += item.quantity * item.price;
    });
    return tempTotal;
  }
}
</script>
<style lang="scss">
</style>
