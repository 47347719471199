<template>
  <div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="InvoiceList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
        <template #total="{ item }">
          <td class="py-2 text-center">
              {{item.total.toFixed(2)}} {{item.currency}}
          </td>
        </template>
        <template #invoiceDate="{ item }">
          <td class="py-2 text-center">
              {{Moment(item.invoiceDate).format('YYYY-MM-DD HH:mm')}}
          </td>
        </template>
        <template #dueDate="{ item }">
          <td class="py-2 text-center">
              {{Moment(item.dueDate).format('YYYY-MM-DD HH:mm')}}
          </td>
        </template>
        <template #sent="{ item }">
          <td class="py-2 text-center">
              <CIcon :name="item.sent ? 'cil-check' : 'cil-minus'" />
          </td>
        </template>
        <template #show_details="{ item, index }">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="toggleDetails(item, index)"
            >
              {{ Boolean(item._toggled) ? "Dölj" : "Visa" }}
            </CButton>
          </td>
        </template>
        <template #details="{ item }">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
            style="border-top: 1px solid #d8dbe0"
          >
            <div class="col-12 py-2">
              <h4>{{ item.customerName }} - {{ item.documentNumber }}</h4>
              <CButton
                @click.stop.prevent="downloadInvoice(item)"
                size="sm"
                color="success"
                class=""
              >
                Ladda ner
              </CButton>
              <CButton
                v-if="!item.booked && !item.cancelled && item.balance >= 0"
                @click="cancelInvoice(item)"
                size="sm"
                color="info"
                class="ml-1"
              >
                Makulera
              </CButton>
              <CButton
                v-if="item.booked && !item.cancelled && item.balance >= 0"
                @click="creditInvoice(item)"
                size="sm"
                color="info"
                class="ml-1"
              >
                Skapa kreditfaktura
              </CButton>
              <CButton
                v-if="!item.booked && !item.cancelled"
                @click="bookKeepInvoice(item)"
                size="sm"
                color="danger"
                class="ml-1"
              >
                Bokför
              </CButton>
              <CButton
                @click="sendInvoice(item)"
                size="sm"
                color="danger"
                class="ml-1"
              >
                Skicka faktura
              </CButton>
            </div>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { InvoiceSubset } from "@/services/api/ApiGeneral";
import moment from "moment";
import { apiGeneral } from "@/services/api/ApiServices";
@Component({})
export default class FortnoxInvoiceList extends Vue {
  @Prop() private invoiceList!: InvoiceSubset[];
  private Moment = moment;
  private InvoiceList: InvoiceSubset[] = [];
  private headers = [
    { key: "documentNumber", label: "FakturaNr", _style: "width:20%" },
    { key: "customerNumber", label: "KundNr", _style: "width:20%" },
    { key: "customerName", label: "Företag", _style: "width:20%" },
    { key: "total", label: "Summa", _style: "width:10%;" },
    { key: "invoiceDate", label: "Skapad", _style: "width:10%;" },
    { key: "dueDate", label: "Förfallodatum", _style: "width:10%;" },
    { key: "sent", label: "Skickad", _style: "width:20%;" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private collapseDuration = 0;
  @Watch("invoiceList") private InvoiceListChanged() {
    this.$nextTick(() => {
      this.InvoiceList = this.invoiceList.map((item: any, ToogleId: any) => ({
        ...item,
        ToogleId,
      }));
    });
  }
  private created() {
    this.InvoiceList = this.invoiceList.map((item: any, ToogleId: any) => ({
      ...item,
      ToogleId,
    }));
  }
  private toggleDetails(item: any) {
    this.$set(this.InvoiceList[item.ToogleId], "_toggled", !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  private async downloadInvoice(payload: InvoiceSubset) {
      await apiGeneral.fortnox_DownloadInvoiceById(payload.documentNumber!).then((response) => {
            const linkSource = `data:application/pdf;base64,${response}`;
            const downloadLink = document.createElement("a");
            const fileName = "abc.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click()
      });
  }
  private async cancelInvoice(payload: InvoiceSubset) {
    this.$beplugins.CustomDialog(`Vill du makulera FakturaNr: ${payload.documentNumber!.toString()}`).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.fortnox_CancelInvoiceList([payload.documentNumber!]).then((response) => {
          this.$emit('reload');
          alert('Fakturan har blivit makulerat!')
        }).catch((error) => {
          alert('Vänligen gör ett nytt försök!')
        });
      }
    });
  }
  private async creditInvoice(payload: InvoiceSubset) {
    this.$beplugins.CustomDialog(`Vill du kreditera FakturaNr: ${payload.documentNumber!.toString()}`).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.fortnox_CreditInvoiceList([payload.documentNumber!]).then((response) => {
          this.$emit('reload');
          alert('Fakturan har blivit krediterad!')
        }).catch((error) => {
          alert('Vänligen gör ett nytt försök!')
        });
      }
    });
  }
  private async bookKeepInvoice(payload: InvoiceSubset) {
    this.$beplugins.CustomDialog(`Vill du bokföra FakturaNr: ${payload.documentNumber!.toString()}`).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.fortnox_BookKeepInvoiceList([payload.documentNumber!]).then((response) => {
          this.$emit('reload');
          alert('Fakturan har blivit bokfört!')
        }).catch((error) => {
          alert('Vänligen gör ett nytt försök!')
        });
      }
    });
  }
  private async sendInvoice(payload: InvoiceSubset) {
    this.$beplugins.CustomDialog(`Vill du skicka FakturaNr: ${payload.documentNumber!.toString()}`).then(async (isAccept) => {
      if (isAccept) {
        await apiGeneral.fortnox_SendInvoiceList([payload.documentNumber!]).then((response) => {
          this.$emit('reload');
          alert('Fakturan har blivit skickat!')
        }).catch((error) => {
          alert('Vänligen gör ett nytt försök!')
        });
      }
    });
  }
}
</script>
