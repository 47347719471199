<template>
  <div class="animated fadeIn">
    <CustomerNew />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CustomerNew from '@/components/customer/CustomerNew.vue';
@Component({
  components: {
      CustomerNew,
  },
})
export default class CustomerCreate extends Vue {}
</script>
