import Swal from "sweetalert2";
import { OidcUserViewModel } from "@/types/oidc/OidcUserViewModel";
import store from "@/store/index";
import i18n from "@/i18n";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { apiGeneral } from "@/services/api/ApiServices";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import {
  CustomerModeEnums,
  CompanyEnums,
  DeliveryModeEnums,
  UnitEnums,
  OrderStatusEnums,
  PaymentStatusEnums,
  PurchaseUnitEnums,
  InvoiceEnums,
  InvoiceStatusEnums,
  GenderEnums,
  OrderEntity,
  OrderProductEntity,
  ProductEntity,
  ProductUnitEnums,
  ProductTypeEnums,
  PurchaseStatusEnums,
  CarTableListViewModel,
  InvoiceEntity,
  CompanyDetails,
  Invoice3,
} from "@/services/api/ApiGeneral";
declare module "vue/types/vue" {
  interface Vue {
    $beplugins: {
      CalculateTB(sellPrice: number, cost: number): number;
      CalculateTG(sellPrice: number, cost: number): number;
      InputMoney(payload: number): void;
      InputValidatorMoney(payload: any, propName: string): void;
      GetOidcUser(): OidcUserViewModel;
      OrderInsertProduct(
        orderModel: OrderEntity,
        nextTick: any,
        isEdit: boolean,
        products: ProductEntity[],
        refs: any
      ): void;
      OrderProductSelected(
        orderModel: OrderEntity,
        selectedItem: ProductEntity,
        itemIndex: number,
        refs: any
      ): any;
      OrderUnitSelected(
        products: ProductEntity[],
        orderModel: OrderProductEntity,
        itemIndex: number,
        refs: any
      ): void;
      OrderQuantityChanged(
        products: ProductEntity[],
        orderModel: OrderProductEntity
      ): void;
      OrderProductFilter(option: any, label: string, search: string): boolean;
      ProviderFilter(option: any, label: string, search: string): boolean;
      CloseWindowConfirmation(message: string): void;
      TotalIncomingPriceOrderViewModelItem(
        item: OrderEntity,
        incVat: boolean
      ): string;
      TotalPriceOrderViewModelItem(item: OrderEntity, incVat: boolean): string;
      GetEnumsByType(enumType: string): [];
      GetEnumsByValue(enumType: string, enumValue: number): string;
      TotalWeightOrderModel(payload: OrderEntity): number;
      TotalWeightCarModel(payload: CarTableListViewModel): number;
      GeneratePassword(
        passwordLength: number,
        useUpperCase: boolean,
        useNumbers: boolean,
        useSpecialChars: boolean
      ): string;
      EditOrder(orderId: string): Promise<boolean>;
      EditOffer(orderId: string): Promise<boolean>;
      PrintOrder(orderId: string, printMode: number): Promise<boolean>;
      PrintOrderList(
        isCar: boolean,
        ids: string[],
        showFrozenItems: boolean,
        showFridgeItems: boolean,
        showDryItems: boolean
      ): Promise<boolean>;
      PrintInvoice(orderId: number): Promise<boolean>;
      PrintingInvoice(
        invoice: InvoiceEntity[],
        companyDetails: CompanyDetails
      ): Promise<void>;
      PrintingOffer(
        orders: OrderEntity[],
      ): Promise<void>;
      PrintingDeliveryNote(
        orders: OrderEntity[],
      ): Promise<void>;
      PrintingPickList(
        orders: OrderEntity[],
      ): Promise<void>;
      PrintingFreezeList(
        orders: OrderEntity[],
      ): Promise<void>;
      PrintingFreezeSummaryList(
        payload: OrderEntity[],
      ): Promise<void>;
      GetBase64ImageFromURL(url: string): Promise<string>;
      CustomDialog(message: string): Promise<boolean>;
      DeleteDialog(name: string): Promise<boolean>;
      ApiSuccess(result: any): Promise<boolean>;
      ApiError(result: any): Promise<boolean>;
      Signout(): void;
      GetIfPowerUser(): boolean;
      GetIfSellerUser(): boolean;
      HasRoles(hasRoles: string[]): boolean
    };
  }
}
export default {
  install(Vue: any, options: any) {
    Vue.prototype.$beplugins = {
      CalculateTB: (sellPrice: number, cost: number): number => {
        const result = sellPrice - cost;
        return isFinite(result) ? result : 0;
      },
      CalculateTG: (sellPrice: number, cost: number): number => {
        const tb = Vue.prototype.$beplugins.CalculateTB(sellPrice, cost);
        const result =
          Math.round(((tb / sellPrice) * 100 + Number.EPSILON) * 100) / 100;
        return isFinite(result) ? result : 0;
      },
      InputValidatorMoney: (payload: any, propName: string) => {
        payload[propName] = payload[propName].replace(",", ".");
      },
      InputMoney: (payload: string) => {
        payload = payload.replace(",", ".");
      },
      GetOidcUser: () => {
        const user: OidcUserViewModel = store.getters["oidcUser"];
        if (user) {
          return user;
        }
        return undefined;
      },
      OrderProductSelected: (
        orderModel: OrderEntity,
        selectedItem: ProductEntity,
        itemIndex: number,
        refs: any
      ) => {
        if (selectedItem !== undefined || selectedItem !== null) {
          const isInArray: boolean =
            orderModel.products!.find(
              (item) => item.productFK === selectedItem.id
            ) !== undefined;
          if (
            isInArray &&
            !confirm(`${selectedItem.name} finns redan, lägg till?`)
          ) {
            return;
          } else {
            orderModel.products![itemIndex].id =
              "00000000-0000-0000-0000-000000000000";
            orderModel.products![itemIndex].productFK = selectedItem.id;
            orderModel.products![itemIndex].productNumber =
              selectedItem.productNumber;
            orderModel.products![itemIndex].packingSorting =
              selectedItem.packingSorting;
            orderModel.products![itemIndex].name = selectedItem.name;
            orderModel.products![itemIndex].productType =
              selectedItem.productType;
            orderModel.products![itemIndex].vat = selectedItem.vat;
            orderModel.products![itemIndex].unit =
              selectedItem.cartonPieces > 0 ? 2 : (selectedItem.unit as number);
            orderModel.products![itemIndex].pieces =
              orderModel.products![itemIndex].unit === UnitEnums.Carton
                ? selectedItem.cartonPieces
                : 1;
            orderModel.products![itemIndex].price = selectedItem.price;
            orderModel.products![itemIndex].inStock = parseFloat(
              selectedItem.inStock.toFixed(2)
            );
            orderModel.products![itemIndex].storageLocation =
              selectedItem.storageLocation;
            orderModel.products![itemIndex].quantity = 1;
            orderModel.products![itemIndex].weight = selectedItem.weight;
            orderModel.products![itemIndex].cartonWeight =
              selectedItem.cartonWeight;
            orderModel.products![itemIndex].orderFK =
              "00000000-0000-0000-0000-000000000000";
            orderModel.products![itemIndex].storageLocation =
              selectedItem.storageLocation;
            orderModel.products![itemIndex].lastPrice =
              selectedItem.orderList && selectedItem.orderList.length > 0
                ? selectedItem.orderList[0].price
                : 0;
            orderModel.products![itemIndex].incomingPrice =
              selectedItem.priceIncoming;
          }
          const unitSelectEl = refs[
            "unit-select-" + itemIndex
          ][0] as HTMLSelectElement;
          unitSelectEl.options[0].hidden =
            selectedItem.unit !== ProductUnitEnums.Piece;
          unitSelectEl.options[1].hidden =
            selectedItem.unit !== ProductUnitEnums.Kilo;
          unitSelectEl.options[2].hidden =
            selectedItem.cartonPieces < 1 &&
            selectedItem.unit !== ProductUnitEnums.Carton;
          const el = refs["quntity-amount-" + itemIndex] as any;
          setTimeout(() => {
            el[0].focus();
            el[0].setSelectionRange(0, el[0].value.length);
          }, 50);
        }
      },
      OrderInsertProduct: (
        orderModel: OrderEntity,
        nextTick: any,
        isEdit: boolean,
        products: ProductEntity[],
        refs: any
      ) => {
        if (isEdit) {
          orderModel.products?.forEach((item, itemIndex) => {
            const tempProduct = products.find((x) => x.id === item.productFK);
            if (tempProduct !== undefined) {
              item.weight = tempProduct.weight;
              item.cartonWeight = tempProduct.cartonWeight;
              item.piecesPrevious = item.pieces;
              item.packingSorting = item.packingSorting;
              item.productType = item.productType;
              item.inStock = parseFloat(tempProduct.inStock.toFixed(2));
              item.pieces =
                item.unit === UnitEnums.Carton
                  ? item!.quantity * tempProduct!.cartonPieces
                  : item!.quantity;
              item.price =
                item.price !== 0
                  ? item.price
                  : item.unit === UnitEnums.Carton
                    ? tempProduct!.price * tempProduct!.cartonPieces
                    : tempProduct!.price;
              item.lastPrice =
                tempProduct.orderList && tempProduct.orderList.length > 0
                  ? tempProduct.orderList[0].price
                  : 0;
              item.incomingPrice = tempProduct!.priceIncoming;
              const unitSelectEl = refs[
                "unit-select-" + itemIndex
              ][0] as HTMLSelectElement;
              unitSelectEl.options[0].hidden =
                tempProduct.unit !== ProductUnitEnums.Piece;
              unitSelectEl.options[1].hidden =
                tempProduct.unit !== ProductUnitEnums.Kilo;
              unitSelectEl.options[2].hidden =
                tempProduct.cartonPieces < 1 &&
                tempProduct.unit !== ProductUnitEnums.Carton;
            }
          });
        }
        Vue.set(
          orderModel.products!,
          orderModel.products!.length,
          new OrderProductEntity({
            id: "00000000-0000-0000-0000-000000000000",
            productType: 0,
            price: 0,
            packingSorting: 0,
            pieces: 0,
            piecesPrevious: 0,
            name: "",
            inStock: 0,
            unit: 0,
            vat: 0,
            incomingPrice: 0,
            productNumber: "",
            productFK: "00000000-0000-0000-0000-000000000000",
            quantity: 1,
            weight: 0,
            cartonWeight: 0,
            orderFK: "00000000-0000-0000-0000-000000000000",
            notes: "",
            storageLocation: "",
            lastPrice: 0,
          })
        );
        nextTick(() => {
          const el = document.getElementsByClassName("vs__search");
          (el[el.length - 1] as any).focus();
        });
      },
      OrderUnitSelected: (
        products: ProductEntity[],
        orderModel: OrderProductEntity,
        itemIndex: number,
        refs: any
      ) => {
        const tempProduct = products.find((x) => x.id === orderModel.productFK);
        if (tempProduct !== undefined) {
          orderModel.price = tempProduct!.price;
          orderModel.pieces =
            orderModel.unit === UnitEnums.Carton
              ? orderModel!.quantity * tempProduct!.cartonPieces
              : orderModel!.quantity;
          orderModel.incomingPrice = tempProduct!.priceIncoming;
          const el = refs["quntity-amount-" + itemIndex] as any;
          el[0].focus();
        }
      },
      OrderQuantityChanged: (
        products: ProductEntity[],
        orderModel: OrderProductEntity
      ) => {
        const tempProduct = products.find((x) => x.id === orderModel.productFK);
        if (tempProduct !== undefined) {
          orderModel.pieces =
            orderModel.unit === UnitEnums.Carton
              ? orderModel!.quantity * tempProduct!.cartonPieces
              : orderModel!.quantity;
        }
      },
      OrderProductFilter: (option: any, label: string, search: string) => {
        const temp = search.toLowerCase();
        return (
          option.name.toLowerCase().indexOf(temp) > -1 ||
          option.productNumber.toLowerCase().indexOf(temp) > -1
        );
      },
      ProviderFilter: (option: any, label: string, search: string) => {
        const temp = search.toLowerCase();
        return (
          option?.companyName?.toLowerCase().indexOf(temp) > -1 ||
          option?.companyNo?.toLowerCase().indexOf(temp) > -1
        );
      },
      CloseWindowConfirmation: (message: string) => {
        if (confirm(message)) {
          close();
        }
      },
      TotalIncomingPriceOrderViewModelItem: (
        item: OrderEntity,
        incVat: boolean
      ) => {
        let result = 0;
        item.products!.forEach((p) => {
          if (incVat) {
            result +=
              p.incomingPrice * p.pieces +
              (p.vat / 100) * (p.incomingPrice * p.pieces);
          } else {
            result += p.incomingPrice * p.pieces;
          }
        });
        return (Math.round(result * 100 + Number.EPSILON) / 100).toFixed(2);
      },
      TotalPriceOrderViewModelItem: (item: OrderEntity, incVat: boolean) => {
        let result = 0;
        item.products!.forEach((p) => {
          if (incVat) {
            result += p.price * p.pieces + (p.vat / 100) * (p.price * p.pieces);
          } else {
            result += p.price * p.pieces;
          }
        });
        return (Math.round(result * 100 + Number.EPSILON) / 100).toFixed(2);
      },
      GetEnumsByType: (enumType: string) => {
        const result = [];
        let enums: any;
        switch (enumType) {
          case "CustomerModeEnums":
            enums = CustomerModeEnums;
            break;
          case "PurchaseStatusEnums":
            enums = PurchaseStatusEnums;
            break;
          case "CompanyEnums":
            enums = CompanyEnums;
            break;
          case "DeliveryModeEnums":
            enums = DeliveryModeEnums;
            break;
          case "InvoiceEnums":
            enums = InvoiceEnums;
            break;
          case "GenderEnums":
            enums = GenderEnums;
            break;
          case "UnitEnums":
            enums = UnitEnums;
            break;
          case "OrderStatusEnums":
            enums = OrderStatusEnums;
            break;
          case "PaymentStatusEnums":
            enums = PaymentStatusEnums;
            break;
          case "InvoiceStatusEnums":
            enums = InvoiceStatusEnums;
            break;
          case "ProductTypeEnums":
            enums = ProductTypeEnums;
            break;
          case "ProductUnitEnums":
            enums = ProductUnitEnums;
            break;
          case "PurchaseUnitEnums":
            enums = PurchaseUnitEnums;
            break;
          case "Invoice3":
            enums = Invoice3;
            break;
        }
        for (const item in enums) {
          if (isNaN(Number(item))) {
            result.push({ label: item, value: enums[item] });
          }
        }
        return result;
      },
      GetEnumsByValue: (enumType: string, enumValue: number) => {
        let enums: any;
        switch (enumType) {
          case "CustomerModeEnums":
            enums = CustomerModeEnums;
            break;
          case "PurchaseStatusEnums":
            enums = PurchaseStatusEnums;
            break;
          case "CompanyEnums":
            enums = CompanyEnums;
            break;
          case "DeliveryModeEnums":
            enums = DeliveryModeEnums;
            break;
          case "InvoiceEnums":
            enums = InvoiceEnums;
            break;
          case "GenderEnums":
            enums = GenderEnums;
            break;
          case "UnitEnums":
            enums = UnitEnums;
            break;
          case "OrderStatusEnums":
            enums = OrderStatusEnums;
            break;
          case "PaymentStatusEnums":
            enums = PaymentStatusEnums;
            break;
          case "InvoiceStatusEnums":
            enums = InvoiceStatusEnums;
            break;
          case "ProductTypeEnums":
            enums = ProductTypeEnums;
            break;
          case "ProductUnitEnums":
            enums = ProductUnitEnums;
            break;
          case "PurchaseUnitEnums":
            enums = PurchaseUnitEnums;
            break;
          case "Invoice3":
            enums = Invoice3;
            break;
        }
        return enums[enumValue];
      },
      TotalWeightOrderModel: (payload: OrderEntity) => {
        let result = 0;
        payload.products?.forEach((item) => {
          if (item.unit === 0) {
            result += item.weight * item.quantity;
          } else {
            result += item.cartonWeight * item.quantity;
          }
        });
        return Math.round(result * 100) / 100;
      },
      TotalWeightCarModel: (payload: CarTableListViewModel) => {
        let result = 0;
        payload.orders!.forEach((order) => {
          order.products?.forEach((item) => {
            if (item.unit === 0) {
              result += item.weight * item.quantity;
            } else {
              result += item.cartonWeight * item.quantity;
            }
          });
        });
        return Math.round(result * 100) / 100;
      },
      GeneratePassword: (
        passwordLength: number,
        useUpperCase: boolean,
        useNumbers: boolean,
        useSpecialChars: boolean
      ) => {
        const chars = "abcdefghijklmnopqrstuvwxyz";
        const numberChars = "0123456789";
        const specialChars = '!"£$%^&*()';

        const usableChars =
          chars +
          (useUpperCase ? chars.toUpperCase() : "") +
          (useNumbers ? numberChars : "") +
          (useSpecialChars ? specialChars : "");

        let generatedPassword = "";

        for (let i = 0; i <= passwordLength; i++) {
          generatedPassword +=
            usableChars[Math.floor(Math.random() * usableChars.length)];
        }
        return generatedPassword;
      },
      EditOrder: (orderId: string) => {
        return new Promise((resolve) => {
          const h = 800;
          const w = 1200;
          const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
          const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
          const new_window = window.open(
            `/OrderEdit/${orderId}`,
            ``,
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            y +
            ", left=" +
            x
          );
          new_window!.onbeforeunload = () => {
            resolve(true);
          };
        });
      },
      EditOffer: (orderId: string) => {
        return new Promise((resolve) => {
          const h = 800;
          const w = 1200;
          const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
          const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
          const new_window = window.open(
            `/OfferEdit/${orderId}`,
            ``,
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            y +
            ", left=" +
            x
          );
          new_window!.onbeforeunload = () => {
            resolve(true);
          };
        });
      },
      PrintOrder: (orderId: string, printMode: number) => {
        return new Promise((resolve) => {
          const h = 800;
          const w = 1200;
          const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
          const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
          const new_window = window.open(
            `/PrintOrder/${orderId}/${printMode}`,
            ``,
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            y +
            ", left=" +
            x
          );
          new_window!.onbeforeunload = () => {
            resolve(true);
          };
        });
      },
      PrintOrderList: (
        isCar: boolean,
        ids: string[],
        showFrozenItems: boolean,
        showFridgeItems: boolean,
        showDryItems: boolean
      ) => {
        return new Promise((resolve) => {
          const h = 800;
          const w = 1200;
          const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
          const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
          const new_window = window.open(
            `/PrintOrderList/${isCar}/${JSON.stringify(
              ids
            )}/${showFrozenItems}/${showFridgeItems}/${showDryItems}`,
            ``,
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            y +
            ", left=" +
            x
          );
          new_window!.onbeforeunload = () => {
            resolve(true);
          };
        });
      },
      PrintInvoice: (orderId: number) => {
        return new Promise((resolve) => {
          const h = 800;
          const w = 1200;
          const y = window.top!.outerHeight / 2 + window.top!.screenY - h / 2;
          const x = window.top!.outerWidth / 2 + window.top!.screenX - w / 2;
          const new_window = window.open(
            `/PrintInvoice/${orderId}`,
            ``,
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            y +
            ", left=" +
            x
          );
          new_window!.onbeforeunload = () => {
            resolve(true);
          };
        });
      },
      PrintingInvoice: async (
        invoices: InvoiceEntity[],
        companyDetails: CompanyDetails
      ): Promise<void> => {
        const docs: any[] = [];
        invoices.forEach((invoice, invoiceIndex) => {
          const orderReference: string[] = invoice.orders!.map(
            (row, rowIndex) => {
              return `${row.orderId}`;
            }
          );
          const tableProductRow: any[] = [];
          invoice.orders!.forEach((row) => {
            tableProductRow.push([
              { text: row.orderId, style: "text" },
              { text: "", style: "text" },
              { text: "", style: "text" },
              { text: "", style: "text" },
              { text: "", style: "text" },
              { text: "", style: "text" },
            ]);
            row.products!.forEach((prod) => {
              tableProductRow.push([
                { text: "", style: "text" },
                { text: prod.productNumber, style: "text" },
                { text: prod.name, style: "text" },
                {
                  text: `${prod.quantity} ${i18n
                    .t(
                      `enums.${Vue.prototype.$beplugins.GetEnumsByValue(
                        "UnitEnums",
                        prod.unit
                      )}`
                    )
                    .toString()
                    .toLowerCase()}`,
                  style: "text",
                },
                {
                  text: `${prod.price.toFixed(2)} ${invoice.currency}`,
                  style: "text",
                  alignment: "right",
                },
                {
                  text: `${(prod.price * prod.quantity).toFixed(2)} ${invoice.currency
                    }`,
                  style: "text",
                  alignment: "right",
                },
              ]);
            });
          });
          const vatPercent: string[] = invoice.vats!.map((row, rowIndex) => {
            return row.vat.toFixed(2);
          });
          const vatPrice: string[] = invoice.vats!.map((row, rowIndex) => {
            return row.total.toFixed(2);
          });
          tableProductRow.splice(0, 0, [
            { text: "Ordernr", style: "subtitle" },
            { text: "Artikelnr", style: "subtitle" },
            { text: "Produkt", style: "subtitle" },
            { text: "Antal", style: "subtitle" },
            { text: "À-pris", style: "subtitle" },
            { text: "Belopp", style: "subtitle" },
          ]);
          docs.push({
            layout: "noBorders",
            table: {
              headerRows: 0,
              widths: ["*", 20, "*"],
              body: [
                [
                  { image: "logo", width: 64, height: 49, alignment: "center" },
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 60,
                        lineWidth: 1,
                        lineColor: "lightgray",
                      },
                    ],
                    alignment: "center",
                  },
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 2,
                      widths: ["*", "*", "*"],
                      body: [
                        [{ text: "Faktura", style: "header" }, "", ""],
                        [
                          { text: "Faktnr", style: "subtitle" },
                          { text: "Kundnr", style: "subtitle" },
                          { text: "Fakturadatum", style: "subtitle" },
                        ],
                        [
                          { text: invoice.invoiceNumber, style: "text" },
                          { text: invoice.customerNo, style: "text" },
                          {
                            text: Vue.prototype
                              .moment(invoice.created)
                              .format("YYYY/MM/DD"),
                            style: "text",
                          },
                        ],
                      ],
                    },
                  },
                ],
              ],
            },
          });
          docs.push({
            margin: [40, 5],
            columns: [
              {
                width: "100%",
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 0,
                    x2: 520,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: "lightgray",
                  },
                ],
                alignment: "center",
              },
            ],
          });
          docs.push({
            layout: "noBorders",
            table: {
              headerRows: 0,
              widths: ["*", 20, "*"],
              body: [
                [
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 1,
                      widths: ["*"],
                      body: [
                        [{ text: "Leveransadress", style: "subtitle" }],
                        [{ text: invoice.customerName, style: "text" }],
                        [
                          {
                            text: `${invoice.deliveryAddress}, ${invoice.deliveryZipCode} ${invoice.deliveryCity}, ${invoice.deliveryCountry}`,
                            style: "text",
                          },
                        ],
                      ],
                    },
                  },
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 50,
                        lineWidth: 1,
                        lineColor: "lightgray",
                      },
                    ],
                    alignment: "center",
                  },
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 1,
                      widths: ["*"],
                      body: [
                        [{ text: "Fakturaadress", style: "subtitle" }],
                        [{ text: invoice.customerName, style: "text" }],
                        [
                          {
                            text: `${invoice.invoiceAddress}, ${invoice.invoiceZipCode} ${invoice.invoiceCity}, ${invoice.invoiceCountry}`,
                            style: "text",
                          },
                        ],
                      ],
                    },
                  },
                ],
              ],
            },
          });
          docs.push({
            margin: [40, 5],
            columns: [
              {
                width: "100%",
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 0,
                    x2: 520,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: "lightgray",
                  },
                ],
                alignment: "center",
              },
            ],
          });
          docs.push({
            layout: "noBorders",
            table: {
              headerRows: 0,
              widths: ["*", 20, "*"],
              body: [
                [
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 1,
                      widths: ["auto", "*"],
                      body: [
                        [
                          { text: "Er referens", style: "subtitle" },
                          { text: invoice.sellerName, style: "text" },
                        ],
                        [
                          { text: "Er ordernr", style: "subtitle" },
                          { text: orderReference.join(", "), style: "text" },
                        ],
                      ],
                    },
                  },
                  {
                    canvas: [
                      {
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 65,
                        lineWidth: 1,
                        lineColor: "lightgray",
                      },
                    ],
                    alignment: "center",
                  },
                  {
                    layout: "noBorders",
                    table: {
                      headerRows: 1,
                      widths: ["*", "*"],
                      body: [
                        [
                          { text: "Vår referens", style: "subtitle" },
                          { text: invoice.contactPerson, style: "text" },
                        ],
                        [
                          { text: "Betalningsvillkor", style: "subtitle" },
                          {
                            text: `${invoice.paymentDueDays} dagar netto`,
                            style: "text",
                          },
                        ],
                        [
                          { text: "Förfallodatum", style: "subtitle" },
                          {
                            text: Vue.prototype
                              .moment(invoice.paymentDue)
                              .format("YYYY/MM/DD"),
                            style: "text",
                          },
                        ],
                        [
                          { text: "Dröjsmålränta", style: "subtitle" },
                          {
                            text: `${invoice.interestRateDelay.toFixed(2)} %`,
                            style: "text",
                          },
                        ],
                      ],
                    },
                  },
                ],
              ],
            },
          });
          docs.push({
            margin: [0, 5],
            table: {
              color: "lightgray",
              headerRows: 1,
              widths: ["auto", "auto", "*", "auto", "auto", "auto"],
              body: tableProductRow,
            },
            layout: {
              hLineWidth: function(i: any, node: any) {
                return 1;
              },
              vLineWidth: function(i: any, node: any) {
                return 1;
              },
              hLineColor: function(i: any, node: any) {
                return "lightgray";
              },
              vLineColor: function(i: any, node: any) {
                return "lightgray";
              },
            },
          });
          docs.push({
            table: {
              color: "lightgray",
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*", "*"],
              body: [
                [
                  { text: "Netto", style: "subtitle" },
                  { text: "Exkl moms", style: "subtitle" },
                  { text: "Moms (%)", style: "subtitle" },
                  { text: "Moms (SEK)", style: "subtitle" },
                  { text: "Öresavr", style: "subtitle" },
                  { text: "ATT BETALA", style: "subtitle" },
                ],
                [
                  {
                    text: `${invoice.amount.toFixed(2)} ${invoice.currency}`,
                    style: "text",
                    alignment: "right",
                  },
                  {
                    text: `${invoice.amount.toFixed(2)} ${invoice.currency}`,
                    style: "text",
                    alignment: "right",
                  },
                  {
                    text: vatPercent.join("\n"),
                    style: "text",
                    alignment: "right",
                  },
                  {
                    text: vatPrice.join("\n"),
                    style: "text",
                    alignment: "right",
                  },
                  {
                    text: `${invoice.roundingPrice.toFixed(2)} ${invoice.currency
                      }`,
                    style: "text",
                    alignment: "right",
                  },
                  {
                    text: `${(
                      invoice.roundingPrice + invoice.amountIncVAT
                    ).toFixed(2)} ${invoice.currency}`,
                    style: "text",
                    alignment: "right",
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: function(i: any, node: any) {
                return 1;
              },
              vLineWidth: function(i: any, node: any) {
                return 1;
              },
              hLineColor: function(i: any, node: any) {
                return "lightgray";
              },
              vLineColor: function(i: any, node: any) {
                return "lightgray";
              },
            },
          });
          docs.push({
            style: "footer",
            ...(invoiceIndex < invoices.length - 1
              ? { pageBreak: "after" }
              : {}),
            absolutePosition: { x: 26, y: 740 },
            columns: [
              {
                style: "footer",
                width: "100%",
                stack: [
                  {
                    style: "footer",
                    width: "100%",
                    canvas: [
                      {
                        style: "footer",
                        type: "line",
                        x1: 0,
                        y1: 0,
                        x2: 600,
                        y2: 0,
                        lineWidth: 1,
                        lineColor: "lightgray",
                      },
                    ],
                    alignment: "center",
                  },
                  {
                    style: "footer",
                    width: "100%",
                    text: companyDetails.invoiceMessage,
                    fontSize: 9,
                    alignment: "center",
                  },
                  {
                    style: "footer",
                    margin: [10, 5, 10, 0],
                    layout: "noBorders",
                    table: {
                      style: "footer",
                      headerRows: 1,
                      widths: ["*", "*", "*", "*", "*"],
                      body: [
                        [
                          {
                            style: "footer",
                            text: "Företag",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Adress",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Hemsida",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Bankgiro",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Momsreg.nr",
                            fontSize: 9,
                            bold: true,
                          },
                        ],
                        [
                          {
                            style: "footer",
                            text: companyDetails.companyName,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: `${companyDetails.addressLine1} \n ${companyDetails.postalCode} ${companyDetails.city},  ${companyDetails.country}`,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: companyDetails.website,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: companyDetails.bankGiro,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: companyDetails.vatNr,
                            fontSize: 9,
                          },
                        ],
                        [
                          {
                            style: "footer",
                            text: "Organisationsnr",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Telefon",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "E-post",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Plusgiro",
                            fontSize: 9,
                            bold: true,
                          },
                          {
                            style: "footer",
                            text: "Innehar F-skattebevis",
                            fontSize: 9,
                            bold: true,
                          },
                        ],
                        [
                          {
                            style: "footer",
                            text: companyDetails.companyNr,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: companyDetails.phone,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: `${companyDetails.email1}\n${companyDetails.email2}`,
                            fontSize: 9,
                          },
                          {
                            style: "footer",
                            text: companyDetails.plusGiro,
                            fontSize: 9,
                          },
                          { style: "footer", text: "", fontSize: 9 },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          });
        });
        const docDefinition = {
          pageSize: "A4",
          pageOrientation: "portrait",
          pageMargins: [40, 20, 40, 0],
          watermark: {
            text: "Easy Asia AB",
            color: "blue",
            opacity: 0.04,
            bold: true,
            italics: false,
          },
          content: docs,
          styles: {
            header: { fontSize: 18, bold: true },
            subtitle: { fontSize: 10, bold: true },
            text: { fontSize: 10 },
            footerHeader: { fontSize: 9, bold: true },
            footerText: { fontSize: 9 },
            footerTextCentered: { fontSize: 9, alignment: "center" },
          },
          images: {
            logo: await Vue.prototype.$beplugins.GetBase64ImageFromURL(
              "/img/logos/printheader.jpg"
            ),
          },
          pageBreakBefore: function(
            currentNode: any,
            followingNodesOnPage: any,
            nodesOnNextPage: any,
            previousNodesOnPage: any
          ) {
            return (
              currentNode.startPosition.top > 720 &&
              currentNode.style !== "footer"
            );
          },
        };
        await pdfMake.createPdf(docDefinition).print();
      },
      PrintingOffer: async (
        orders: OrderEntity[],
      ): Promise<void> => {
        await apiGeneral.pdf_PrintOfferList(orders.map((x) => x.id)).then((response) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(response.data);
          downloadLink.setAttribute('download', orders[0].orderId!);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        });
      },
      PrintingDeliveryNote: async (
        orders: OrderEntity[],
      ): Promise<void> => {
        await apiGeneral.pdf_PrintDeliveryNote(orders.map((x) => x.id)).then((response) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(response.data);
          downloadLink.setAttribute('download', orders[0].orderId!);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        });
      },
      PrintingPickList: async (
        orders: OrderEntity[],
      ): Promise<void> => {
        await apiGeneral.pdf_PrintPickList(orders.map((x) => x.id)).then((response) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(response.data);
          downloadLink.setAttribute('download', orders[0].orderId!);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        });
      },
      PrintingFreezeList: async (
        orders: OrderEntity[],
      ): Promise<void> => {
        await apiGeneral.pdf_PrintFreezeList(orders.map((x) => x.id)).then((response) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(response.data);
          downloadLink.setAttribute('download', orders[0].orderId!);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        });
      },
      PrintingFreezeSummaryList: async (
        payload: OrderEntity[],
      ): Promise<void> => {
        await apiGeneral.pdf_PrintFreezeSummaryList(payload.map((x) => x.id)).then((response) => {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(response.data);
          downloadLink.setAttribute('download', payload[0].orderId!);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        });
      },
      GetBase64ImageFromURL: async (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.setAttribute("crossOrigin", "anonymous");
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          };
          img.onerror = (error) => {
            reject(error);
          };
          img.src = url;
        });
      },
      CustomDialog: (message: string): Promise<boolean> => {
        return new Promise(async (resolve) => {
          return Swal.fire({
            icon: "warning",
            title: "Är du säker?",
            text: message,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ja",
            cancelButtonText: "Nej",
          }).then(async (result: any) => {
            if (result.value) {
              return resolve(true);
            } else {
              return resolve(false);
            }
          });
        });
      },
      DeleteDialog: (name: string) => {
        return new Promise(async (resolve) => {
          return Swal.fire({
            icon: "warning",
            title: "Är du säker?",
            text: `Vill du ta bort ${name}?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ja",
            cancelButtonText: "Nej",
          }).then(async (result: any) => {
            if (result.value) {
              return resolve(true);
            } else {
              return resolve(false);
            }
          });
        });
      },
      ApiSuccess: (result: any) => {
        return new Promise(async (resolve) => {
          if (Swal.isVisible()) {
            Swal.close();
          }
          Swal.fire({
            icon: "success",
            title: result.title!,
            text: result.messages![0],
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then(() => {
            resolve(true);
          });
        });
      },
      ApiError: (result: any) => {
        return new Promise(async (resolve) => {
          if (Swal.isVisible()) {
            Swal.close();
          }
          Swal.fire({
            icon: "error",
            title: result.title!,
            text: result.messages![0],
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then(() => {
            resolve(true);
          });
        });
      },
      Signout: () => {
        store.dispatch("signOutOidc");
      },
      GetIfPowerUser: (): boolean => {
        let result: boolean = false;
        let roles: string[] = [];
        if (
          store.getters["oidcUser"] === null ||
          store.getters["oidcUser"].role === null
        ) {
          result = false;
        } else if (typeof store.getters["oidcUser"].role === "string") {
          roles.push(store.getters["oidcUser"].role);
        } else if (typeof store.getters["oidcUser"].role === "object") {
          roles = store.getters["oidcUser"].role;
        }
        if (roles.includes("Admin") || roles.includes("Administrator")) {
          result = true;
        }
        return result;
      },
      GetIfSellerUser: (): boolean => {
        let result: boolean = false;
        let roles: string[] = [];
        if (
          store.getters["oidcUser"] === null ||
          store.getters["oidcUser"].role === null
        ) {
          result = false;
        } else if (typeof store.getters["oidcUser"].role === "string") {
          roles.push(store.getters["oidcUser"].role);
        } else if (typeof store.getters["oidcUser"].role === "object") {
          roles = store.getters["oidcUser"].role;
        }
        if (
          roles.includes("Admin") ||
          roles.includes("Administrator") ||
          roles.includes("Inköpare")
        ) {
          result = true;
        }
        return result;
      },
      HasRoles: (hasRoles: string[]): boolean => {
        let roles: string[] = [];
        if (
          store.getters["oidcUser"] === null ||
          store.getters["oidcUser"].role === null
        ) {
          return false;
        } else if (typeof store.getters["oidcUser"].role === "string") {
          roles.push(store.getters["oidcUser"].role);
        } else if (typeof store.getters["oidcUser"].role === "object") {
          roles = store.getters["oidcUser"].role;
        }
        return hasRoles.some((r) => roles.includes(r));
      }
    };
  },
};
