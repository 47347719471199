<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card" v-if="stockValueList.length > 0">
                <StockValueList :stockValueList="GetStockValueList" @reload="loadData" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { StockValueViewModel } from '@/services/api/ApiGeneral';
import StockValueList from '@/components/stock/StockValueList.vue';
@Component({
  components: {
      StockValueList
  },
})
export default class StockValue extends Vue {
    private stockValueList: StockValueViewModel[] = [];
    private async created() {
        await this.loadData();
    }
    private async loadData() {
        await apiGeneral.stockValue_GetStockValueData().then((data) => {
            this.stockValueList = data;
        }).catch((err) => {
            console.log(err);
        });
    }
    get GetStockValueList() {
        return this.stockValueList;
    }
}
</script>
