<template>
    <div>
        <div class="card-body">
          <CDataTable
              :pagination="false"
              :items="ArticleList"
              :fields="headers"
              :tableFilter="{label: 'Sök', placeholder: 'ange text'}"
              :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
              column-filter
              table-filter
              hover
              outlined
              sorter
              responsive>
          </CDataTable>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ArticleSubset } from '@/services/api/ApiGeneral';
@Component({})
export default class FortnoxArticleList extends Vue {
  @Prop() private articleList!: ArticleSubset[];
  private ArticleList: ArticleSubset[] = [];
  private headers = [
    { key: 'articleNumber', label: 'ArtNr', _style: 'width:20%' },
    { key: 'description', label: 'Namn', _style: 'width:80%' }
  ];
  private collapseDuration = 0;
  @Watch('articleList') private CustomerListChanged() {
    this.$nextTick(() => {
      this.ArticleList = this.articleList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
    });
  }
  private created() {
    this.ArticleList = this.articleList.map((item: any, ToogleId: any) => ({...item, ToogleId}));
  }
  private toggleDetails(item: any) {
    this.$set(this.ArticleList[item.ToogleId], '_toggled', !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => { this.collapseDuration = 0; });
  }
}
</script>
