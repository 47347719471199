const data: any[] = [
    // {
    //     Country: 'AFGHANISTAN',
    //     Name: 'Afghani',
    //     ISO: 'AFN',
    //     CurrencyNumber: '971',
    //     Units: '2',
    // },
    // {
    //     Country: 'ALBANIA',
    //     Name: 'Lek',
    //     ISO: 'ALL',
    //     CurrencyNumber: '008',
    //     Units: '2',
    // },
    // {
    //     Country: 'ALGERIA',
    //     Name: 'Algerian Dinar',
    //     ISO: 'DZD',
    //     CurrencyNumber: '012',
    //     Units: '2',
    // },
    // {
    //     Country: 'ANGOLA',
    //     Name: 'Kwanza',
    //     ISO: 'AOA',
    //     CurrencyNumber: '973',
    //     Units: '2',
    // },
    // {
    //     Country: 'ARGENTINA',
    //     Name: 'Argentine Peso',
    //     ISO: 'ARS',
    //     CurrencyNumber: '032',
    //     Units: '2',
    // },
    // {
    //     Country: 'ARMENIA',
    //     Name: 'Armenian Dram',
    //     ISO: 'AMD',
    //     CurrencyNumber: '051',
    //     Units: '2',
    // },
    // {
    //     Country: 'ARUBA',
    //     Name: 'Aruban Florin',
    //     ISO: 'AWG',
    //     CurrencyNumber: '533',
    //     Units: '2',
    // },
    // {
    //     Country: 'AUSTRALIA',
    //     Name: 'Australian Dollar',
    //     ISO: 'AUD',
    //     CurrencyNumber: '036',
    //     Units: '2',
    // },
    // {
    //     Country: 'AZERBAIJAN',
    //     Name: 'Azerbaijan Manat',
    //     ISO: 'AZN',
    //     CurrencyNumber: '944',
    //     Units: '2',
    // },
    // {
    //     Country: 'BAHAMAS (THE)',
    //     Name: 'Bahamian Dollar',
    //     ISO: 'BSD',
    //     CurrencyNumber: '044',
    //     Units: '2',
    // },
    // {
    //     Country: 'BAHRAIN',
    //     Name: 'Bahraini Dinar',
    //     ISO: 'BHD',
    //     CurrencyNumber: '048',
    //     Units: '3',
    // },
    // {
    //     Country: 'BANGLADESH',
    //     Name: 'Taka',
    //     ISO: 'BDT',
    //     CurrencyNumber: '050',
    //     Units: '2',
    // },
    // {
    //     Country: 'BARBADOS',
    //     Name: 'Barbados Dollar',
    //     ISO: 'BBD',
    //     CurrencyNumber: '052',
    //     Units: '2',
    // },
    // {
    //     Country: 'BELARUS',
    //     Name: 'Belarusian Ruble',
    //     ISO: 'BYN',
    //     CurrencyNumber: '933',
    //     Units: '2',
    // },
    // {
    //     Country: 'BELIZE',
    //     Name: 'Belize Dollar',
    //     ISO: 'BZD',
    //     CurrencyNumber: '084',
    //     Units: '2',
    // },
    // {
    //     Country: 'BERMUDA',
    //     Name: 'Bermudian Dollar',
    //     ISO: 'BMD',
    //     CurrencyNumber: '060',
    //     Units: '2',
    // },
    // {
    //     Country: 'BHUTAN',
    //     Name: 'Ngultrum',
    //     ISO: 'BTN',
    //     CurrencyNumber: '064',
    //     Units: '2',
    // },
    // {
    //     Country: 'BOLIVIA (PLURINATIONAL STATE OF)',
    //     Name: 'Boliviano',
    //     ISO: 'BOB',
    //     CurrencyNumber: '068',
    //     Units: '2',
    // },
    // {
    //     Country: 'BOSNIA AND HERZEGOVINA',
    //     Name: 'Convertible Mark',
    //     ISO: 'BAM',
    //     CurrencyNumber: '977',
    //     Units: '2',
    // },
    // {
    //     Country: 'BOTSWANA',
    //     Name: 'Pula',
    //     ISO: 'BWP',
    //     CurrencyNumber: '072',
    //     Units: '2',
    // },
    // {
    //     Country: 'BRAZIL',
    //     Name: 'Brazilian Real',
    //     ISO: 'BRL',
    //     CurrencyNumber: '986',
    //     Units: '2',
    // },
    // {
    //     Country: 'BRUNEI DARUSSALAM',
    //     Name: 'Brunei Dollar',
    //     ISO: 'BND',
    //     CurrencyNumber: '096',
    //     Units: '2',
    // },
    // {
    //     Country: 'BULGARIA',
    //     Name: 'Bulgarian Lev',
    //     ISO: 'BGN',
    //     CurrencyNumber: '975',
    //     Units: '2',
    // },
    // {
    //     Country: 'BURUNDI',
    //     Name: 'Burundi Franc',
    //     ISO: 'BIF',
    //     CurrencyNumber: '108',
    //     Units: '0',
    // },
    // {
    //     Country: 'CABO VERDE',
    //     Name: 'Cabo Verde Escudo',
    //     ISO: 'CVE',
    //     CurrencyNumber: '132',
    //     Units: '2',
    // },
    // {
    //     Country: 'CAMBODIA',
    //     Name: 'Riel',
    //     ISO: 'KHR',
    //     CurrencyNumber: '116',
    //     Units: '2',
    // },
    // {
    //     Country: 'CANADA',
    //     Name: 'Canadian Dollar',
    //     ISO: 'CAD',
    //     CurrencyNumber: '124',
    //     Units: '2',
    // },
    // {
    //     Country: 'CAYMAN ISLANDS (THE)',
    //     Name: 'Cayman Islands Dollar',
    //     ISO: 'KYD',
    //     CurrencyNumber: '136',
    //     Units: '2',
    // },
    // {
    //     Country: 'CENTRAL AFRICAN REPUBLIC (THE)',
    //     Name: 'CFA Franc BEAC',
    //     ISO: 'XAF',
    //     CurrencyNumber: '950',
    //     Units: '0',
    // },
    // {
    //     Country: 'CHILE',
    //     Name: 'Chilean Peso',
    //     ISO: 'CLP',
    //     CurrencyNumber: '152',
    //     Units: '0',
    // },
    // {
    //     Country: 'CHINA',
    //     Name: 'Yuan Renminbi',
    //     ISO: 'CNY',
    //     CurrencyNumber: '156',
    //     Units: '2',
    // },
    // {
    //     Country: 'COLOMBIA',
    //     Name: 'Colombian Peso',
    //     ISO: 'COP',
    //     CurrencyNumber: '170',
    //     Units: '2',
    // },

    // {
    //     Country: 'COMOROS (THE)',
    //     Name: 'Comorian Franc',
    //     ISO: 'KMF',
    //     CurrencyNumber: '174',
    //     Units: '0',
    // },
    // {
    //     Country: 'CONGO (THE DEMOCRATIC REPUBLIC OF THE)',
    //     Name: 'Congolese Franc',
    //     ISO: 'CDF',
    //     CurrencyNumber: '976',
    //     Units: '2',
    // },
    // {
    //     Country: 'COSTA RICA',
    //     Name: 'Costa Rican Colon',
    //     ISO: 'CRC',
    //     CurrencyNumber: '188',
    //     Units: '2',
    // },
    // {
    //     Country: 'CROATIA',
    //     Name: 'Kuna',
    //     ISO: 'HRK',
    //     CurrencyNumber: '191',
    //     Units: '2',
    // },
    // {
    //     Country: 'CUBA',
    //     Name: 'Cuban Peso',
    //     ISO: 'CUP',
    //     CurrencyNumber: '192',
    //     Units: '2',
    // },
    // {
    //     Country: 'CUBA',
    //     Name: 'Peso Convertible',
    //     ISO: 'CUC',
    //     CurrencyNumber: '931',
    //     Units: '2',
    // },
    // {
    //     Country: 'CZECHIA',
    //     Name: 'Czech Koruna',
    //     ISO: 'CZK',
    //     CurrencyNumber: '203',
    //     Units: '2',
    // },
    {
        Country: 'DENMARK',
        Name: 'Danish Krone',
        ISO: 'DKK',
        CurrencyNumber: '208',
        Units: '2',
    },
    // {
    //     Country: 'DJIBOUTI',
    //     Name: 'Djibouti Franc',
    //     ISO: 'DJF',
    //     CurrencyNumber: '262',
    //     Units: '0',
    // },
    // {
    //     Country: 'DOMINICA',
    //     Name: 'East Caribbean Dollar',
    //     ISO: 'XCD',
    //     CurrencyNumber: '951',
    //     Units: '2',
    // },
    // {
    //     Country: 'DOMINICAN REPUBLIC (THE)',
    //     Name: 'Dominican Peso',
    //     ISO: 'DOP',
    //     CurrencyNumber: '214',
    //     Units: '2',
    // },
    // {
    //     Country: 'EGYPT',
    //     Name: 'Egyptian Pound',
    //     ISO: 'EGP',
    //     CurrencyNumber: '818',
    //     Units: '2',
    // },
    // {
    //     Country: 'EL SALVADOR',
    //     Name: 'El Salvador Colon',
    //     ISO: 'SVC',
    //     CurrencyNumber: '222',
    //     Units: '2',
    // },
    // {
    //     Country: 'ERITREA',
    //     Name: 'Nakfa',
    //     ISO: 'ERN',
    //     CurrencyNumber: '232',
    //     Units: '2',
    // },
    // {
    //     Country: 'ETHIOPIA',
    //     Name: 'Ethiopian Birr',
    //     ISO: 'ETB',
    //     CurrencyNumber: '230',
    //     Units: '2',
    // },
    {
        Country: 'EUROPEAN UNION',
        Name: 'Euro',
        ISO: 'EUR',
        CurrencyNumber: '978',
        Units: '2',
    },
    // {
    //     Country: 'FALKLAND ISLANDS (THE) [MALVINAS]',
    //     Name: 'Falkland Islands Pound',
    //     ISO: 'FKP',
    //     CurrencyNumber: '238',
    //     Units: '2',
    // },
    // {
    //     Country: 'FIJI',
    //     Name: 'Fiji Dollar',
    //     ISO: 'FJD',
    //     CurrencyNumber: '242',
    //     Units: '2',
    // },
    // {
    //     Country: 'FRENCH POLYNESIA',
    //     Name: 'CFP Franc',
    //     ISO: 'XPF',
    //     CurrencyNumber: '953',
    //     Units: '0',
    // },
    // {
    //     Country: 'GAMBIA (THE)',
    //     Name: 'Dalasi',
    //     ISO: 'GMD',
    //     CurrencyNumber: '270',
    //     Units: '2',
    // },
    // {
    //     Country: 'GEORGIA',
    //     Name: 'Lari',
    //     ISO: 'GEL',
    //     CurrencyNumber: '981',
    //     Units: '2',
    // },
    // {
    //     Country: 'GHANA',
    //     Name: 'Ghana Cedi',
    //     ISO: 'GHS',
    //     CurrencyNumber: '936',
    //     Units: '2',
    // },
    // {
    //     Country: 'GIBRALTAR',
    //     Name: 'Gibraltar Pound',
    //     ISO: 'GIP',
    //     CurrencyNumber: '292',
    //     Units: '2',
    // },
    // {
    //     Country: 'GUATEMALA',
    //     Name: 'Quetzal',
    //     ISO: 'GTQ',
    //     CurrencyNumber: '320',
    //     Units: '2',
    // },
    // {
    //     Country: 'GUINEA',
    //     Name: 'Guinean Franc',
    //     ISO: 'GNF',
    //     CurrencyNumber: '324',
    //     Units: '0',
    // },
    // {
    //     Country: 'GUYANA',
    //     Name: 'Guyana Dollar',
    //     ISO: 'GYD',
    //     CurrencyNumber: '328',
    //     Units: '2',
    // },
    // {
    //     Country: 'HAITI',
    //     Name: 'Gourde',
    //     ISO: 'HTG',
    //     CurrencyNumber: '332',
    //     Units: '2',
    // },
    // {
    //     Country: 'HONDURAS',
    //     Name: 'Lempira',
    //     ISO: 'HNL',
    //     CurrencyNumber: '340',
    //     Units: '2',
    // },
    // {
    //     Country: 'HONG KONG',
    //     Name: 'Hong Kong Dollar',
    //     ISO: 'HKD',
    //     CurrencyNumber: '344',
    //     Units: '2',
    // },
    // {
    //     Country: 'HUNGARY',
    //     Name: 'Forint',
    //     ISO: 'HUF',
    //     CurrencyNumber: '348',
    //     Units: '2',
    // },
    // {
    //     Country: 'ICELAND',
    //     Name: 'Iceland Krona',
    //     ISO: 'ISK',
    //     CurrencyNumber: '352',
    //     Units: '0',
    // },
    // {
    //     Country: 'INDIA',
    //     Name: 'Indian Rupee',
    //     ISO: 'INR',
    //     CurrencyNumber: '356',
    //     Units: '2',
    // },
    // {
    //     Country: 'INDONESIA',
    //     Name: 'Rupiah',
    //     ISO: 'IDR',
    //     CurrencyNumber: '360',
    //     Units: '2',
    // },
    // {
    //     Country: 'INTERNATIONAL MONETARY FUND (IMF)',
    //     Name: 'SDR (Special Drawing Right)',
    //     ISO: 'XDR',
    //     CurrencyNumber: '960',
    //     Units: 'N.A.',
    // },
    // {
    //     Country: 'IRAN (ISLAMIC REPUBLIC OF)',
    //     Name: 'Iranian Rial',
    //     ISO: 'IRR',
    //     CurrencyNumber: '364',
    //     Units: '2',
    // },
    // {
    //     Country: 'IRAQ',
    //     Name: 'Iraqi Dinar',
    //     ISO: 'IQD',
    //     CurrencyNumber: '368',
    //     Units: '3',
    // },
    // {
    //     Country: 'ISRAEL',
    //     Name: 'New Israeli Sheqel',
    //     ISO: 'ILS',
    //     CurrencyNumber: '376',
    //     Units: '2',
    // },
    // {
    //     Country: 'JAMAICA',
    //     Name: 'Jamaican Dollar',
    //     ISO: 'JMD',
    //     CurrencyNumber: '388',
    //     Units: '2',
    // },
    // {
    //     Country: 'JAPAN',
    //     Name: 'Yen',
    //     ISO: 'JPY',
    //     CurrencyNumber: '392',
    //     Units: '0',
    // },
    // {
    //     Country: 'JORDAN',
    //     Name: 'Jordanian Dinar',
    //     ISO: 'JOD',
    //     CurrencyNumber: '400',
    //     Units: '3',
    // },
    // {
    //     Country: 'KAZAKHSTAN',
    //     Name: 'Tenge',
    //     ISO: 'KZT',
    //     CurrencyNumber: '398',
    //     Units: '2',
    // },
    // {
    //     Country: 'KENYA',
    //     Name: 'Kenyan Shilling',
    //     ISO: 'KES',
    //     CurrencyNumber: '404',
    //     Units: '2',
    // },
    // {
    //     Country: 'KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)',
    //     Name: 'North Korean Won',
    //     ISO: 'KPW',
    //     CurrencyNumber: '408',
    //     Units: '2',
    // },
    // {
    //     Country: 'KOREA (THE REPUBLIC OF)',
    //     Name: 'Won',
    //     ISO: 'KRW',
    //     CurrencyNumber: '410',
    //     Units: '0',
    // },
    // {
    //     Country: 'KUWAIT',
    //     Name: 'Kuwaiti Dinar',
    //     ISO: 'KWD',
    //     CurrencyNumber: '414',
    //     Units: '3',
    // },
    // {
    //     Country: 'KYRGYZSTAN',
    //     Name: 'Som',
    //     ISO: 'KGS',
    //     CurrencyNumber: '417',
    //     Units: '2',
    // },
    // {
    //     Country: 'LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)',
    //     Name: 'Lao Kip',
    //     ISO: 'LAK',
    //     CurrencyNumber: '418',
    //     Units: '2',
    // },
    // {
    //     Country: 'LEBANON',
    //     Name: 'Lebanese Pound',
    //     ISO: 'LBP',
    //     CurrencyNumber: '422',
    //     Units: '2',
    // },
    // {
    //     Country: 'LESOTHO',
    //     Name: 'Loti',
    //     ISO: 'LSL',
    //     CurrencyNumber: '426',
    //     Units: '2',
    // },
    // {
    //     Country: 'LIBERIA',
    //     Name: 'Liberian Dollar',
    //     ISO: 'LRD',
    //     CurrencyNumber: '430',
    //     Units: '2',
    // },
    // {
    //     Country: 'LIBYA',
    //     Name: 'Libyan Dinar',
    //     ISO: 'LYD',
    //     CurrencyNumber: '434',
    //     Units: '3',
    // },
    // {
    //     Country: 'MACAO',
    //     Name: 'Pataca',
    //     ISO: 'MOP',
    //     CurrencyNumber: '446',
    //     Units: '2',
    // },
    // {
    //     Country: 'MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)',
    //     Name: 'Denar',
    //     ISO: 'MKD',
    //     CurrencyNumber: '807',
    //     Units: '2',
    // },
    // {
    //     Country: 'MADAGASCAR',
    //     Name: 'Malagasy Ariary',
    //     ISO: 'MGA',
    //     CurrencyNumber: '969',
    //     Units: '2',
    // },
    // {
    //     Country: 'MALAWI',
    //     Name: 'Malawi Kwacha',
    //     ISO: 'MWK',
    //     CurrencyNumber: '454',
    //     Units: '2',
    // },
    // {
    //     Country: 'MALAYSIA',
    //     Name: 'Malaysian Ringgit',
    //     ISO: 'MYR',
    //     CurrencyNumber: '458',
    //     Units: '2',
    // },
    // {
    //     Country: 'MALDIVES',
    //     Name: 'Rufiyaa',
    //     ISO: 'MVR',
    //     CurrencyNumber: '462',
    //     Units: '2',
    // },
    // {
    //     Country: 'MAURITANIA',
    //     Name: 'Ouguiya',
    //     ISO: 'MRU',
    //     CurrencyNumber: '929',
    //     Units: '2',
    // },
    // {
    //     Country: 'MAURITIUS',
    //     Name: 'Mauritius Rupee',
    //     ISO: 'MUR',
    //     CurrencyNumber: '480',
    //     Units: '2',
    // },
    // {
    //     Country: 'MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP',
    //     Name: 'ADB Unit of Account',
    //     ISO: 'XUA',
    //     CurrencyNumber: '965',
    //     Units: 'N.A.',
    // },
    // {
    //     Country: 'MEXICO',
    //     Name: 'Mexican Peso',
    //     ISO: 'MXN',
    //     CurrencyNumber: '484',
    //     Units: '2',
    // },
    // {
    //     Country: 'MOLDOVA (THE REPUBLIC OF)',
    //     Name: 'Moldovan Leu',
    //     ISO: 'MDL',
    //     CurrencyNumber: '498',
    //     Units: '2',
    // },
    // {
    //     Country: 'MONGOLIA',
    //     Name: 'Tugrik',
    //     ISO: 'MNT',
    //     CurrencyNumber: '496',
    //     Units: '2',
    // },
    // {
    //     Country: 'MOROCCO',
    //     Name: 'Moroccan Dirham',
    //     ISO: 'MAD',
    //     CurrencyNumber: '504',
    //     Units: '2',
    // },
    // {
    //     Country: 'MOZAMBIQUE',
    //     Name: 'Mozambique Metical',
    //     ISO: 'MZN',
    //     CurrencyNumber: '943',
    //     Units: '2',
    // },
    // {
    //     Country: 'MYANMAR',
    //     Name: 'Kyat',
    //     ISO: 'MMK',
    //     CurrencyNumber: '104',
    //     Units: '2',
    // },
    // {
    //     Country: 'NAMIBIA',
    //     Name: 'Namibia Dollar',
    //     ISO: 'NAD',
    //     CurrencyNumber: '516',
    //     Units: '2',
    // },
    // {
    //     Country: 'NEPAL',
    //     Name: 'Nepalese Rupee',
    //     ISO: 'NPR',
    //     CurrencyNumber: '524',
    //     Units: '2',
    // },
    // {
    //     Country: 'NEW ZEALAND',
    //     Name: 'New Zealand Dollar',
    //     ISO: 'NZD',
    //     CurrencyNumber: '554',
    //     Units: '2',
    // },
    // {
    //     Country: 'NICARAGUA',
    //     Name: 'Cordoba Oro',
    //     ISO: 'NIO',
    //     CurrencyNumber: '558',
    //     Units: '2',
    // },
    // {
    //     Country: 'NIGER',
    //     Name: 'CFA Franc BCEAO',
    //     ISO: 'XOF',
    //     CurrencyNumber: '952',
    //     Units: '0',
    // },
    // {
    //     Country: 'NIGERIA',
    //     Name: 'Naira',
    //     ISO: 'NGN',
    //     CurrencyNumber: '566',
    //     Units: '2',
    // },
    // {
    //     Country: 'NORWAY',
    //     Name: 'Norwegian Krone',
    //     ISO: 'NOK',
    //     CurrencyNumber: '578',
    //     Units: '2',
    // },
    // {
    //     Country: 'OMAN',
    //     Name: 'Rial Omani',
    //     ISO: 'OMR',
    //     CurrencyNumber: '512',
    //     Units: '3',
    // },
    // {
    //     Country: 'PAKISTAN',
    //     Name: 'Pakistan Rupee',
    //     ISO: 'PKR',
    //     CurrencyNumber: '586',
    //     Units: '2',
    // },
    // {
    //     Country: 'PANAMA',
    //     Name: 'Balboa',
    //     ISO: 'PAB',
    //     CurrencyNumber: '590',
    //     Units: '2',
    // },
    // {
    //     Country: 'PAPUA NEW GUINEA',
    //     Name: 'Kina',
    //     ISO: 'PGK',
    //     CurrencyNumber: '598',
    //     Units: '2',
    // },
    // {
    //     Country: 'PARAGUAY',
    //     Name: 'Guarani',
    //     ISO: 'PYG',
    //     CurrencyNumber: '600',
    //     Units: '0',
    // },
    // {
    //     Country: 'PERU',
    //     Name: 'Sol',
    //     ISO: 'PEN',
    //     CurrencyNumber: '604',
    //     Units: '2',
    // },
    // {
    //     Country: 'PHILIPPINES (THE)',
    //     Name: 'Philippine Peso',
    //     ISO: 'PHP',
    //     CurrencyNumber: '608',
    //     Units: '2',
    // },
    // {
    //     Country: 'POLAND',
    //     Name: 'Zloty',
    //     ISO: 'PLN',
    //     CurrencyNumber: '985',
    //     Units: '2',
    // },
    // {
    //     Country: 'QATAR',
    //     Name: 'Qatari Rial',
    //     ISO: 'QAR',
    //     CurrencyNumber: '634',
    //     Units: '2',
    // },
    // {
    //     Country: 'ROMANIA',
    //     Name: 'Romanian Leu',
    //     ISO: 'RON',
    //     CurrencyNumber: '946',
    //     Units: '2',
    // },
    // {
    //     Country: 'RUSSIAN FEDERATION (THE)',
    //     Name: 'Russian Ruble',
    //     ISO: 'RUB',
    //     CurrencyNumber: '643',
    //     Units: '2',
    // },
    // {
    //     Country: 'RWANDA',
    //     Name: 'Rwanda Franc',
    //     ISO: 'RWF',
    //     CurrencyNumber: '646',
    //     Units: '0',
    // },
    // {
    //     Country: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    //     Name: 'Saint Helena Pound',
    //     ISO: 'SHP',
    //     CurrencyNumber: '654',
    //     Units: '2',
    // },
    // {
    //     Country: 'SAMOA',
    //     Name: 'Tala',
    //     ISO: 'WST',
    //     CurrencyNumber: '882',
    //     Units: '2',
    // },
    // {
    //     Country: 'SAO TOME AND PRINCIPE',
    //     Name: 'Dobra',
    //     ISO: 'STN',
    //     CurrencyNumber: '930',
    //     Units: '2',
    // },
    // {
    //     Country: 'SAUDI ARABIA',
    //     Name: 'Saudi Riyal',
    //     ISO: 'SAR',
    //     CurrencyNumber: '682',
    //     Units: '2',
    // },
    // {
    //     Country: 'SERBIA',
    //     Name: 'Serbian Dinar',
    //     ISO: 'RSD',
    //     CurrencyNumber: '941',
    //     Units: '2',
    // },
    // {
    //     Country: 'SEYCHELLES',
    //     Name: 'Seychelles Rupee',
    //     ISO: 'SCR',
    //     CurrencyNumber: '690',
    //     Units: '2',
    // },
    // {
    //     Country: 'SIERRA LEONE',
    //     Name: 'Leone',
    //     ISO: 'SLL',
    //     CurrencyNumber: '694',
    //     Units: '2',
    // },
    // {
    //     Country: 'SINGAPORE',
    //     Name: 'Singapore Dollar',
    //     ISO: 'SGD',
    //     CurrencyNumber: '702',
    //     Units: '2',
    // },
    // {
    //     Country: 'SINT MAARTEN (DUTCH PART)',
    //     Name: 'Netherlands Antillean Guilder',
    //     ISO: 'ANG',
    //     CurrencyNumber: '532',
    //     Units: '2',
    // },
    // {
    //     Country: 'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS \'SUCRE\'',
    //     Name: 'Sucre',
    //     ISO: 'XSU',
    //     CurrencyNumber: '994',
    //     Units: 'N.A.',
    // },
    // {
    //     Country: 'SOLOMON ISLANDS',
    //     Name: 'Solomon Islands Dollar',
    //     ISO: 'SBD',
    //     CurrencyNumber: '090',
    //     Units: '2',
    // },
    // {
    //     Country: 'SOMALIA',
    //     Name: 'Somali Shilling',
    //     ISO: 'SOS',
    //     CurrencyNumber: '706',
    //     Units: '2',
    // },
    // {
    //     Country: 'SOUTH AFRICA',
    //     Name: 'Rand',
    //     ISO: 'ZAR',
    //     CurrencyNumber: '710',
    //     Units: '2',
    // },
    // {
    //     Country: 'SOUTH SUDAN',
    //     Name: 'South Sudanese Pound',
    //     ISO: 'SSP',
    //     CurrencyNumber: '728',
    //     Units: '2',
    // },
    // {
    //     Country: 'SRI LANKA',
    //     Name: 'Sri Lanka Rupee',
    //     ISO: 'LKR',
    //     CurrencyNumber: '144',
    //     Units: '2',
    // },
    // {
    //     Country: 'SUDAN (THE)',
    //     Name: 'Sudanese Pound',
    //     ISO: 'SDG',
    //     CurrencyNumber: '938',
    //     Units: '2',
    // },
    // {
    //     Country: 'SURINAME',
    //     Name: 'Surinam Dollar',
    //     ISO: 'SRD',
    //     CurrencyNumber: '968',
    //     Units: '2',
    // },
    // {
    //     Country: 'ESWATINI',
    //     Name: 'Lilangeni',
    //     ISO: 'SZL',
    //     CurrencyNumber: '748',
    //     Units: '2',
    // },
    {
        Country: 'SWEDEN',
        Name: 'Swedish Krona',
        ISO: 'SEK',
        CurrencyNumber: '752',
        Units: '2',
    },
    // {
    //     Country: 'SWITZERLAND',
    //     Name: 'Swiss Franc',
    //     ISO: 'CHF',
    //     CurrencyNumber: '756',
    //     Units: '2',
    // },
    // {
    //     Country: 'SYRIAN ARAB REPUBLIC',
    //     Name: 'Syrian Pound',
    //     ISO: 'SYP',
    //     CurrencyNumber: '760',
    //     Units: '2',
    // },
    // {
    //     Country: 'TAIWAN (PROVINCE OF CHINA)',
    //     Name: 'New Taiwan Dollar',
    //     ISO: 'TWD',
    //     CurrencyNumber: '901',
    //     Units: '2',
    // },
    // {
    //     Country: 'TAJIKISTAN',
    //     Name: 'Somoni',
    //     ISO: 'TJS',
    //     CurrencyNumber: '972',
    //     Units: '2',
    // },
    // {
    //     Country: 'TANZANIA, UNITED REPUBLIC OF',
    //     Name: 'Tanzanian Shilling',
    //     ISO: 'TZS',
    //     CurrencyNumber: '834',
    //     Units: '2',
    // },
    // {
    //     Country: 'THAILAND',
    //     Name: 'Baht',
    //     ISO: 'THB',
    //     CurrencyNumber: '764',
    //     Units: '2',
    // },
    // {
    //     Country: 'TONGA',
    //     Name: 'Pa’anga',
    //     ISO: 'TOP',
    //     CurrencyNumber: '776',
    //     Units: '2',
    // },
    // {
    //     Country: 'TRINIDAD AND TOBAGO',
    //     Name: 'Trinidad and Tobago Dollar',
    //     ISO: 'TTD',
    //     CurrencyNumber: '780',
    //     Units: '2',
    // },
    // {
    //     Country: 'TUNISIA',
    //     Name: 'Tunisian Dinar',
    //     ISO: 'TND',
    //     CurrencyNumber: '788',
    //     Units: '3',
    // },
    // {
    //     Country: 'TURKEY',
    //     Name: 'Turkish Lira',
    //     ISO: 'TRY',
    //     CurrencyNumber: '949',
    //     Units: '2',
    // },
    // {
    //     Country: 'TURKMENISTAN',
    //     Name: 'Turkmenistan New Manat',
    //     ISO: 'TMT',
    //     CurrencyNumber: '934',
    //     Units: '2',
    // },
    // {
    //     Country: 'UGANDA',
    //     Name: 'Uganda Shilling',
    //     ISO: 'UGX',
    //     CurrencyNumber: '800',
    //     Units: '0',
    // },
    // {
    //     Country: 'UKRAINE',
    //     Name: 'Hryvnia',
    //     ISO: 'UAH',
    //     CurrencyNumber: '980',
    //     Units: '2',
    // },
    // {
    //     Country: 'UNITED ARAB EMIRATES (THE)',
    //     Name: 'UAE Dirham',
    //     ISO: 'AED',
    //     CurrencyNumber: '784',
    //     Units: '2',
    // },
    // {
    //     Country: 'UNITED KINGDOM',
    //     Name: 'Pound Sterling',
    //     ISO: 'GBP',
    //     CurrencyNumber: '826',
    //     Units: '2',
    // },
    {
        Country: 'UNITED STATES OF AMERICA',
        Name: 'US Dollar',
        ISO: 'USD',
        CurrencyNumber: '840',
        Units: '2',
    }
    // {
    //     Country: 'URUGUAY',
    //     Name: 'Peso Uruguayo',
    //     ISO: 'UYU',
    //     CurrencyNumber: '858',
    //     Units: '2',
    // },
    // {
    //     Country: 'URUGUAY',
    //     Name: 'Unidad Previsional',
    //     ISO: 'UYW',
    //     CurrencyNumber: '927',
    //     Units: '4',
    // },
    // {
    //     Country: 'UZBEKISTAN',
    //     Name: 'Uzbekistan Sum',
    //     ISO: 'UZS',
    //     CurrencyNumber: '860',
    //     Units: '2',
    // },
    // {
    //     Country: 'VANUATU',
    //     Name: 'Vatu',
    //     ISO: 'VUV',
    //     CurrencyNumber: '548',
    //     Units: '0',
    // },
    // {
    //     Country: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
    //     Name: 'Bolívar Soberano',
    //     ISO: 'VES',
    //     CurrencyNumber: '928',
    //     Units: '2',
    // },
    // {
    //     Country: 'VIET NAM',
    //     Name: 'Dong',
    //     ISO: 'VND',
    //     CurrencyNumber: '704',
    //     Units: '0',
    // },
    // {
    //     Country: 'YEMEN',
    //     Name: 'Yemeni Rial',
    //     ISO: 'YER',
    //     CurrencyNumber: '886',
    //     Units: '2',
    // },
    // {
    //     Country: 'ZAMBIA',
    //     Name: 'Zambian Kwacha',
    //     ISO: 'ZMW',
    //     CurrencyNumber: '967',
    //     Units: '2',
    // },
    // {
    //     Country: 'ZIMBABWE',
    //     Name: 'Zimbabwe Dollar',
    //     ISO: 'ZWL',
    //     CurrencyNumber: '932',
    //     Units: '2',
    // },
];
export default data;
