import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
import createPersistedState from "vuex-persistedstate";
import { config } from '@/global/OidcConfig';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '1.0',
    loading: false,
    sidebarShow: 'responsive' as any,
    sidebarMinimize: false
  },
  getters: {
    getLoadingState: (state) => {
      return state.loading;
    }
  },
  mutations: {
    setLoadingState(state, payload) {
      state.loading = payload;
    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
      state[variable] = value
    }
  },
  actions: {
    setLoading({ commit }, payload) {
      commit('setLoadingState', payload)
    }
  },
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      config,
      {
        namespaced: false,
        dispatchEventsOnWindow: true
      },
      // {
      //   accessTokenExpired: () => console.log('Updating session...'),
      //   oidcError: () => Vue.prototype.$beplugins.Signout(),
      //   accessTokenExpiring: () => console.log('Session about to expire!'),
      //   silentRenewError: () => Vue.prototype.$beplugins.Signout(),
      //   userSignedOut: () => Vue.prototype.$beplugins.Signout(),
      // },
    )
  },
  plugins: [createPersistedState()],
})
