<template>
  <div>
    <div class="card-body">
      <CDataTable
        height="300px"
        :pagination="false"
        :items="productList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { OrderProductDTO } from "@/services/api/ApiGeneral";
@Component({})
export default class OrderedCustomerProductList extends Vue {
  @Prop() private productList!: OrderProductDTO[];
  private headers = [
    { key: "productNumber", label: "ArtNr", _style: "width:33%" },
    { key: "name", label: "Namn", _style: "width:33%" },
    { key: "pieces", label: "Antal", _style: "width:33%;" },
  ];
}
</script>
