<template>
<ValidationObserver ref="observer">
    <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6"><strong>Planeringar</strong></div>
              <div class="col-6">
                <VueCtkDateTimePicker
                  style="width:120px !important;"
                  :no-button-now="false"
                  format="YYYY-MM-DD"
                  formatted="YYYY-MM-DD"
                  class="deliveryDate form-control form-control-sm float-right"
                  :no-label="true"
                  :only-date="true"
                  :right="true"
                  :noClearButton="true"
                  :auto-close="true"
                  locale="sv"
                  v-model="filterDate" />
                  <span class="float-right mr-3 mt-1">Filtrera efter leveransdatum</span>
                  <CSwitch color="info" variant="3d" labelOn="T" labelOff="D" :checked.sync="isDragMode" />
              </div>
            </div>
          </div>
          <DragMode v-if="isDragMode" :selectedDate="filterDate" />
          <ListMode v-else :selectedDate="filterDate" />
        </div>
      </div>
    </form>
</ValidationObserver>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Moment from 'moment';
import DragMode from '@/components/planning/DragMode.vue';
import ListMode from '@/components/planning/ListMode.vue';
@Component({
  components: {
    VueCtkDateTimePicker,
    DragMode,
    ListMode
  }
})
export default class PlanningComp extends Vue {
  private isDragMode: boolean = false;
  private filterDate: string = '';
  private moment = Moment;
  private async created() {
    this.filterDate = Moment(new Date()).format('YYYY-MM-DD');
  }
}
</script>
<style lang="scss" scoped>
</style>