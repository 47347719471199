<template>
  <div v-if="!isLoading" class="card print" style="border:none !important;">
    <img class="watermark-logo" src="@/assets/images/watermark.png" />
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-6">
          <h2>
            Plocklista
          </h2>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
            <h4>{{ GetSubtitle }}</h4>
        </div>
        <div class="col-6">
            <CButton @click="PrintList()" size="sm" color="info" class="ml-1 float-right no-print">
                Skriv ut
            </CButton>
        </div>
      </div>
      <div v-if="printProps.isCar" class="table-responsive-sm">
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th v-if="!printProps.showFrozenItems">Kund</th>
              <th v-if="!printProps.showFrozenItems">Ordernr</th>
              <th>Artikelnr</th>
              <th>Produkt</th>
              <th class="text-center">Lagerplats</th>
              <th class="text-center">Enhet</th>
              <th class="text-center">Antal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, productIndex) in GetProducts" :key="`row-${productIndex}-product-${product.id}`">
              <td>{{ ++productIndex }}</td>
              <td v-if="!printProps.showFrozenItems">{{product.customer}}</td>
              <td v-if="!printProps.showFrozenItems">{{product.orderNr}}</td>
              <td>{{product.productNumber}}</td>
              <td>{{product.name}}</td>
              <td class="text-center">{{product.storageLocation}}</td>
              <td class="text-center">{{ $t( `enums.${$beplugins.GetEnumsByValue('UnitEnums', product.unit)}`)}}</td>
              <td class="text-center">{{product.quantity}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="table-responsive-sm">
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th v-if="printProps.ids.length < 2 && !printProps.isCar">Kund</th>
              <th v-if="printProps.ids.length < 2 && !printProps.isCar">Ordernr</th>
              <th>Artikelnr</th>
              <th>Produkt</th>
              <th class="text-center">Lagerplats</th>
              <th class="text-center">Enhet</th>
              <th class="text-center">Antal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, productIndex) in GetProducts" :key="`row-${productIndex}-product-${product.id}`">
              <td>{{ ++productIndex }}</td>
              <td v-if="printProps.ids.length < 2 && !printProps.isCar">{{product.customer}}</td>
              <td v-if="printProps.ids.length < 2 && !printProps.isCar">{{product.orderNr}}</td>
              <td>{{product.productNumber}}</td>
              <td>{{product.name}}</td>
              <td class="text-center">{{product.storageLocation}}</td>
              <td class="text-center">{{ $t( `enums.${$beplugins.GetEnumsByValue('UnitEnums', product.unit)}`)}}</td>
              <td class="text-center">{{product.quantity}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import {  ProductEntity, OrderEntity, OrderProductEntity, CarPlanningEntity } from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
import Loading from 'vue-loading-overlay';
@Component({
  components: {
    Loading,
  },
})
// Dry = 0, Frozen = 1, Fridge = 2
export default class PrintOrderList extends Vue {
  private isLoading: boolean = true;
  private printProps = {
    isCar: false,
    ids: [''],
    showFrozenItems: false,
    showDryItems: false,
    showFridgeItems: false,
  };
  private totalWeight: number = 0;
  private pModel: CarPlanningEntity[] = [];
  private oModel: OrderEntity[] = [];
  private products: ProductEntity[] = [];
  private PrintList() {
    window.print();
  }
  get GetSubtitle() {
    const result: string[] = [];
    if (this.printProps.showFrozenItems) {
      result.push('Frys');
    }
    if (this.printProps.showFridgeItems) {
      result.push('Kyl');
    }
    if (this.printProps.showDryItems) {
      result.push('Torr');
    }
    let final: string = '';
    if (result.length > 0) {
      final = result.join(', ') + 'lista'
    } else {
      final = 'Tom lista'
    }
    return final;
  }
  get GetProducts() {
    const result: OrderProductEntity[] = [];
    if (this.printProps.isCar) {
      this.pModel.forEach((c) => {
        c.orders!.forEach((o) => {
          o.products!.forEach((p) => {
            p["orderNr"] = o.orderId;
            p["customer"] = o.customer!.customerMode === 0 ? o.customer!.companyName : `${o.customer!.firstName} ${o.customer!.lastName}`;
            if (this.printProps.showFrozenItems && p.productType === 1) {
              const itemExistIndex = result.findIndex((x) => x.productNumber === p.productNumber && x.unit === p.unit);
              if (itemExistIndex !== -1) {
                result[itemExistIndex].quantity += p.quantity;
                result[itemExistIndex]["customer"] += ` - ${o.customer!.customerMode === 0 ? o.customer!.companyName : o.customer!.firstName + " " + o.customer!.lastName}`;
                result[itemExistIndex]["orderNr"] += ` - ${o.orderId}`;
              } else {
                result.push(p);
              }
            } else if (this.printProps.showFridgeItems && p.productType === 2) {
              result.push(p);
            } else if (this.printProps.showDryItems && p.productType === 0) {
              result.push(p);
            }
          });
        });
      });
    } else {
      this.oModel.forEach((order) => {
        order.products!.forEach((p) => {
          p["orderNr"] = order.orderId;
          p["customer"] = order.customer!.customerMode === 0 ? order.customer!.companyName : `${order.customer!.firstName} ${order.customer!.lastName}`;
          if (this.printProps.showFrozenItems && p.productType === 1) {
            const itemExistIndex = result.findIndex((x) => x.productNumber === p.productNumber && x.unit === p.unit);
            if (itemExistIndex !== -1) {
              result[itemExistIndex].quantity += p.quantity;
              result[itemExistIndex]["customer"] += ` - ${order.customer!.customerMode === 0 ? order.customer!.companyName : order.customer!.firstName + " " + order.customer!.lastName}`;
              result[itemExistIndex]["orderNr"] += ` - ${order.orderId}`;
            } else {
              result.push(p);
            }
          } else if (this.printProps.showFridgeItems && p.productType === 2) {
            result.push(p);
          } else if (this.printProps.showDryItems && p.productType === 0) {
            result.push(p);
          }
        });
      });
    }
    return result;
  }
  private async LoadCars() {
    const ids = this.printProps.ids.map((x) => parseInt(x, 0));
    await apiGeneral.carPlanning_GetAllByIds(ids).then((response) => {
      this.pModel = response;
      this.isLoading = false;
    });
  }
  private async LoadOrder() {
    await apiGeneral.order_GetOrderByIds(this.printProps.ids).then((response) => {
      this.oModel = response;
      this.isLoading = false;
    });
  }
  private async created() {
    this.printProps = {
      isCar: this.$route.params.isCar === "true",
      ids: JSON.parse(this.$route.params.ids),
      showFrozenItems: this.$route.params.showFrozenItems === "true",
      showDryItems: this.$route.params.showDryItems === "true",
      showFridgeItems: this.$route.params.showFridgeItems === "true",
    };
    if (this.printProps.isCar) {
      await this.LoadCars();
    } else {
      await this.LoadOrder();
    }
  }
}
</script>
<style lang="scss">
</style>
