<template>
  <div class="c-app">
    <Sidebar />
    <CWrapper>
      <Header />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
        <Footer />
      </div>
    </CWrapper>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/containers/Sidebar.vue';
import Header from '@/containers/Header.vue';
import Footer from '@/containers/Footer.vue';
@Component({
  components: {
    Sidebar,
    Header,
    Footer,
  },
})
export default class Container extends Vue {}
</script>
<style lang="scss">
@import '@/assets/scss/style';
</style>
