import GlobalConfig from '@/global/GlobalConfig';
import store from '@/store/index';
import { Client as ApiGeneral } from '@/services/api/ApiGeneral';
import axios from 'axios';
const instanceGeneral = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});
instanceGeneral.interceptors.request.use((config) => {
  const user = store.getters['oidcUser'];
  config.headers['email'] = user.email;
  config.headers.Authorization = `Bearer ${store.getters['oidcAccessToken']}`,
  store.dispatch('setLoading', true);
  return config;
});
instanceGeneral.interceptors.response.use((response) => {
  store.dispatch('setLoading', false);
  return response;
});
export const apiGeneral = new ApiGeneral(
  GlobalConfig.GeneralApi,
  instanceGeneral,
);
