<template>
  <div class="animated fadeIn">
    <CarNew />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CarNew from '@/components/car/CarNew.vue';
@Component({
  components: {
      CarNew,
  },
})
export default class CarCreate extends Vue {}
</script>
