<template>
  <div style="height: 100% !important;">
    <transition name="fade" mode="out-in">
      <Spinner ref="loading" />
    </transition>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Spinner from '@/components/loaders/Spinner.vue';
import { Action } from "vuex-class";
@Component({
  components: {
    Spinner,
  },
})
export default class App extends Vue {
  @Action("authenticateOidcSilent") private authenticateOidcSilent!: () => Promise<string>;
  private mounted() {
    window.addEventListener('vuexoidc:accessTokenExpired', this.tokenExpired)
  }
  private destroyed() {
    window.removeEventListener('vuexoidc:accessTokenExpired', this.tokenExpired);
  }
  private async tokenExpired(e: any) {
    await this.authenticateOidcSilent().catch((err) => {
      console.error(err);
    });
  }
}
</script>
<style lang="scss">
/* Import Font Awesome Icons Set */
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
/* Import Simple Line Icons Set */
$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
/* Import Flag Icons Set */
@import '~flag-icon-css/css/flag-icon.min.css';
.form-label{
  font-size: 1.15rem !important;
  font-weight: 500;
}
</style>
