<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding: 0.3rem .3rem !important;">
          <label>Fakturor hos fortnox som ej genererats</label>
        </div>
        <FortnoxInvoiceMissingList
          :invoiceList="GetInvoiceList"
          @reload="loadData"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { apiGeneral } from "@/services/api/ApiServices";
import { InvoiceEntity } from "@/services/api/ApiGeneral";
import FortnoxInvoiceMissingList from "@/components/fortnox/invoices/FortnoxInvoiceMissingList.vue";
@Component({
  components: {
    FortnoxInvoiceMissingList,
  },
})
export default class FortnoxInvoicesMissing extends Vue {
  private invoiceList: InvoiceEntity[] = [];
  private async created() {
    await this.loadData();
  }
  private async loadData() {
    await apiGeneral
      .fortnox_GetAllInvoicesNotGenerated()
      .then((data) => {
        this.invoiceList = data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  get GetInvoiceList() {
    return this.invoiceList;
  }
}
</script>
