<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <ProviderList :providerList="GetProviderList" @reload="loadData" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { ProviderEntity } from '@/services/api/ApiGeneral';
import ProviderList from '@/components/provider/ProviderList.vue';
@Component({
  components: {
      ProviderList
  },
})
export default class Providers extends Vue {
    private providerList: ProviderEntity[] = [];
    private async created() {
        await this.loadData();
    }
    private async loadData() {
        await apiGeneral.provider_GetAll().then((data) => {
            this.providerList = data;
        });
    }
    get GetProviderList() {
        return this.providerList;
    }
}
</script>
