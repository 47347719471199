<template>
  <div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="InvoiceList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        hover
        outlined
        sorter
        responsive
      >
        <template #show_details="{ item, index }">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="toggleDetails(item, index)"
            >
              {{ Boolean(item._toggled) ? "Dölj" : "Visa" }}
            </CButton>
          </td>
        </template>
        <template #details="{ item }">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
            style="border-top: 1px solid #d8dbe0"
          >
            <div class="col-12 py-2">
              <h4>{{ item.customerName }}</h4>
              <CButton
                @click="createInvoice(item)"
                size="sm"
                color="danger"
                class="ml-1"
              >
                Skapa faktura
              </CButton>
            </div>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { InvoiceEntity } from "@/services/api/ApiGeneral";
import { apiGeneral } from "@/services/api/ApiServices";
@Component({})
export default class FortnoxInvoiceMissingList extends Vue {
  @Prop() private invoiceList!: InvoiceEntity[];
  private InvoiceList: InvoiceEntity[] = [];
  private headers = [
    { key: "invoiceNumber", label: "FakturaNr", _style: "width:20%" },
    { key: "customerNo", label: "KundNr", _style: "width:20%" },
    { key: "customerName", label: "Företag", _style: "width:20%" },
    { key: "amount", label: "Summa", _style: "width:10%;" },
    { key: "amountIncVAT", label: "Summa + Moms", _style: "width:10%;" },
    { key: "created", label: "Skapad", _style: "width:10%;" },
    {
      key: "show_details",
      label: "",
      _style: "width:1%",
      sorter: false,
      filter: false,
    },
  ];
  private collapseDuration = 0;
  @Watch("invoiceList") private InvoiceListChanged() {
    this.$nextTick(() => {
      this.InvoiceList = this.invoiceList.map((item: any, ToogleId: any) => ({
        ...item,
        ToogleId,
      }));
    });
  }
  private created() {
    this.InvoiceList = this.invoiceList.map((item: any, ToogleId: any) => ({
      ...item,
      ToogleId,
    }));
  }
  private toggleDetails(item: any) {
    this.$set(this.InvoiceList[item.ToogleId], "_toggled", !item._toggled);
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
    });
  }
  private async createInvoice(selected: InvoiceEntity) {
    const selectedItems: number[] = [];
    selectedItems.push(selected.id);
    if (selectedItems.length > 0) {
      await apiGeneral
        .fortnox_CreateInvoiceById(selectedItems)
        .then((response) => {
          alert("Faktura skapat!");
        });
    } else {
      alert("Vänligen markera de du vill fakturera!");
    }
  }
}
</script>
