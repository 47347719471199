<template>
<div class="row">
    <div class="col-6">
        <ul class="list-group dual-list-box">
            <li>
                <input v-model="searchSource" class="form-control form-control-sm" placeholder="sök..." />
            </li>
            <li class="list-group-item" @click="SelectSource(sourceItem)" v-for="(sourceItem, sourceIndex) in GetSource" :key="`${sourceItem.Label}-${sourceIndex}`" :index="sourceIndex">{{ sourceItem.Label}}</li>
            <li v-if="GetSource.length === 0" class="list-group-item list-item-search-empty">
                ingen data...
            </li>
        </ul>
    </div>
    <div class="col-6">
        <ul class="list-group dual-list-box">
            <li>
                <input v-model="searchDestination" class="form-control form-control-sm" placeholder="sök..." />
            </li>
            <div v-if="isValue">
                <li class="list-group-item" v-for="(sourceItem, sourceIndex) in GetSelected" :key="`${sourceItem.Label}-${sourceIndex}`" :index="sourceIndex">
                    <div class="row">
                        <div class="col-7" @click="SelectSource(sourceItem)"><span class="align-middle">{{ sourceItem.Label}}</span></div>
                        <div class="col-5">
                            
                            <div class="input-group input-group-sm">
                                <input v-model.number="sourceItem.Value" type="number" class="form-control form-control-sm text-right" @keyup="SourceChangeEvent" />
                                <div class="input-group-append">
                                    <span class="input-group-text" style="width:40px;" id="inputGroupPrepend">{{ sourceItem.Extra1}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </div>
            <div v-else>
                <li class="list-group-item" @click="SelectSource(sourceItem)" v-for="(sourceItem, sourceIndex) in GetSelected" :key="sourceItem.Label" :index="sourceIndex">{{ sourceItem.Label}}</li>
            </div>
            <li v-if="GetSelected.length === 0" class="list-group-item list-item-search-empty">
                ingen data...
            </li>
        </ul>
    </div>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DualListBoxViewModel } from '@/types/tools/DualListBoxViewModel';
@Component({
  components: {
  },
})
export default class DualListBox extends Vue {
    @Prop() private source!: DualListBoxViewModel[];
    @Prop() private isValue!: boolean;
    private searchSource: string = '';
    private searchDestination: string = '';
    @Watch('GetSource', {deep: true}) private SourceChangeEvent() {
        const result = this.source.filter((item) => item.Selected);
        this.$emit('DualListOnChange', result);
    }
    get GetSource() {
        return this.source.filter((item) => {
            if (this.searchSource === '') {
                return !item.Selected;
            } else {
                return !item.Selected && item.Label.toLowerCase().includes(this.searchSource.toLowerCase());
            }
        });
    }
    get GetSelected() {
        return this.source.filter((item) => {
            if (this.searchDestination === '') {
                return item.Selected;
            } else {
                return item.Selected && item.Label.toLowerCase().includes(this.searchDestination.toLowerCase());
            }
        });
    }
    private SelectSource(item: any) {
        item.Selected = !item.Selected;
    }
}
</script>
<style lang="scss">
    .list-item-search-empty{
        background: #f86c6b;
        color: #fff;
        border-radius: 0 !important;
        text-align: center;
        &:hover{
            background: #f86c6b !important;
            color: #fff;
        }
    }
    .list-group-item{
        cursor: pointer;
        padding: 0.15rem 1.25rem;
        border-radius: 0 !important;
        &:hover{
            background: #20a8d8;
            color: #fff;
        }
    }
    .dual-list-box{
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        height: 235px !important;
        overflow-y:scroll;
    }
</style>