<template>
  <div class="animated fadeIn">
    <ValidationObserver ref="observer">
      <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Företagsuppgifter</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label">Företag</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.companyName" name="companyName" class="form-control" type="text" placeholder="Vänligen ange företagsnamn..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label">Orgnummer</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.companyNr" name="companyNr" class="form-control" type="text" placeholder="Vänligen ange orgnummer..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label class="form-label">Momsnummer</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.vatNr" name="vatNr" class="form-control" type="text" placeholder="Vänligen ange momsnummer..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Adress 1</label>
              <ValidationProvider rules="required" v-slot="v">
                <input v-model="vModel.addressLine1" name="addressLine1" class="form-control" type="text" placeholder="Vänligen ange adress..." />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label class="form-label">Adress 2</label>
              <input v-model="vModel.addressLine2" name="addressLine2" class="form-control" type="text" placeholder="Vänligen ange adress2..." />
            </div>
            <div class="row">
               <div class="form-group col-6">
                <label class="form-label">Postnummer</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.postalCode" name="postalCode" class="form-control" type="text" placeholder="Vänligen ange postnummer..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label">Stad</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.city" name="city" class="form-control" type="text" placeholder="Vänligen ange stad..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
                <label class="form-label">Land</label>
                <select v-model="vModel.country" class="form-control">
                    <option v-for="(country, countryIndex) in GetCountries" :key="country.code" :index="countryIndex" :value="country.code">{{country.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-label">Telefon</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.phone" name="phone" class="form-control" type="text" placeholder="Vänligen ange telefon..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            <div class="form-group">
                <label class="form-label">E-post (kontakt)</label>
                <ValidationProvider rules="required|email" v-slot="v">
                  <input v-model="vModel.email1" name="email1" class="form-control" type="text" placeholder="Vänligen ange e-post..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label class="form-label">E-post (order)</label>
                <ValidationProvider rules="email" v-slot="v">
                  <input v-model="vModel.email2" name="email2" class="form-control" type="text" placeholder="Vänligen ange e-post..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label class="form-label">Hemsida</label>
                <input v-model="vModel.website" name="website" class="form-control" type="text" placeholder="Vänligen ange hemsida..." />
              </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Fakturauppgifter</strong>
          </div>
          <div class="card-body">
              <div class="form-group">
                <label class="form-label">BankGiro</label>
                <input v-model="vModel.bankGiro" name="BankGiro" class="form-control" type="text" placeholder="Vänligen ange BankGiro..." />
              </div>
              <div class="form-group">
                <label class="form-label">PlusGiro</label>
                <input v-model="vModel.plusGiro" name="PlusGiro" class="form-control" type="text" placeholder="Vänligen ange PlusGiro..." />
              </div>
              <div class="form-group">
                <label class="form-label">IBAN</label>
                <input v-model="vModel.iban" name="IBAN" class="form-control" type="text" placeholder="Vänligen ange IBAN..." />
              </div>
            <div class="form-group">
              <label class="form-label">Information</label>
              <textarea v-model="vModel.invoiceMessage" rows="5" class="form-control" type="text" placeholder="Vänligen ange extra information..." />
            </div>
            <button type="submit" class="btn btn-success pull-right">Spara</button>
          </div>
        </div>
      </div>
     </form>
    </ValidationObserver>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { CompanyDetails } from '@/services/api/ApiGeneral';
import countries from '@/assets/data/countries.ts';
@Component({})
export default class Setting extends Vue {
  private isLoading: boolean = true;
  private vModel: CompanyDetails = new CompanyDetails({
    id: 0,
    interestRateDelay: 0,
    vatNr: '',
    companyNr: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    country: '',
    phone: '',
    email1: '',
    email2: '',
    website: '',
    bankGiro: '',
    plusGiro: '',
    iban: '',
    invoiceMessage: '',
  });
  get GetCountries() {
    return countries;
  }
  private async LoadData() {
    await apiGeneral.companyDetail_Get().then((response) => {
      this.vModel = response;
    });
  }
  private async created() {
    await this.LoadData().then(() => {
      this.isLoading = false;
    });
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    await apiGeneral.companyDetail_Update(this.vModel).then((response) => {
      if (response.isSuccess && response.messages != null) {
          this.$beplugins.ApiSuccess(response);
          (this.$refs.observer as any).reset();
      } else {
          this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
