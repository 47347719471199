<template>
  <div class="animated fadeIn">
    <ProviderUpdate :providerId="providerId" />
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ProviderUpdate from '@/components/provider/ProviderUpdate.vue';
@Component({
  components: {
    ProviderUpdate
  }
})
export default class ProviderEdit extends Vue {
  private providerId!: string;
  private created() {
      this.providerId = this.$route.params.id;
  }
}
</script>
