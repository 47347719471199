<template>
  <div class="animated fadeIn">
    <EmployeeUpdate :employeeId="employeeId" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import EmployeeUpdate from '@/components/employee/EmployeeUpdate.vue';
@Component({
  components: {
      EmployeeUpdate,
  },
})
export default class EmployeeEdit extends Vue {
    private employeeId!: string;
    private created() {
        this.employeeId = this.$route.params.id;
    }
}
</script>
