<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding: 0.3rem .3rem !important;">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              :class="
                `btn ${customerMode === 0 ? 'btn-info active' : 'btn-secondary'}`
              "
            >
              <input
                v-model="customerMode"
                :value="0"
                type="radio"
                name="userMode"
                id="option1"
                autocomplete="off"
              />
              Företag
            </label>
            <label
              :class="
                `btn ${customerMode === 1 ? 'btn-info active' : 'btn-secondary'}`
              "
            >
              <input
                v-model="customerMode"
                :value="1"
                type="radio"
                name="userMode"
                id="option2"
                autocomplete="off"
              />
              Privat
            </label>
          </div>
        </div>
        <CustomerList
          :customerList="GetCustomerList" :customerMode="customerMode"
          @reload="loadCustomers"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { CustomerEntity, CustomerModeEnums } from '@/services/api/ApiGeneral';
import CustomerList from '@/components/customer/CustomerList.vue';
@Component({
  components: {
    CustomerList,
  },
})
export default class Customers extends Vue {
  private customerMode: CustomerModeEnums = 0;
  private customerList: CustomerEntity[] = [];
  private async created() {
    await this.loadCustomers();
  }
  private async loadCustomers() {
    await apiGeneral.customer_GetAll(false, false, false, false, false).then((data) => {
      this.customerList = data;
    }).catch((err) => {
      console.log(err);
    });
  }
  get GetCustomerList() {
    return this.customerList.filter((x) => x.customerMode === this.customerMode);
  }
}
</script>
