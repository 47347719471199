<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header" style="padding: 0.3rem .3rem !important;">
          <label>Fortnox fakturor</label> 
          <select v-model="selectedFilter" class="form-control pull-right" style="max-width:250px;">
            <option :value="-1">
                Alla
            </option>
            <option v-for="(item, itemIndex) in $beplugins.GetEnumsByType('Invoice3')" :key="`invoice-booking-enum-index-${itemIndex}`" :value="item.value">
            {{ $t(`fortnox.enums.${item.label}`) }}
            </option>
          </select>
        </div>
        <FortnoxInvoiceList
          :invoiceList="GetInvoiceList"
          @reload="loadData"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { Invoice2, Invoice3, InvoiceSearch, InvoiceSubset } from '@/services/api/ApiGeneral';
import FortnoxInvoiceList from '@/components/fortnox/invoices/FortnoxInvoiceList.vue';
import moment from 'moment';
@Component({
  components: {
    FortnoxInvoiceList,
  },
})
export default class FortnoxInvoices extends Vue {
  private invoiceList: InvoiceSubset[] = [];
  private selectedFilter: number = Invoice3.Unpaid;
  private searchFilter: InvoiceSearch = new InvoiceSearch({
    page: 1,
    fromDate: moment(moment().add(-4, 'months')).format('YYYY-MM-DD'),
    filterBy: Invoice3.Unpaid,
    limit: 1000000,
  });
  @Watch('selectedFilter') private async SelectedFilterEventChange(val: number) {
      if (val === -1) {
          this.searchFilter.filterBy = null;
      } else {
          this.searchFilter.filterBy = val;
      }
      await this.loadData();
  }
  private async created() {
    await this.loadData();
  }
  private async loadData() {
    await apiGeneral.fortnox_GetAllInvoices(this.searchFilter).then((data) => {
      this.invoiceList = data;
    }).catch((err) => {
      console.log(err);
    });
  }
  get GetInvoiceList() {
    return this.invoiceList;
  }
}
</script>
