<template>
  <div>
    <div class="card-header py-4">
      Lagerstatus
      <VueCtkDateTimePicker
      style="width: 200px;"
                    :no-button-now="true"
                    format="YYYY-MM-DD HH:mm"
                    formatted="YYYY-MM-DD HH:mm"
                    class="deliveryDate form-control form-control-sm float-right mr-3"
                    :no-label="true"
                    locale="sv"
                    onlyDate
                    v-model="selectedDate"
                  />
    </div>
    <div class="card-body">
      <CDataTable
        :pagination="false"
        :items="GetStockList"
        :fields="headers"
        :tableFilter="{ label: 'Sök', placeholder: 'ange text' }"
        :noItemsView="{ noResults: 'hittar inget!', noItems: 'data saknas!' }"
        column-filter
        table-filter
        outlined
        sorter
        responsive
      >
      <template #productNumber="{item}">
            <td><a href="javascript:void(0)" @click="editProduct(item)">{{item.productNumber}}</a></td>
        </template>
        <template #Incoming="{item, index}">
          <td>
            {{item.Incoming}}
            <CIcon class="float-right" style="font-size:10px;cursor: pointer" v-popover="{ name: index.toString() }" name="cil-zoom" />
            <popover transition="show-from-bottom" class="popover-preview" :pointer="false" :name="index.toString()">
              <div class="row mx-auto my-4">
                  <div class="col-12">
                      <h3 class="text-left">{{item.name}} </h3>
                  </div>
                  <hr style="border-bottom: 1px solid #d8dbe0 !important; width: 100%;" />
                  <div class="col-12">
                      <table class="table table-sm">
                          <thead>
                              <th style="border-top:none;">Leverantör</th>
                              <th style="border-top:none;">Inkommande</th>
                              <th style="border-top:none;">Datum</th>
                          </thead>
                          <tbody>
                              <tr v-for="(order, orderIndex) in item.incoming" :key="`order-row-${orderIndex}-${order.id}`" :index="orderIndex">
                                  <td>{{ order.purchase.provider.companyName }}</td>
                                  <td>{{ order.quantity }} {{$t(`enums.${$beplugins.GetEnumsByValue('ProductUnitEnums', order.unit)}`).toLowerCase()}}</td>
                                  <td>{{ moment(order.purchase.arrivalAt).format('YYYY-MM-DD HH:mm') }}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
            </popover>
          </td>
        </template>
        <template #minPointDays="{item}">
          <td>
            <input v-model="item.minPointDays" name="minpointdays" class="form-control form-control-sm" @keyup.enter="x => UpdateMinPointDays(item, x)" type="number" placeholder="saknas värde!" />
          </td>
        </template>
      </CDataTable>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import {
  StockViewModel,
  EmployeeEntity,
  ProductEntity,
} from '@/services/api/ApiGeneral';
import { apiGeneral } from '@/services/api/ApiServices';
import moment from 'moment';
import { watch } from 'fs';
@Component({
  components: {
    VueCtkDateTimePicker,
  }
})
export default class StockList extends Vue {
  @Prop() private stockList!: StockViewModel[];
  private selectedDate: string = moment().format('yyyy-MM-DD');
  private buyerList: EmployeeEntity[] = [];
  private StockList: StockViewModel[] = [];
  private headers = [
    { key: 'productNumber', label: 'ArtNr', _style: '' },
    { key: 'name', label: 'Namn', _style: '' },
    { key: 'ProviderName', label: 'Leverantör', _style: '' },
    { key: 'BuyerName', label: 'Inköpare', _style: '' },
    { key: 'inStock', label: 'Lagerstatus', _style: '' },
    { key: 'Unit', label: 'Enhet', _style: '' },
    { key: 'sevenDays', label: '7 dagar', _style: '' },
    { key: 'thirtyDays', label: '30 dagar', _style: '' },
    { key: 'threeMonths', label: '3 månader', _style: '' },
    { key: 'daysLeft', label: 'Dagar kvar', _style: '' },
    { key: 'Incoming', label: 'Inkommande', _style: '' },
    { key: 'minPointDays', label: 'Minpunkt', _style: '' },
  ];
  private details = [];
  private collapseDuration = 0;
  @Watch('selectedDate') private SelectedDateChanged() {
    this.$emit('dateChanged', this.selectedDate);
  }
  @Watch('stockList') private ProviderListChanged() {
    this.$nextTick(() => {
      this.StockList = this.stockList.map((item: any, ToogleId: any) => ({
        ...item,
        Unit: (this as any).$t(`enums.${this.$beplugins.GetEnumsByValue('ProductUnitEnums', item.unit)}`).toLowerCase(),
        Incoming: item.incoming?.reduce((total: any, thing: any) => total + thing.quantity, 0),
        ToogleId,
      }));
    });
  }
  private async GetBuyersList() {
    await apiGeneral.employee_GetAllBuyers(true).then((response) => {
      this.buyerList = response;
    });
  }
  private async created() {
    await this.GetBuyersList().then(() => {
      this.StockList = this.stockList.map((item: any, ToogleId: any) => ({
        ...item,
        Unit: (this as any).$t(`enums.${this.$beplugins.GetEnumsByValue('ProductUnitEnums', item.unit)}`).toLowerCase(),
        Incoming: item.incoming?.reduce((total: any, thing: any) => total + thing.quantity, 0),
        ProviderName: item.mainProviderFK === '00000000-0000-0000-0000-000000000000' ? '-' : item.providers.find((x: any) => x.id === item.mainProviderFK).companyName,
        BuyerName: item.mainProviderFK === '00000000-0000-0000-0000-000000000000' ? '-' : this.buyerList.find((x) => x.id === item.providers.find((y: any) => y.id === item.mainProviderFK).buyerId) ? this.buyerList.find((x) => x.id === item.providers.find((y: any) => y.id === item.mainProviderFK).buyerId)!.firstName : '',
        ToogleId,
      }));
    })
  }
  private async UpdateMinPointDays(item: StockViewModel, payload: any) {
    if (payload.target.value === '') {
      alert('Minpunkt saknas!')
    } else {
      const minDays = parseInt(payload.target.value, 0);
      if (!isNaN(minDays)) {
        await apiGeneral.product_UpdateMinPointDays(item.productId, minDays).then((response) => {
          if (response.isSuccess) {
            alert('Minpunkt har uppdaterats!')
            item.daysLeft = item.inStock / item.threeMonths / 30;
          } else {
            alert('Minpunkt har ej uppdaterats!')
          }
        }).catch((err) => {
          console.log(err);
          alert('Minpunkt har ej uppdaterats!')
        });
      }
    }
  }
  private editProduct(selected: StockViewModel) {
    window.open(`/product/edit/${selected.productId}`, "_blank");
    // this.$router.push(`/product/edit/${selected.productId}`);
  }
  get GetStockList() {
    return this.StockList;
  }
}
</script>
<style lang="scss" scoped>
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.popover-preview {
 position: fixed !important;
 width: 100% !important;
 max-height: 50% !important;
 top: 0 !important;
 left:0 !important;
 z-index: 9999 !important;
 overflow-y: scroll !important;
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>