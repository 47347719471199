class GlobalConfig {
  public Currency = "SEK";
  public Language = "sv-SE";
  public Domain =
    process.env.NODE_ENV === "production"
      ? "https://admin.easyasia.se"
      : "localhost:8080";
  public GeneralApi =
    process.env.NODE_ENV === "production"
      ? "https://api-general.easyasia.se"
      : "https://localhost:5001";
  public Oidc = {
    authority:
      process.env.NODE_ENV === "production"
        ? "https://auth.easyasia.se"
        : "https://localhost:5003",
    post_logout_redirect_uri:
      process.env.NODE_ENV === "production"
        ? "https://admin.easyasia.se"
        : "https://localhost:8080",
    redirect_uri:
      process.env.NODE_ENV === "production"
        ? "https://admin.easyasia.se/oidc-callback/"
        : "https://localhost:8080/oidc-callback/",
    silent_redirect_uri:
      process.env.NODE_ENV === "production"
        ? "https://admin.easyasia.se/silent-renew-oidc.html"
        : "https://localhost:8080/silent-renew-oidc.html",
  };
}
const globalConfig = new GlobalConfig();
export default globalConfig;
