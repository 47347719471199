import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import OidcCallback from "@/views/Authorization/OidcCallback.vue";
import OidcCallbackError from "@/views/Authorization/OidcCallbackError.vue";
import Container from "@/containers/Container.vue";
import Dashboard from "@/views/Dashboard.vue";
// Kunder
import Customers from "@/views/Customer/Customers.vue";
import CustomerCreate from "@/views/Customer/CustomerCreate.vue";
import CustomerEdit from "@/views/Customer/CustomerEdit.vue";
// Anställda
import Employees from "@/views/Employee/Employees.vue";
import EmployeeCreate from "@/views/Employee/EmployeeCreate.vue";
import EmployeeEdit from "@/views/Employee/EmployeeEdit.vue";
// Leverantörer
import Providers from "./views/Provider/Providers.vue";
import ProviderCreate from "./views/Provider/ProviderCreate.vue";
import ProviderEdit from "./views/Provider/ProviderEdit.vue";
// Produkter
import Products from "./views/Product/Products.vue";
import ProductCreate from "./views/Product/ProductCreate.vue";
import ProductEdit from "./views/Product/ProductEdit.vue";
import ProductHistory from "./views/Product/ProductHistory.vue";
// Prismallar
import PriceTemplates from "./views/PriceTemplate/PriceTemplates.vue";
import PriceTemplateCreate from "./views/PriceTemplate/PriceTemplateCreate.vue";
import PriceTemplateEdit from "./views/PriceTemplate/PriceTemplateEdit.vue";
// Bilar
import Cars from "./views/Car/Cars.vue";
import CarCreate from "./views/Car/CarCreate.vue";
import CarEdit from "./views/Car/CarEdit.vue";
// Planering
import Plannings from "./views/Planning/Plannings.vue";
// Inköp
import Purchases from "./views/Purchase/Purchases.vue";
import PurchaseCreate from "./views/Purchase/PurchaseCreate.vue";
import PurchaseEdit from "./views/Purchase/PurchaseEdit.vue";
// Lagerstatus
import Stock from "./views/Stock/Stock.vue";
import StockValue from "./views/Stock/StockValue.vue";
// Fortnox
import FortnoxCustomers from "./views/Fortnox/Customers/FortnoxCustomers.vue";
import FortnoxInvoices from "./views/Fortnox/Invoices/FortnoxInvoices.vue";
import FortnoxArticles from "./views/Fortnox/Articles/FortnoxArticles.vue";
import FortnoxInvoicesMissing from "./views/Fortnox/Invoices/FortnoxInvoicesMissing.vue";
// In and outcoming
import InAndOutComing from "./views/Coming/InAndOutComing.vue";
//
import OrderList from "./views/Order/OrderList.vue";
import NewOrder from "./views/Order/NewOrder.vue";
import EditOrder from "./views/Order/EditOrder.vue";
// Offer
import OfferList from "./views/Offer/OfferList.vue";
import NewOffer from "./views/Offer/NewOffer.vue";
import EditOffer from "./views/Offer/EditOffer.vue";
//
import PrintOrder from "./views/Order/PrintOrder.vue";
import PrintInvoice from "./views/Invoice/PrintInvoice.vue";
import PrintOrderList from "./views/Order/PrintOrderList.vue";
import InvoiceList from "@/views/Invoice/InvoiceList.vue";
import Setting from "@/views/Setting/Setting.vue";

Vue.use(Router);
const router = new Router({
  mode: "history",
  linkActiveClass: "open active",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/",
      component: Container,
      children: [
        {
          path: "/",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "customer",
          redirect: "/customer/list",
          name: "Kunder",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/customer/edit/:id",
              name: "Redigera kund",
              component: CustomerEdit,
            },
            {
              path: "/customer/create",
              name: "Lägg till kund",
              component: CustomerCreate,
            },
            {
              path: "/customer/list",
              name: "Lista",
              component: Customers,
            },
          ],
        },
        {
          path: "employee",
          redirect: "/employee/list",
          name: "Anställda",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/employee/edit/:id",
              name: "Redigera anställd",
              component: EmployeeEdit,
            },
            {
              path: "/employee/create",
              name: "Lägg till anställd",
              component: EmployeeCreate,
            },
            {
              path: "/employee/list",
              name: "Lista av anställda",
              component: Employees,
            },
          ],
        },
        {
          path: "provider",
          redirect: "/provider/list",
          name: "Leverantör",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/provider/edit/:id",
              name: "Redigera leverantör",
              component: ProviderEdit,
            },
            {
              path: "/provider/create",
              name: "Lägg till leverantör",
              component: ProviderCreate,
            },
            {
              path: "/provider/list",
              name: "Visa alla leverantörer",
              component: Providers,
            },
          ],
        },
        {
          path: "product",
          redirect: "/product/list",
          name: "Produkt",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/product/edit/:id",
              name: "Redigera produkt",
              component: ProductEdit,
            },
            {
              path: "/product/create",
              name: "Lägg till produkt",
              component: ProductCreate,
            },
            {
              path: "/product/list",
              name: "Visa alla produkter",
              component: Products,
            },
            {
              path: "/product/history/:id",
              name: "Produkthistorik",
              component: ProductHistory,
            },
          ],
        },
        {
          path: "purchase",
          redirect: "/purchase/list",
          name: "Beställningar",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/purchase/edit/:id",
              name: "Redigera inköp",
              component: PurchaseEdit,
            },
            {
              path: "/purchase/create",
              name: "Lägg till inköp",
              component: PurchaseCreate,
            },
            {
              path: "/purchase/list",
              name: "Visa alla inköp",
              component: Purchases,
            },
          ],
        },
        {
          path: "pricetemplate",
          redirect: "/pricetemplate/list",
          name: "Prismallar",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/pricetemplate/edit/:id",
              name: "Redigera prismall",
              component: PriceTemplateEdit,
            },
            {
              path: "/pricetemplate/create",
              name: "Lägg till prismall",
              component: PriceTemplateCreate,
            },
            {
              path: "/pricetemplate/list",
              name: "Visa alla prismallar",
              component: PriceTemplates,
            },
          ],
        },
        {
          path: "car",
          redirect: "/car/list",
          name: "Bilar",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/car/edit/:id",
              name: "Redigera bil",
              component: CarEdit,
            },
            {
              path: "/car/create",
              name: "Lägg till bil",
              component: CarCreate,
            },
            {
              path: "/car/list",
              name: "Visa alla bilar",
              component: Cars,
            },
          ],
        },
        {
          path: "planning",
          redirect: "/planning/list",
          name: "Planering",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/plannings",
              name: "Planeringar",
              component: Plannings,
            },
            {
              path: "/incoming",
              name: "InAndOutComing",
              component: InAndOutComing,
            },
          ],
        },
        {
          path: "/Settings",
          name: "Settings",
          component: Setting,
        },
        {
          path: "/invoice/list",
          name: "InvoiceList",
          component: InvoiceList,
        },
        {
          path: "/stock/list",
          name: "StockList",
          component: Stock,
        },
        {
          path: "/stockvalue/list",
          name: "StockValueList",
          component: StockValue,
        },
        {
          path: "/order/list/:userId?",
          name: "OrderList",
          component: OrderList,
        },
        {
          path: "/Offer/list/:userId?",
          name: "OfferList",
          component: OfferList,
        },
        {
          path: "/signout",
          name: "SignOut",
          beforeEnter: (to, from, next) => {
            store.dispatch("signOutOidc");
          },
        },
        {
          path: "fortnox",
          redirect: "/fortnox/list",
          name: "Fortnox",
          component: {
            render(c: (arg0: string) => any) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/fortnox/customer/list",
              name: "Kundregister",
              component: FortnoxCustomers,
            },
            {
              path: "/fortnox/invoice/list",
              name: "Fakturor",
              component: FortnoxInvoices,
            },
            {
              path: "/fortnox/invoicemissing/list",
              name: "FakturorKontroll",
              component: FortnoxInvoicesMissing,
            },
            {
              path: "/fortnox/article/list",
              name: "Artiklar",
              component: FortnoxArticles,
            },
          ],
        },
      ],
    },
    {
      path: "/OfferCreate/:id",
      name: "OfferCreate",
      component: NewOffer,
    },
    {
      path: "/OfferEdit/:orderId",
      name: "OfferEdit",
      component: EditOffer,
    },
    {
      path: "/OrderCreate/:id",
      name: "OrderCreate",
      component: NewOrder,
    },
    {
      path: "/OrderEdit/:orderId",
      name: "OrderEdit",
      component: EditOrder,
    },
    {
      path: "/PrintOrder/:orderId/:printMode",
      name: "PrintOrder",
      component: PrintOrder,
    },
    {
      path:
        "/PrintOrderList/:isCar/:ids/:showFrozenItems/:showFridgeItems/:showDryItems",
      name: "PrintOrderList",
      component: PrintOrderList,
    },
    {
      path: "/PrintInvoice/:id",
      name: "PrintInvoice",
      component: PrintInvoice,
    },
    {
      path: "/oidc-callback",
      name: "oidcCallback",
      component: OidcCallback,
    },
    {
      path: "/oidc-callback-error",
      name: "oidcCallbackError",
      component: OidcCallbackError,
      meta: {
        isPublic: true,
      },
    },
  ],
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store));
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    store.dispatch("setLoading", true);
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  store.dispatch("setLoading", false);
});
export default router;
