<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <CarList
          :carList="carList" @reload="loadCars"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { CarEntity } from '@/services/api/ApiGeneral';
import CarList from '@/components/car/CarList.vue';
@Component({
  components: {
    CarList,
  },
})
export default class Cars extends Vue {
  private carList: CarEntity[] = [];
  private async created() {
    await this.loadCars();
  }
  private async loadCars() {
    await apiGeneral.car_GetAll().then((data) => {
      this.carList = data;
    });
  }
}
</script>
