<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <ProductHistoryDetails :productId="productId" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ProductHistoryDetails from "@/components/product/ProductHistoryDetails.vue";
@Component({
  components: {
    ProductHistoryDetails,
  },
})
export default class ProductHistory extends Vue {
  private productId!: string;
  private created() {
    this.productId = this.$route.params.id;
  }
}
</script>
