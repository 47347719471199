<template>
  <ValidationObserver ref="observer">
    <form class="row" @submit.prevent="onSubmit" novalidate>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Kunduppgifter</strong>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label class="form-label" for="title">Titel</label>
              <select v-model="vModel.title" class="form-control" id="title">
                <option :value="customerModeEnum.value" v-for="customerModeEnum in $beplugins.GetEnumsByType(
                  'GenderEnums'
                )" :key="customerModeEnum.label">{{ $t(`enums.${customerModeEnum.label}`) }}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="alias">Alias</label>
              <ValidationProvider rules="required" v-slot="v">
                <input v-model="vModel.alias" name="alias" class="form-control" id="alias" type="text"
                  placeholder="Vänligen ange alias..." />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="firstName">Förnamn</label>
                <ValidationProvider rules="required|name" v-slot="v">
                  <input v-model="vModel.firstName" name="firstName" class="form-control" id="firstName" type="text"
                    placeholder="Vänligen ange förnamn..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="lastName">Efternamn</label>
                <ValidationProvider rules="required|name" v-slot="v">
                  <input v-model="vModel.lastName" name="lastName" class="form-control" id="lastName" type="text"
                    placeholder="Vänligen ange efternamn..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="address">Adress</label>
              <ValidationProvider rules="required" v-slot="v">
                <input v-model="vModel.address" name="address" class="form-control" id="address" type="text"
                  placeholder="Vänligen ange adress..." />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="form-group col-4">
                <label class="form-label" for="country">Land</label>
                <select v-model="vModel.country" class="form-control" id="country">
                  <option v-for="(country, countryIndex) in GetCountries" :key="country.code" :index="countryIndex"
                    :value="country.code">{{ country.name }}</option>
                </select>
              </div>
              <div class="form-group col-4">
                <label class="form-label" for="city">Stad</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.city" name="city" class="form-control" id="city" type="text"
                    placeholder="Vänligen ange stad..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-4">
                <label class="form-label" for="zipCode">Postnummer</label>
                <ValidationProvider rules="required" v-slot="v">
                  <input v-model="vModel.zipCode" name="zipCode" class="form-control" id="zipCode" type="text"
                    placeholder="Vänligen ange postnummer..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="phone">Telefon</label>
                <input v-model="vModel.phone" name="phone" class="form-control" id="phone" type="text"
                  placeholder="Vänligen ange telefon..." />
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="email">E-post</label>
                <ValidationProvider rules="email|required" v-slot="v">
                  <input v-model="vModel.email" name="email" class="form-control" id="email" type="text"
                    placeholder="Vänligen ange e-post..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="employeeDate">Anställningsdatum</label>
              <ValidationProvider rules="required" v-slot="v">
                <input v-model="vModel.employeeDate" name="employeeDate" class="form-control" id="employeeDate"
                  type="date" />
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <strong>Säkerhet</strong>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label class="form-label" for="isActive">Kontostatus</label>
              <select v-model="vModel.isEnabled" class="form-control" id="isActive">
                <option :value="true">Aktiverad</option>
                <option :value="false">Avaktiverad</option>
              </select>
            </div>
            <div class="form-group">
              <label class="form-label" for="roles">Avdelning</label>
              <ValidationProvider rules="required" v-slot="v">
                <select multiple v-model="vModel.roles" class="form-control" id="roles">
                  <option :value="role" v-for="role in roleList" :key="role">{{ role }}</option>
                </select>
                <span class="text-danger">{{ v.errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="form-label" for="password">Lösenord: <span v-if="generatedPassword !== ''" class="ml-5">{{
                    generatedPassword
                }}</span></label>
                <ValidationProvider rules="required|passwordRegex|password:@cpassword" v-slot="v">
                  <input v-model="vModel.password" name="password" class="form-control" id="password" type="password"
                    placeholder="Vänligen ange lösenord..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="cpassword">Bekräfta</label>
                <ValidationProvider name="cpassword" rules="required" v-slot="v">
                  <input v-model="cpassword" name="cpassword" class="form-control" id="cpassword" type="password"
                    placeholder="Vänligen ange bekräfta lösenordet..." />
                  <span class="text-danger">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <button type="submit" class="btn btn-success pull-right">
              Spara
            </button>
            <button type="button" @click="GeneratePassword()" class="btn btn-info mr-3 pull-right">
              Generera lösenord
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { apiGeneral } from '@/services/api/ApiServices';
import { EmployeeEntity, GenderEnums } from '@/services/api/ApiGeneral';
import countries from '@/assets/data/countries.ts';
@Component({})
export default class EmployeeNew extends Vue {
  private cpassword: string = '';
  private generatedPassword = '';
  private vModel: EmployeeEntity = new EmployeeEntity({
    id: '00000000-0000-0000-0000-000000000000',
    title: 0,
    isEnabled: false,
    employeeDate: new Date().toISOString(),
    firstName: '',
    lastName: '',
    address: '',
    zipCode: '',
    city: '',
    country: 'SE',
    phone: '',
    email: '',
    alias: '',
    roles: [],
    entityState: 0,
    password: ''
  });
  private roleList: string[] = [];
  get GetCountries() {
    return countries;
  }
  private async created() {
    await this.LoadRoles();
  }
  private async LoadRoles() {
    await apiGeneral.role_GetAll().then((response) => {
      this.roleList = response;
    });
  }
  private GeneratePassword() {
    this.generatedPassword = this.$beplugins.GeneratePassword(8, true, true, false);
    this.vModel.password = this.generatedPassword;
    this.cpassword = this.generatedPassword;
  }
  private async onSubmit() {
    const isValid = await (this.$refs.observer as any).validate();
    if (!isValid) {
      return;
    }
    await apiGeneral.employee_Create(this.vModel).then((response) => {
      if (response.isSuccess && response.messages != null) {
        this.$beplugins.ApiSuccess(response);
        this.vModel = new EmployeeEntity({
          id: '00000000-0000-0000-0000-000000000000',
          title: 0,
          isEnabled: false,
          employeeDate: new Date().toISOString(),
          firstName: '',
          lastName: '',
          address: '',
          zipCode: '',
          city: '',
          country: 'SE',
          phone: '',
          email: '',
          alias: '',
          roles: [],
          entityState: 0,
          password: ''
        });
        this.cpassword = '';
        (this.$refs.observer as any).reset();
      } else {
        this.$beplugins.ApiError(response);
      }
    });
  }
}
</script>
