export default {
  items: [
    {
      _name: "CSidebarNav",
      _children: [
        {
          _name: "CSidebarNavItem",
          name: "Översikt",
          to: "/",
          icon: "cil-speedometer",
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Lager",
          route: "/base",
          icon: "cil-bar-chart",
          items: [
            {
              name: "Lagerstatus",
              to: "/stock/list",
            },
            
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Försäljning",
          route: "/base",
          icon: "cil-euro",
          _children: [
            {
              _name: "CSidebarNavDropdown",
              name: "Prismallar",
              route: "/base",
              icon: "cil-folder-open",
              items: [
                {
                  name: "Lägg till",
                  to: "/pricetemplate/create",
                },
                {
                  name: "Visa alla",
                  to: "/pricetemplate/list",
                },
              ],
            },
            {
              _name: "CSidebarNavItem",
              name: "Fakturor",
              to: "/fortnox/invoice/list", // '/invoice/list',
              icon: "cil-speedometer",
            },
            {
              _name: "CSidebarNavItem",
              name: "Order",
              to: "/order/list",
              icon: "cil-speedometer",
            },
            {
              _name: "CSidebarNavItem",
              name: "Offerter",
              to: "/offer/list",
              icon: "cil-speedometer",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Leveranser",
          route: "/delivery",
          icon: "cil-truck",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: "Planering",
              to: "/plannings",
              icon: "cil-calendar",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Inköp",
          route: "/base",
          icon: "cil-dollar",
          _children: [
            {
              _name: "CSidebarNavDropdown",
              name: "Beställningar",
              route: "/base",
              icon: "cil-dollar",
              items: [
                {
                  name: "Lägg till",
                  to: "/purchase/create",
                },
                {
                  name: "Visa alla",
                  to: "/purchase/list",
                },
              ],
            },
            {
              _name: "CSidebarNavDropdown",
              name: "Leverantörer",
              route: "/base",
              icon: "cil-truck",
              items: [
                {
                  name: "Lägg till",
                  to: "/provider/create",
                },
                {
                  name: "Visa alla",
                  to: "/provider/list",
                },
              ],
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Kunder",
          route: "/base",
          icon: "cil-people",
          items: [
            {
              name: "Lägg till",
              to: "/customer/create",
            },
            {
              name: "Visa alla",
              to: "/customer/list",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Produkter",
          route: "/base",
          icon: "cil-sitemap",
          items: [
            {
              name: "Lägg till",
              to: "/product/create",
            },
            {
              name: "Visa alla",
              to: "/product/list",
            },
            {
              _name: "CSidebarNavItem",
              name: "In- & Utleveranss",
              to: "/incoming",
              icon: "cil-calendar",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Användare",
          route: "/base",
          icon: "cil-shield-alt",
          items: [
            {
              name: "Lägg till",
              to: "/employee/create",
            },
            {
              name: "Visa alla",
              to: "/employee/list",
            },
          ],
        },
        {
          _name: "CSidebarNavDropdown",
          name: "Inställningar",
          route: "/Settings",
          icon: "cil-settings",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: "Företagsuppgifter",
              to: "/settings",
              icon: "cil-settings",
            },
            {
              _name: "CSidebarNavDropdown",
              name: "Fortnox",
              route: "/fortnox",
              icon: "cil-dollar",
              items: [
                {
                  name: "Kundregster",
                  to: "/fortnox/customer/list",
                },
                {
                  name: "Fakturor",
                  to: "/fortnox/invoice/list",
                },
                {
                  name: "Kontrollera Fakturor",
                  to: "/fortnox/invoicemissing/list",
                },
                {
                  name: "Artiklar",
                  to: "/fortnox/article/list",
                },
              ],
            },
            {
              _name: "CSidebarNavDropdown",
              name: "Bilar",
              route: "/car",
              icon: "cil-truck",
              items: [
                {
                  name: "Lägg till",
                  to: "/car/create",
                },
                {
                  name: "Visa alla",
                  to: "/car/list",
                },
              ],
            },
          ],
        },
        {
          _name: "CSidebarNavItem",
          name: "Logga ut",
          to: "/signout",
          icon: "cil-exit-to-app",
        },
      ],
    },
  ],
};
