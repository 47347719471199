<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <h5>Du saknar behörighet för denna sidan!</h5>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component({
})
export default class NotAuthorized extends Vue {
}
</script>
