<template>
  <div v-if="!isLoading" class="card-body">
    <div class="row">
      <div class="col-6">
        <p class="mt-0"><strong>Tillgängliga ordrar</strong></p>
        <table class="table table-sm">
          <thead>
            <th>Ordernr</th>
            <th>Kund</th>
            <th>Ort</th>
            <th class="text-center">Bil</th>
            <th class="text-center">Vikt</th>
            <th>Kommentar</th>
            <th>Säljare</th>
          </thead>
          <tr v-for="(order, orderIndex) in orders" :key="order.id" :index="orderIndex">
            <td>{{ order.orderId }}</td>
            <td>
              {{
                order.customer.customerMode === 0
                ? order.customer.companyName
                : `${order.customer.firstName} ${order.customer.lastName}`
              }}
            </td>
            <td>{{ `${order.customer.city} (${order.customer.country})` }}</td>
            <td>
              <select :ref="`order-car-${order.id}`" @change="CarSelectedChangeEvent($event, orderIndex, order)"
                class="form-control form-control-sm">
                <option :value="0">0</option>
                <option v-for="i in 100" :value="i" :key="`car-order-index-${orderIndex}-${i}`">{{ i }}</option>
              </select>
            </td>
            <td class="text-right">
              {{ $beplugins.TotalWeightOrderModel(order) }} Kg
            </td>
            <td>{{ order.notes }}</td>
            <td>{{ order.sellerAlias }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6">
        <p class="mt-0">
          <strong>Planeringar</strong>
          <CButton @click="SaveAndPrint(false, false)" size="sm" color="info" class="ml-1 float-right">
            Spara
          </CButton>
          <CButton :disabled="vModel.filter((x) => x.selectedForPrint).length < 1" @click="SaveAndPrint(true, true)"
            size="sm" color="info" class="ml-1 float-right">
            Plocklista
          </CButton>
          <CButton :disabled="AllFreezeButtonDisabled()" @click="SaveAndPrint(true, false)" size="sm" color="info"
            class="ml-1 float-right">
            Fryslista
          </CButton>
        </p>
        <table class="table table-sm">
          <thead>
            <th>Bilnr</th>
            <th>Chafför</th>
            <th class="text-center">Bil</th>
            <th class="text-center">Vikt</th>
            <th class="text-center">Tid</th>
            <th></th>
          </thead>
          <tr v-for="(car, carIndex) in vModel" :key="`car-order-id-${carIndex}`" :index="carIndex">
            <td>{{ car.carNr }}</td>
            <td>
              <select v-model="car.driverFk" :ref="`driver-select-${carIndex}`" class="form-control form-control-sm">
                <option v-for="driver in drivers" :value="driver.id" :key="`car-driver-${carIndex}-${driver.id}`">{{
                  driver.alias }}</option>
              </select>
            </td>
            <td>
              <select v-model="car.carFk" :ref="`car-select-${carIndex}`" class="form-control form-control-sm">
                <option v-for="car in cars" :value="car.id" :key="`car-vehicle-${carIndex}-${car.id}`">{{
                  `${car.brand} - ${car.model} (${car.numberPlate})`
                }}</option>
              </select>
            </td>
            <td class="text-right" style="width:100px;">
              {{ car.totalWeight }} Kg
            </td>
            <td style="width:100px;">
              <input v-model="car.transportDateTime" :ref="`Time-input-${carIndex}`" style="text-align:right;"
                value="12:00" class="form-control form-control-sm" type="time" />
            </td>
            <td>
              <CInputCheckbox :checked.sync="car.selectedForPrint" class="mt-0" />
              <CIcon class="ml-4" style="font-size:10px;cursor: pointer" v-popover="{ name: carIndex.toString() }"
                name="cil-zoom" />
            </td>
            <popover transition="show-from-bottom" class="popover-preview" :pointer="false" :name="carIndex.toString()">
              <div class="row mx-auto my-4">
                <div class="col-12">
                  <h3 class="text-center">Bilnr: {{ car.carNr }}</h3>
                </div>
                <hr style="border-bottom: 1px solid #d8dbe0 !important; width: 100%;" />
                <div class="col-12">
                  <table class="table table-sm">
                    <thead>
                      <th style="border-top:none;">Ordernr</th>
                      <th class="text-center" style="border-top:none;width: 80px;">
                        Skriv ut
                      </th>
                      <th style="border-top:none;">Kund</th>
                      <th style="border-top:none;">Ort</th>
                      <th style="border-top:none;">Vikt</th>

                      <th style="border-top:none;width: 180px;"></th>
                    </thead>
                    <tbody>
                      <tr v-for="(order, orderIndex) in car.orders" :key="`car-row-${carIndex}-${order.id}`"
                        :index="orderIndex">
                        <td>{{ order.orderId }}</td>
                        <td class="text-center">
                          <CInputCheckbox :checked.sync="order.selectedForPrint" class="mt-0" />
                        </td>
                        <td>
                          {{
                            order.customer.customerMode === 0
                            ? order.customer.companyName
                            : `${order.customer.firstName} ${order.customer.lastName}`
                          }}
                        </td>
                        <td>
                          {{
                            `${order.customer.city} (${order.customer.country})`
                          }}
                        </td>
                        <td>
                          {{ $beplugins.TotalWeightOrderModel(order) }} Kg
                        </td>

                        <td>
                          <CButton @click="SinglePrint(true, order)" size="sm" color="info" class="float-right ml-1">
                            Plocklista
                          </CButton>
                          <CButton :disabled="order.products.some((x) => x.productType !== 1)
                            " @click="SinglePrint(false, order)" size="sm" color="info" class="float-right ml-1">
                            Fryslista
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <th colspan="4"></th>
                      <th>Totalvikt: {{ car.totalWeight }} Kg</th>
                      <th></th>
                    </tfoot>
                  </table>
                </div>
                <hr class="mt-0" style="border-bottom: 1px solid #d8dbe0 !important; width: 100%;" />
                <div class="col-12">
                  <CButton :disabled="car.orders
                    .filter((x) => x.selectedForPrint)
                    .map((y) => y.id).length < 1
                    " @click="CarSaveAndPrint(true, car)" size="sm" color="info" class="ml-1">
                    Plocklista
                  </CButton>
                  <CButton :disabled="FreezeButtonDisabled(car)" @click="CarSaveAndPrint(false, car)" size="sm"
                    color="info" class="ml-1">
                    Fryslista
                  </CButton>
                  <CButton @click="ToogleSelectAllOrders(car, true)" size="sm" color="info" class="ml-1">
                    Markera alla
                  </CButton>
                  <CButton @click="ToogleSelectAllOrders(car, false)" size="sm" color="info" class="ml-1">
                    Avmarkera alla
                  </CButton>
                </div>
              </div>
            </popover>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Moment from "moment";
import { apiGeneral } from "@/services/api/ApiServices";
import {
  OrderEntity,
  CarEntity,
  EmployeeEntity,
  CarPlanningEntity,
  CarTableListViewModel,
  CompanyDetails,
  ProductTypeEnums,
  ProductEntity,
} from "@/services/api/ApiGeneral";
const moment = Moment;
@Component({
  components: {},
})
export default class ListMode extends Vue {
  @Prop() private selectedDate!: string;
  private products: ProductEntity[] = [];
  private filterDate: string = this.selectedDate;
  private isLoading: boolean = false;
  private cars: CarEntity[] = [];
  private orders: OrderEntity[] = [];
  private drivers: EmployeeEntity[] = [];
  private plannings: CarPlanningEntity[] = [];
  private vModel: CarTableListViewModel[] = [];
  private settings: CompanyDetails = new CompanyDetails();
  @Watch("selectedDate") private async WatchFilterDate(payload: string) {
    this.filterDate = payload;
    await this.LoadAll();
  }
  private AllFreezeButtonDisabled() {
    const items: any[] = [];
    this.vModel
      .filter((x) => x.selectedForPrint)
      .forEach((x) => {
        x.orders!.flatMap((p) => p.products)!.forEach((i) => {
          items.push(i);
        });
      });
    if (
      items.length > 0 &&
      items!.some((x) => x?.productType !== ProductTypeEnums.Frozen)
    ) {
      return false;
    }
    return this.vModel.filter((x) => x.selectedForPrint).length < 1;
  }
  private FreezeButtonDisabled(car: CarTableListViewModel) {
    const items = car!
      .orders!.filter((y) => y.selectedForPrint)
      .flatMap((x) => x.products);
    if (items!.some((x) => x?.productType !== ProductTypeEnums.Frozen)) {
      return false;
    }
    return (
      car!.orders!.filter((x) => x.selectedForPrint).map((y) => y.id).length < 1
    );
  }
  private CarSelectedChangeEvent(
    event: any,
    orderIndex: number,
    selectedOrder: OrderEntity
  ) {
    const carNr = parseInt(event.target.value, 0);
    if (carNr === 0) {
      this.vModel.forEach((item, itemIndex) => {
        const carItemIndex = item.tempOrderIds!.findIndex(
          (id) => id === selectedOrder.id
        );
        if (carItemIndex !== -1) {
          if (item.tempOrderIds!.length > 1) {
            this.$delete(item.tempOrderIds!, carItemIndex);
            this.$delete(item.orders!, carItemIndex);
            this.vModel[
              itemIndex
            ].totalWeight = this.$beplugins.TotalWeightCarModel(
              this.vModel[itemIndex]
            );
          } else {
            this.$delete(this.vModel, itemIndex);
          }
        }
      });
    } else {
      this.vModel.forEach((item, itemIndex) => {
        const carItemIndex = item.tempOrderIds!.findIndex(
          (id) => id === selectedOrder.id
        );
        if (carItemIndex !== -1) {
          if (item.tempOrderIds!.length > 1) {
            this.$delete(item.tempOrderIds!, carItemIndex);
            this.$delete(item.orders!, carItemIndex);
            this.vModel[
              itemIndex
            ].totalWeight = this.$beplugins.TotalWeightCarModel(
              this.vModel[itemIndex]
            );
          } else {
            this.$delete(this.vModel, itemIndex);
          }
        }
      });
      const carIndex = this.vModel.findIndex((x) => x.carNr === carNr);
      if (carIndex === -1) {
        const payload: CarTableListViewModel = new CarTableListViewModel({
          id: 0,
          selectedForPrint: false,
          carNr: carNr,
          totalWeight: 0,
          orders: [selectedOrder],
          tempOrderIds: [selectedOrder.id],
          carFk: this.cars[0].id,
          driverAlias: "",
          driverFk: this.drivers[0].id,
          transportDateTime: "12:00",
          deliveredDateTime: "",
        });
        this.$set(this.vModel, this.vModel.length, payload);
        this.vModel[
          this.vModel.length - 1
        ].totalWeight = this.$beplugins.TotalWeightCarModel(
          this.vModel[this.vModel.length - 1]
        );
      } else {
        this.$set(
          this.vModel[carIndex].orders!,
          this.vModel[carIndex].orders!.length,
          selectedOrder
        );
        this.$set(
          this.vModel[carIndex].tempOrderIds!,
          this.vModel[carIndex].tempOrderIds!.length,
          selectedOrder.id
        );
        this.vModel[carIndex].totalWeight = this.$beplugins.TotalWeightCarModel(
          this.vModel[carIndex]
        );
      }
    }
  }
  private async LoadSettings() {
    await apiGeneral.companyDetail_Get().then((response) => {
      this.settings = response;
    });
  }
  private async LoadPlannings() {
    await apiGeneral
      .carPlanning_GetAllListMode(this.filterDate)
      .then((response) => {
        this.plannings = response;
      });
  }
  private async LoadOrders() {
    await apiGeneral
      .order_GetAllOrdersByDate(this.filterDate)
      .then((response) => {
        this.orders = response;
      });
  }
  private async LoadCars() {
    await apiGeneral.car_GetAll().then((data) => {
      this.cars = data;
    });
  }
  private async LoadDrivers() {
    await apiGeneral.employee_GetAllDrivers(true).then((response) => {
      this.drivers = response;
    });
  }
  private async LoadAll() {
    this.vModel = [];
    await apiGeneral.product_GetAll().then((pList) => {
      this.products = pList;
    });
    await this.LoadPlannings().then(
      async () =>
        await this.LoadSettings().then(
          async () =>
            await this.LoadOrders().then(
              async () =>
                await this.LoadCars().then(
                  async () =>
                    await this.LoadDrivers().then(() => {
                      this.plannings.forEach((plan, planIndex) => {
                        const payload: CarTableListViewModel = new CarTableListViewModel(
                          {
                            id: plan.id,
                            selectedForPrint: plan.selectedForPrint,
                            carNr: plan.carNr,
                            totalWeight: plan.totalWeight,
                            orders: [],
                            tempOrderIds: plan.tempOrderIds,
                            carFk: plan.carFk!,
                            driverAlias: "",
                            driverFk: plan.driverFk!,
                            transportDateTime: moment(
                              plan.transportDateTime
                            ).format("HH:mm"),
                            deliveredDateTime: plan.deliveredDateTime,
                          }
                        );
                        this.$set(this.vModel, planIndex, payload);
                        plan.orders!.forEach((order) => {
                          const itemIndex = this.orders.findIndex(
                            (item) => item!.id === order!.id
                          );
                          if (itemIndex !== -1) {
                            const orderCarEl = this.$refs[
                              `order-car-${order.id}`
                            ]![0] as HTMLSelectElement;
                            orderCarEl.value = plan.carNr.toString();
                            this.$set(
                              this.vModel[planIndex].orders!,
                              this.vModel[planIndex].orders!.length,
                              this.orders[itemIndex]
                            );
                          }
                        });
                      });
                      this.isLoading = false;
                    })
                )
            )
        )
    );
  }
  private async created() {
    this.filterDate = this.selectedDate;
    await this.LoadAll();
  }
  private async SaveAndPrint(isPrint: boolean, isPickingList: boolean) {
    this.vModel.forEach((item) => {
      item.transportDateTime = `${this.filterDate} ${item.transportDateTime}`;
    });
    await apiGeneral
      .carPlanning_CreateOrUpdateListMode(this.vModel, this.filterDate)
      .then(async (response) => {
        this.LoadAll()
          .then(async () => {
            response.data!.forEach((item) => {
              const dataIndex = this.vModel.findIndex(
                (data) => data.id === item.id
              );
              if (dataIndex !== -1) {
                this.vModel[dataIndex].selectedForPrint = item.selectedForPrint;
              }
            });
          })
          .then(async () => {
            const selectedCars = this.vModel.filter((x) => x.selectedForPrint);
            if (isPrint && selectedCars.length > 0) {
              let orders = selectedCars.flatMap(
                (x) => x.orders
              ) as OrderEntity[];
              if (isPickingList) {
                orders = orders.filter((x) => !x.printedPickList);
              }
              if (isPickingList && orders !== null && orders.length > 0) {
                await this.UpdatePickListStatusByOrderIds(
                  orders.flatMap((x) => x.id)
                ).then(async () => {
                  await this.$beplugins.PrintingPickList(
                    orders
                  );
                });
              } else if (
                !isPickingList &&
                orders !== null &&
                orders.length > 0
              ) {
                // fryslista
                await this.$beplugins.PrintingFreezeSummaryList(
                  orders
                );
              } else {
                alert("Dessa plocklistor har redan blivit utskrivna!");
              }
            }
          });
      });
  }
  private async CarSaveAndPrint(
    isPickingList: boolean,
    car: CarTableListViewModel
  ) {
    let selectedOrders = car.orders!.filter((x) => x.selectedForPrint);
    if (isPickingList) {
      selectedOrders = selectedOrders.filter((x) => !x.printedPickList);
    }
    if (selectedOrders.length > 0) {
      if (
        isPickingList &&
        selectedOrders !== null &&
        selectedOrders.length > 0
      ) {
        selectedOrders.forEach((x) => (x.printedPickList = true));
        await this.UpdatePickListStatusByOrderIds(
          selectedOrders.flatMap((x) => x.id)
        ).then(async () => {
          await this.$beplugins.PrintingPickList(
            selectedOrders
          );
        });
      } else if (
        !isPickingList &&
        selectedOrders !== null &&
        selectedOrders.length > 0
      ) {
        // fryslista
        await this.$beplugins.PrintingFreezeSummaryList(
          selectedOrders
        );
      } else {
        alert("Dessa plocklistor har redan blivit utskrivna!");
      }
    } else {
      alert("Dessa plocklistor har redan blivit utskrivna!");
    }
  }
  private async SinglePrint(isPickingList: boolean, order: OrderEntity) {
    if (isPickingList && !order.printedPickList) {
      await this.UpdatePickListStatusByOrderIds([order.id]).then(async () => {
        order.printedPickList = true;
        await this.$beplugins.PrintingPickList(
          [order]
        );
      });
    } else if (!isPickingList) {
      await this.$beplugins.PrintingFreezeList([order]);
    } else {
      alert("Plocklistan är redan utskriven!");
    }
  }
  private ToogleSelectAllOrders(
    payload: CarTableListViewModel,
    isSelect: boolean
  ) {
    payload.orders?.forEach((x) => (x.selectedForPrint = isSelect));
  }
  private async UpdatePickListStatusByOrderIds(payload: string[]) {
    await apiGeneral.order_UpdateOrderPrintingStatusByIds(
      payload,
      false,
      false
    );
  }
}
</script>
<style lang="scss" scoped>
.show-from-bottom-enter-active,
.show-from-bottom-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}

.show-from-bottom-enter,
.show-from-bottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.popover-preview {
  position: fixed !important;
  width: 100% !important;
  max-height: 50% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  overflow-y: scroll !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
