<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12">
        <CustomerList />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import CustomerList from "@/components/dashboard/CustomerList.vue";
@Component({
  components: {
    CustomerList,
  },
})
export default class Dashboard extends Vue {

}
</script>
<style lang="scss">
</style>
